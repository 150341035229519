/** @format */

import NoItems from "components/NoItems";
import Layout from "layouts";
import PlusBtn from "assets/icons/PlusBtnSvg";
import React, { useEffect, useState } from "react";
import Button from "components/Button2";
import PlusIcon from "assets/icons/PlusIcon";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import toast from "react-hot-toast";
import NewCategoryInput from "components/NewCategoryInput";
import AddComboForm from "components/AddComboForm";
import OfferCard from "components/OfferCard";
import { Box, Grid } from "@mui/material";
import "./index.scss";
import { CreateOffer, DeleteOffer, GetAllOffers, UpdateOffer } from "store/action/OffersAction";
import OffersModal from "components/OffersModal";
import LockIcon from "assets/icons/LockIcon";
import UnlockIcon from "assets/icons/UnlockIcon";
import Confetti from "components/Confetti";
import { DeleteModal } from "components/DeleteModal";

function Offers() {
   //DATA

   const { offers, singleOffer } = useSelector((state) => state.OffersReducer);
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);
   const [open, setOpen] = useState("None");
   const [offerToDelete, setofferToDelete] = useState();
   const [showDeleteModal, setshowDeleteModal] = useState(false);
   console.log(offers, singleOffer, merchantDetails);
   const [isAdding, setAdding] = useState(false);
   const [isEdit, setEdit] = useState(false);
   const [loading, setloading] = useState(false);
   const dispatch = useDispatch();
   const [isVisible, setVisible] = useState(false);
   //METHODS
   const CONTENT = [
      {
         id: "unlockTheFeature",
         title: "Unlock this feature",
         description: "Once you unlock this feature you will have 3 days of free trail after which you will be charged 1000 credits/month",
         button: "Unlock",
         icon: <LockIcon />,
         onClick: () => {
            setOpen("trialEnded");
         },
         color: "#2D6B4D",
      },
      {
         id: "featureUnlocked",
         title: "Feature unlocked",
         description: `Enjoy this feature for free for 3 days`,
         button: "None",
         icon: <UnlockIcon />,
         onClick: () => {
            setOpen("");
         },
         color: "#2FBF71",
      },
      {
         id: "trialEnded",
         title: "Free trial ended!",
         description: "Your free trial ended. Unlock this feature for 1000 credits/ month.",
         button: "Unlock",
         icon: <LockIcon />,
         onClick: () => {
            setOpen("subscriptionEnded");
         },
         color: "#D90429",
      },
      {
         id: "subscriptionEnded",
         title: "Monthly subscription ended!",
         description: `Subscribe to continue enjoying the benefits`,
         button: "Subscribe",
         icon: <LockIcon />,
         onClick: () => {
            setOpen("subscribed");
         },
         color: "#D90429",
      },
      {
         id: "subscribed",
         title: "Hurray! You are subscribed to offers",
         description: `1000 credits deducted`,
         button: "Close",
         icon: <Confetti />,
         onClick: () => {
            setOpen("featureUnlocked");
         },
         color: "#2D6B4D",
      },
   ];
   const close = () => {
      setOpen("None");
   };

   const fetchOffers = async () => {
      setloading(true);
      const request = await dispatch(GetAllOffers());
      if (request.type === "GetAllOffers/fulfilled") {
         setloading(false);
      } else {
         setloading(false);
         toast.error("Something went wrong");
      }
   };
   const checkIsFieldChanged = (id, values) => {
      const offer = offers?.find((item) => item.offer_id === id);
      console.log("OFFER FIELDS", offer);
      if (offer) {
         if (
            offer.offer_name === values.offer_name &&
            offer.offer_type === values.offer_type &&
            offer.bool_exp === values.bool_exp &&
            offer.discount_type === values.discount_type &&
            offer.discount_val === values.discount_val &&
            offer.status === values.status &&
            offer.offer_value === values.offer_value &&
            offer.maximum_allowed_discount === values.maximum_allowed_discount
         ) {
            return false;
         } else {
            return true;
         }
      }
   };
   const handleCreateOffer = async (data) => {
      if (offers?.length >= 5) {
         toast.error("You can't create more than 5 offers");
      } else if (
         (data.discount_type === "percentage" && data.maximum_allowed_discount === "") ||
         data.offer_name === "" ||
         data.discount_val === "" ||
         data.offer_value === "" ||
         data.offer_type === ""
      ) {
         toast.error("Please fill all the fields");
      } else {
         const body = {
            offer_name: data.offer_name,
            offer_type: data.offer_type,
            bool_exp: data.bool_exp,
            discount_type: data.discount_type,
            discount_val: parseFloat(data.discount_val),
            status: data.status,
            offer_value: data.offer_value,
            maximum_allowed_discount: data?.discount_type != "percentage" ? 0 : parseFloat(data.maximum_allowed_discount),
         };
         setloading(true);
         const request = await dispatch(CreateOffer(body));
         if (request.type === "CreateOffer/fulfilled") {
            setloading(false);
            toast.success("Offer created successfully");
            setAdding(false);
            fetchOffers();
         } else {
            setloading(false);
            toast.error("Something went wrong");
         }
      }
   };

   const handleEditOffer = async (data) => {
      const body = {
         offer_id: data.offer_id,
         offer_name: data.offer_name,
         offer_type: data.offer_type,
         bool_exp: data.bool_exp,
         discount_type: data.discount_type,
         discount_val: parseFloat(data.discount_val),
         status: data.status,
         offer_value: data.offer_value,
         maximum_allowed_discount: data?.discount_type != "percentage" ? 0 : parseFloat(data.maximum_allowed_discount),
      };

      setloading(true);
      const request = await dispatch(UpdateOffer(body));
      if (request.type === "UpdateOffer/fulfilled") {
         setloading(false);
         toast.success("Offer updated successfully");
         setAdding(false);
      } else {
         setloading(false);
         toast.error("Something went wrong");
      }

      fetchOffers();
   };

   const handleDeleteOffer = async (id) => {
      console.log("IDD", id);

      setloading(true);
      const request = await dispatch(DeleteOffer(id));
      if (request.type === "DeleteOffer/fulfilled") {
         setloading(false);
         toast.success("Offer deleted successfully");
         setAdding(false);
         fetchOffers();
         window.location.reload();
      } else {
         setloading(false);
         toast.error("Something went wrong");
      }
   };

   useEffect(() => {
      fetchOffers();
   }, []);

   const handle = () => {
      setAdding((p) => !p);
   };
   return (
      <Layout>
         <Loader isLoading={loading} />
         <DeleteModal
            message={<>Are you sure you want to delete this offer ?</>}
            isVisible={showDeleteModal}
            onClose={() => setshowDeleteModal(false)}
            handleDelete={() => {
               handleDeleteOffer(offerToDelete);
               setshowDeleteModal(false);
            }}
         />
         {CONTENT?.map((action) => {
            if (action.id === open) {
               console.log("ACTION", action);
               return (
                  <OffersModal
                     open={action?.id === open}
                     action={action}
                     close={close}
                  />
               );
            }
         })}
         <div className="MenuSubContainer">
            <div className="MenuHeader">
               <span className="d-flex align-items-center gap-4">
                  <h2>Offers</h2>
                  <h6 className="text-transform-none header-sub-text ">
                     <span>{offers?.length}</span>/5 offers created
                  </h6>
                  {/* {offers?.length === 5 && (
                     <span
                        style={{ textTransform: "none" }}
                        className="text-danger">
                        You can't create more than 5 offers
                     </span>
                  )} */}
               </span>
               {open === "None" && (
                  <Button
                     Size="md"
                     title={`Create offer`}
                     icon={<PlusIcon color={"#4361EE"} />}
                     border="none"
                     background="#ECEFFD"
                     color={"#4361EE"}
                     fontWeight="bold"
                     onClick={() => {
                        if (offers?.length < 5) {
                           handle();
                        } else {
                           toast.error("You can't create more than 5 offers");
                        }
                     }}
                  />
               )}
            </div>
            <Box sx={{ flexGrow: 1 }}>
               <Grid
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}>
                  {isAdding && (
                     <OfferCard
                        handleOffer={handleCreateOffer}
                        isEdit={false}
                        setAdding={setAdding}
                     />
                  )}
                  {open === "None" &&
                     offers?.map((item, index) => {
                        return (
                           <OfferCard
                              checkIsFieldChanged={checkIsFieldChanged}
                              isVisible={isVisible}
                              setVisible={setVisible}
                              handleDeleteOffer={() => {
                                 setofferToDelete(item.offer_id);
                                 setshowDeleteModal(true);
                              }}
                              data={item}
                              isEdit={true}
                              handleOffer={handleEditOffer}
                              setEdit={setEdit}
                           />
                        );
                     })}
               </Grid>
            </Box>
         </div>
      </Layout>
   );
}

export default Offers;
