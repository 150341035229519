import FilterSvg from 'assets/icons/FilterSvg';
import React from 'react';
import './index.scss';

function FilterButton() {
  return (
    <div className='filter-btn-container'>
      <FilterSvg />
    </div>
  );
}

export default FilterButton;
