import './index.scss';

// immediate parent should have position: relative to position the window correctly
const NotificationsWindow = ({ notifications, isVisible, setVisibility }) => {
    return (
        <>
        <div className={`NotificationsWindow__overlay ${isVisible ? 'opened' : 'closed'}`} onClick={() => {
            setVisibility(false);
        }}></div>
        <div className={`NotificationsWindow ${isVisible ? 'opened' : 'closed'}`}>
            <div className='NotificationsWindow__header'>
                <span>New updates</span>
                <button className='mark-as-read' onClick={() => {
                    setVisibility(false);
                }}>Close</button>
            </div>
            <div className='NotificationsWindow__content'>
                {notifications.map((notification) => {
                    return (
                    <div className='Notification' key={notification.id}>
                        <div className={`read-icon ${notification.read ? 'transparent' : 'blue'}`}>
                        </div>
                        <div className='Notification__content'>
                            <div>
                                {notification.title}
                            </div>
                            <div className='Notification__content__sender-time'>
                                {notification.sender ? `${notification.sender} • ` : ''}
                                {notification.dateTime.toLocaleString('en-US', { dateStyle: 'long', timeStyle: 'short' })}
                            </div>
                        </div>
                        {/* <div className='Notification__icon'>
                            <img className='Notification__icon__image' src={notification.icon} alt='icon' />
                        </div> */}
                    </div>
                    );
                })}
            </div>
            <div className='NotificationsWindow__footer'>
                <button className='view-all-notifications' onClick={() => {
                    setVisibility(false);
                }}>View all</button>
            </div>
        </div>
        </>
    );
}

export default NotificationsWindow;