import { createSlice } from '@reduxjs/toolkit';
import { CreateBestSellingItems, GetBestSellingItems } from 'store/action/BestSellingAction';

const BestSellingReducer = createSlice( {
  name: 'BestSelling',
  initialState: {
    bestSellingItems: { best_selling_items: [] },
    allItems: [],
  },
  reducers: {
    updateData: ( state, { payload } ) => {
      state.allItems = payload;
    },
  },
  extraReducers: {
    [GetBestSellingItems.fulfilled]: ( state, { payload } ) => {
      state.bestSellingItems.best_selling_items = payload.best_selling_items;
    },
    [CreateBestSellingItems.fulfilled]: ( state, { payload } ) => {
      state.bestSellingItems.best_selling_items = payload.item_ids;
    },
  },
} );
export const { updateData } = BestSellingReducer.actions;
export default BestSellingReducer.reducer;
