/** @format */

import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function CustomizedSnackbars({ open, onClose, message, handleClose }) {
   return (
      <div>
         <Snackbar
            open={open}
            autoHideDuration={4000}
            onClose={onClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }} // Ensure this is correctly set
         >
            <Alert
               onClose={handleClose}
               severity="warning"
               variant="filled"
               sx={{ width: "100%", backgroundColor: "red" }}>
               {message}
            </Alert>
         </Snackbar>
      </div>
   );
}
