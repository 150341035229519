/** @format */

import "./index.scss";
import { useState } from "react";
import Button from "../Button2/index";
import CreditBtnIcon from "assets/icons/CreditBtnIcon";
import { ToggleSwitch } from "components/ToggleSwitch";
import { CustomModal } from "components/DeleteModal";
import { useSelector } from "react-redux";

import Input from "../Input/index";
import { getCurrency } from "utils/functions";

const PointsCard = ({ points, amount, isCustom = false, handleChooseCredit }) => {
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);

   const [autoRenewal, setAutoRenewal] = useState(true);
   const [isVisible, setVisible] = useState(false);
   const [customCredit, setCustomCredit] = useState({
      no_of_orders: 0,
      amount: 0,
   });
   const handlePurchase = () => {
      handleChooseCredit(customCredit.no_of_orders);
      setVisible(false);
   };
   const handlePurchaseAndAutoRenew = () => {
      setAutoRenewal(true);
      setVisible((p) => !p);
   };

   return (
      <div className={isCustom ? "points-card-outer-custom" : "points-card-outer"}>
         <CustomModal
            isVisible={isVisible}
            message={"Automate your credit renewal!"}
            content={"You can enable auto-renewal option to automate your renewal, and you don't want to remember everytime to renew your plan!"}
            handleBtn1={handlePurchase}
            handleBtn2={() => {
               setAutoRenewal(true);
               setVisible(false);
            }}
            onClose={() => {
               setVisible(false);
            }}
         />
         <div className="points-card-inner-container">
            {isCustom ? (
               <div
                  className="d-flex align-items-center gap-2  justify-content-between mt-4 w-100"
                  style={{
                     paddingInline: "10%",
                  }}>
                  <div className="d-flex flex-column">
                     <span className="points-word mt-1">Enter no. of orders</span>
                     <input
                        type="number"
                        className="points-input mt-1"
                        // defaultValue={points}
                        placeholder="Enter count"
                        onChange={(e) => setCustomCredit({ ...customCredit, no_of_orders: e.target.value })}
                     />
                  </div>
                  <div className="d-flex flex-column">
                     <span className="points-word mt-1">Enter points:</span>
                     <input
                        type={"number"}
                        className="points-input mt-1"
                        placeholder={`${getCurrency(merchantDetails?.currency)}0.00`}
                        onChange={(e) => setCustomCredit({ ...customCredit, amount: e.target.value })}
                     />
                  </div>
               </div>
            ) : (
               <>
                  <div>
                     <span className="points">{points}</span>
                     <span className="points-word">credits</span>
                  </div>
                  <div className="amount">
                     {/* <span className="amount-word">Amount:</span> */}
                     <div>{`${merchantDetails?.currency} amount`}</div>
                     <div style={{ fontSize: "0.6rem", color: "#000000" }}>{"(+ 18% GST)"}</div>
                  </div>
               </>
            )}
         </div>

         {/* <div className="points-card-auto-renewal-container">
        <div>Auto renewal</div>
        <div className="point-card-switch-wrapper">
          <ToggleSwitch
            falseValueBg={"#E6E4D7"}
            noWidth
            handleValue={() => {
              setAutoRenewal(p => !p);
            }}
            isAvailable={autoRenewal}
          />
        </div>
      </div> */}
         {/* <div
        className="buy-now-btn"
        style={{
          opacity: isCustom && (customPoints === 0 || customPoints === "") ? 0.5 : 1,
        }}>
        <button disabled={isCustom && (customPoints === 0 || customPoints === "")}>
          <span className="buy-now">Buy Now</span>
        </button>
      </div> */}
         <Button
            disabled={isCustom && (customCredit.no_of_orders === 0 || customCredit.no_of_orders === "") && (customCredit.amount === 0 || customCredit.amount === "")}
            title={"Buy now"}
            width={"10rem"}
            Size={"md"}
            border={"none"}
            icon={<CreditBtnIcon />}
            background={"#4361ee"}
            color={"#fff"}
            onClick={() => {
               handlePurchase();
            }}
         />
      </div>
   );
};

export default PointsCard;
