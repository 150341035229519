/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const ADDONS_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/add_ons/`;
const CUSTOMIZATIONS_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/customisation/`;

export const GetAddons = createAsyncThunk(`GetAddons`, async (data, { rejectWithValue }) => {
   console.log("GET ADDONS");
   try {
      const response = await axios.get(ADDONS_ENDPOINT, {
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      if (response.status === 201 || response.status === 200) {
         const resData = await response.data;
         return resData;
      } else {
         return rejectWithValue("Can't Get Addons");
      }
   } catch (error) {
      console.log(error);
      throw error;
   }
});

export const CreateAddons = createAsyncThunk(`CreateAddons`, async (data, { rejectWithValue }) => {
   try {
      const response = await axios.post(ADDONS_ENDPOINT, data, {
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      console.log("DATA", data);
      console.log("RESPONSe", response);
      if (response.status === 201 || response.status === 200) {
         const resData = await response.data;

         return resData;
      } else {
         return rejectWithValue("Can't Create Addon");
      }
   } catch (error) {
      console.log(error);
      throw error;
   }
});

export const EditAddon = createAsyncThunk(`EditAddon`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${ADDONS_ENDPOINT}${data.add_on_id}`, {
         method: "PUT",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(data),
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't edit addon");
      }
   } catch (error) {
      throw error;
   }
});

export const DeleteAddon = createAsyncThunk(`DeleteAddon`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${ADDONS_ENDPOINT}${data.id}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      if (response.status == 201 || response.status == 200) {
         return data;
      } else {
         return rejectWithValue("Can't delete category");
      }
   } catch (error) {
      throw error;
   }
});

export const GetCustomizations = createAsyncThunk(`GetCustomizations`, async (data, { rejectWithValue }) => {
   console.log("GET CUSTOMIZATIONS");
   try {
      const response = await axios.get(CUSTOMIZATIONS_ENDPOINT, {
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      if (response.status === 201 || response.status === 200) {
         const resData = await response.data;
         return resData;
      } else {
         return rejectWithValue("Can't Get Cstomizations");
      }
   } catch (error) {
      console.log(error);
      throw error;
   }
});

export const CreateCustomizations = createAsyncThunk(`CreateCustomizations`, async (data, { rejectWithValue }) => {
   try {
      const response = await axios.post(CUSTOMIZATIONS_ENDPOINT, data, {
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      console.log("DATA", data);
      console.log("RESPONSe", response);
      if (response.status === 201 || response.status === 200) {
         const resData = await response.data;

         return resData;
      } else {
         return rejectWithValue("Can't Create Customization");
      }
   } catch (error) {
      console.log(error);
      throw error;
   }
});

export const EditCustomization = createAsyncThunk(`EditCustomization`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${CUSTOMIZATIONS_ENDPOINT}${data.customisation_id}`, {
         method: "PUT",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(data),
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't edit customization");
      }
   } catch (error) {
      throw error;
   }
});

export const DeleteCustomization = createAsyncThunk(`DeleteCustomization`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${CUSTOMIZATIONS_ENDPOINT}${data.id}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      if (response.status == 201 || response.status == 200) {
         return data;
      } else {
         return rejectWithValue("Can't delete category");
      }
   } catch (error) {
      throw error;
   }
});
