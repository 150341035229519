import { createAsyncThunk } from '@reduxjs/toolkit';
const SUBSCRIPTIONS_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/subscriptions`;
const MERCHANT_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/plans/`;

export const GetSubscription = createAsyncThunk(
  `GetSubscription`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(`${SUBSCRIPTIONS_API_ENDPOINT}/all`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('savedT')}`,
          'ngrok-skip-browser-warning': 'any',
        },
      });
      if (response.status === 200 || response.status === 201) {
        const resData = await response.json();
        console.log('SUBSCRITION DETAILS', resData);
        return resData;
      } else {
        return rejectWithValue("Can't Get Subscriptions/ Details");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const GetCurrentSubscription = createAsyncThunk(
  `GetCurrentSubscription`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(`${SUBSCRIPTIONS_API_ENDPOINT}/current_subscription`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('savedT')}`,
          'ngrok-skip-browser-warning': 'any',
        },
      });
      if (response.status === 200 || response.status === 201) {
        const resData = await response.json();
        console.log('CURRENT SUBSCRITION DETAILS', resData);
        return resData;
      } else if (response.status === 404) {
        return {
          plan_active: false,
        };
      } else {
        return rejectWithValue("Can't Get Current Subscriptions/ Details");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const GetMerchantPlans = createAsyncThunk(
  `GetMerchantPlans`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(MERCHANT_API_ENDPOINT, {
        headers: {
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'any',
        },
      });
      if (response.status === 200 || response.status === 201) {
        const resData = await response.json();
        console.log('MERCHANT PLANS DETAILS', resData);
        return resData;
      } else {
        return rejectWithValue("Can't Get Merchant Plans Details");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const CreateSubscription = createAsyncThunk(`CreateSubscription`, async (data) => {
  try {
    const response = await fetch(`${SUBSCRIPTIONS_API_ENDPOINT}/create_subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('savedT')}`,
        'ngrok-skip-browser-warning': 'any',
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200 || response.status === 201) {
      const resData = await response.json();
      console.log('SUCCESSFULLY CREATED SUBCRIPTION DETAILS', resData);
      return resData;
    } else {
      return 'Error';
    }
  } catch (error) {
    throw error;
  }
});

export const AttemptSubscription = createAsyncThunk(
  `AttemptSubscription`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(`${SUBSCRIPTIONS_API_ENDPOINT}/attempt_subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('savedT')}`,
          'ngrok-skip-browser-warning': 'any',
        },
        body: JSON.stringify(data),
      });
      if (response.status === 200 || response.status === 201) {
        const resData = await response.json();
        console.log('SUCCESSFULLY ATTEMPT SUBSCRIPTION DETAILS', resData);
        return resData;
      } else {
        return false;
      }
    } catch (error) {
      throw error;
    }
  },
);
