/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { GetAddons, CreateAddons, EditAddon, GetCustomizations, CreateCustomizations, EditCustomization, DeleteAddon, DeleteCustomization } from "store/action/AddonsAction";

const AddonsReducer = createSlice({
   name: "Addons",
   initialState: {
      addons: [],
      customizations: [],
   },
   reducers: {},
   extraReducers: {
      [GetAddons.fulfilled]: (state, { payload }) => {
         console.log("payload", payload);
         state.addons = payload;
      },
      [CreateAddons.fulfilled]: (state, { payload }) => {
         state.addons = [...state.addons, payload];
      },
      [EditAddon.fulfilled]: (state, { payload }) => {
         state.addons = state.addons.map((addon) => {
            if (addon.add_on_id === payload.add_on_id) {
               return payload;
            }
            return addon;
         });
      },
      [DeleteAddon.fulfilled]: (state, { payload }) => {
         state.addons = state.addons.filter((addon) => addon.add_on_id !== payload.id);
      },
      [GetCustomizations.fulfilled]: (state, { payload }) => {
         state.customizations = payload;
      },
      [CreateCustomizations.fulfilled]: (state, { payload }) => {
         state.customizations = [...state.customizations, payload];
      },
      [EditCustomization.fulfilled]: (state, { payload }) => {
         state.customizations = state.customizations.map((customization) => {
            if (customization.customisation_id === payload.customisation_id) {
               return payload;
            }
            return customization;
         });
      },
      [DeleteCustomization.fulfilled]: (state, { payload }) => {
         state.customizations = state.customizations.filter((customization) => customization.customisation_id !== payload.id);
      },
   },
});
export const { updateData } = AddonsReducer.actions;
export default AddonsReducer.reducer;
