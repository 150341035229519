import { createSlice } from "@reduxjs/toolkit";
import { CreateCredit, getAllCredits } from "store/action/CreditsAction";

const initialState = {
  credits: {},
};

const CreditsReducer = createSlice({
  name: "CreditsReducer",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllCredits.fulfilled]: (state, action) => {
      state.credits = action.payload;
    },
    [CreateCredit.fulfilled]: (state, action) => {
      state.credits = {
        no_of_credits: state.credits?.no_of_credits + action.payload?.no_of_credits,
      };
    },
  },
});

export const {} = CreditsReducer.actions;

export default CreditsReducer.reducer;
