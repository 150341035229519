/** @format */

import React, { useState } from "react";
import "./style.scss";
import AddButton from "components/AddButton";
import { useSelector } from "react-redux";
import { getCurrency } from "utils/functions";

function FoodItemCard({ isAdded, item, disabled, addToRecommended, provided }) {
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);

   const [showMore, setShowMore] = useState(true);
   const style1 = {
      opacity: isAdded ? "0.5" : "1",
   };

   function handleTextDescription() {
      setShowMore((p) => !p);
   }

   return (
      <div
         style={style1}
         className="food-item-container">
         <div className="food-item-image-wrapper">
            {item?.image_url !== null && item?.image_url !== "" ? (
               <img
                  className="food-item-image"
                  src={item?.image_url + "?" + new Date().getMilliseconds()}
                  alt=""
               />
            ) : (
               <div className="food-item-image-logo">{item?.item_name.charAt(0).toUpperCase()}</div>
            )}
         </div>
         <div className="MenuItemContentWrapper">
            <div className="food-item-right-section ">
               <div className="food-item-top-content">
                  <div className="food-item-name">{item?.item_name}</div>
                  <div className="food-item-btns">
                     <AddButton
                        onClick={addToRecommended}
                        add={isAdded}
                        disabled={disabled || isAdded}
                     />
                  </div>
               </div>
               <div className="food-item-price">
                  {getCurrency(merchantDetails.currency)} {item?.price}
               </div>
               <div className="food-item-text">
                  {item.description?.length > 50 ? (
                     <>
                        {showMore ? item.description.substring(0, 35) + "..." : item.description}
                        <span
                           onClick={handleTextDescription}
                           className="SliceBtn">
                           {showMore ? "See more" : " See less"}
                        </span>
                     </>
                  ) : (
                     item.description
                  )}
               </div>
            </div>
         </div>
      </div>
   );
}

export default FoodItemCard;
