/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import axios from "axios";
const API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/menu/`;
const CATEGORY_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/category/`;
const CATEGORYITEM_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/item/`;
const DRAG_AND_DROP_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/`;

export const GetMenu = createAsyncThunk(`GetMenu`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(API_ENDPOINT, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't  fetch the menu");
      }
   } catch (error) {
      throw error;
   }
});

export const CreateCategory = createAsyncThunk(`CreateCategory`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(CATEGORY_API_ENDPOINT, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(data),
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't add category");
      }
   } catch (error) {
      throw error;
   }
});

export const EditCategory = createAsyncThunk(`EditCategory`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${CATEGORY_API_ENDPOINT}${data.id}`, {
         method: "PUT",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(data.body),
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't add category");
      }
   } catch (error) {
      throw error;
   }
});
export const DeleteCategory = createAsyncThunk(`DeleteCategory`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${CATEGORY_API_ENDPOINT}${data.id}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't delete category");
      }
   } catch (error) {
      throw error;
   }
});

//CategoryItem

export const CreateCategoryItem = createAsyncThunk(`CreateCategoryItem`, async (formData, { rejectWithValue }) => {
   try {
      const response = await axios.post(CATEGORYITEM_API_ENDPOINT, formData, {
         headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.data;
         return resData;
      } else {
         return rejectWithValue("Can't add category item");
      }
   } catch (error) {
      // throw error;
      console.log(error);
   }
});

export const EditCategoryItem = createAsyncThunk(`EditCategoryItem`, async (data, { rejectWithValue }) => {
   console.log("EditCategoryItem", typeof data);
   try {
      const response = await axios.put(`${CATEGORYITEM_API_ENDPOINT}${data.item_id}`, data, {
         headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });

      if (response.status == 201 || response.status == 200) {
         const Data = await response.data;
         console.log("IMAGE", data);

         console.log(Data);

         const resData = {
            ...Data,
            image_url: Data?.image === null ? (Data?.image_url === "" ? null : Data?.image_url) : URL.createObjectURL(data.image),
         };

         console.log("heyyy", resData);
         return resData;
      } else {
         return rejectWithValue("Can't add category item");
      }
   } catch (error) {
      // throw error;
      console.log(error);
   }
});

export const DeleteItem = createAsyncThunk(`DeleteItem`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${CATEGORYITEM_API_ENDPOINT}${data.id}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't delete category");
      }
   } catch (error) {
      throw error;
   }
});

export const DragAndDrop = createAsyncThunk("DragAndDrop", async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${DRAG_AND_DROP_API_ENDPOINT}drag_and_drop/`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(data),
      });
      if (response.status === 200 || response.status === 201) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't drag and drop");
      }
   } catch (error) {
      throw error;
   }
});
