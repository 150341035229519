/** @format */

import React from "react";

const BlueTickIcon = () => {
   return (
      <svg
         width="18"
         height="18"
         viewBox="0 0 18 14"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
         <path
            d="M6.55156 13.0016L0.851562 7.30156L2.27656 5.87656L6.55156 10.1516L15.7266 0.976562L17.1516 2.40156L6.55156 13.0016Z"
            fill="#4361EE"
         />
      </svg>
   );
};

export default BlueTickIcon;
