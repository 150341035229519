import { faSliders, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./index.scss";

function Filters({ count, clearFilters }) {
  return (
    <button
      className=" dropdown-toggle d-flex align-items-center w-100"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      id="filters-toggle">
      <span id="filters-toggle">
        <FontAwesomeIcon icon={faSliders} />
      </span>
      &nbsp;&nbsp;
      <span>Filters</span>
      &nbsp;&nbsp;
      {count() !== 0 && <span className="orders-filter-count">{count()}</span>}
      &nbsp;&nbsp;
      {count() !== 0 && (
        <div
          className="orders-filter-clear"
          onClick={e => {
            e.stopPropagation();
            clearFilters();
          }}>
          <FontAwesomeIcon icon={faXmark} />
        </div>
      )}
    </button>
  );
}

export default Filters;
