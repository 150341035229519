/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { CreateQRCode, GetAllQrCodes } from "store/action/QRActions";

const initialState = {
   qrCodes: [],
};

const QRCodeReducer = createSlice({
   name: "QRCodeReducer",
   initialState,
   reducers: {},
   extraReducers: {
      [GetAllQrCodes.fulfilled]: (state, { payload }) => {
         if (payload !== null) {
            state.qrCodes = payload?.qr_codes;
         } else {
            state.qrCodes = {
               "dine-in": [],
               standalone: {},
            };
         }
      },
      [CreateQRCode.fulfilled]: (state, { payload }) => {
         console.log("QR CReated", payload?.qr_codes);
         state.qrCodes = payload?.qr_codes;
      },
   },
});

export const {} = QRCodeReducer.actions;

export default QRCodeReducer.reducer;
