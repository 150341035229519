/** @format */

import DownArrowIcon from "assets/icons/DownArrowIcon";
import UpArrowIcon from "assets/icons/UpArrowIcon";
import React from "react";
import "./index.scss";

function Select({ width, Dropdown, onClick, Options }) {
   return (
      <>
         <div
            className="SelectInputContainer"
            style={{
               width: width,
            }}
            onClick={onClick}>
            Select <span className="ArrowIconWrapper">{Dropdown ? <UpArrowIcon /> : <DownArrowIcon />}</span>
         </div>

         {Dropdown && (
            <div className="OptionsContainer">
               {Options.map((item, index) => (
                  <option
                     value={item.value}
                     key={index}
                     style={{
                        position: "fixed",
                     }}>
                     {item.name}
                  </option>
               ))}
            </div>
         )}
      </>
   );
}

export default Select;
