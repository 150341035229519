import Input from 'components/FormikInput';
import ImageUploader from 'components/ImageUploader';
import { Form, Formik } from 'formik';
import React from 'react';

function EditForm({ setFieldValue }) {
  return (
    <div className='editForm-MainContainer'>
      <Form className='EditComboFormContainer'>
        <div className='FormGroup'>
          <label htmlFor=''>Total Price</label>
          <Input
            rupee
            height={'2.5rem'}
            width={'8rem'}
            name={'price'}
            type={'number'}
            onChange={(e) => setFieldValue('price', parseInt(e.target.value))}
          />{' '}
        </div>
        <div className='FormGroup'>
          <label htmlFor=''>Combo Price</label>
          <Input
            rupee
            height={'2.5rem'}
            width={'8rem'}
            name={'combo_price'}
            type={'number'}
            onChange={(e) => setFieldValue('combo_price', parseInt(e.target.value))}
          />{' '}
        </div>
        <div className='FormGroup'>
          <label htmlFor=''>Discount</label>
          <div className='combo-row-inputs '>
            <section className='discountSection editDiscountSection'>
              <input
                onChange={(e) => setFieldValue('discount', parseInt(e.target.value))}
                name='discount'
                id='discount'
                type={'number'}
              />
              <select>
                <option value='percent'>&#xFF05;</option>
                <option value='rupee'>&#x20B9;</option>
              </select>
            </section>
          </div>
        </div>
        <div className='FormGroup'>
          <label htmlFor=''>Price after discount</label>
          <Input
            rupee
            height={'2.5rem'}
            width={'8rem'}
            type={'number'}
            name={'price_after_discount'}
            onChange={(e) => setFieldValue('price_after_discount', parseInt(e.target.value))}
          />
        </div>
      </Form>
    </div>
  );
}

export default EditForm;
