/** @format */

import React from "react";

const LockIcon = () => {
   return (
      <svg
         width="59"
         height="70"
         viewBox="0 0 59 70"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
         <path
            d="M29.7907 0.5C20.0812 0.5 11.9317 7.08105 9.49805 16.0179L17.498 18C18.3038 15.0505 20.2681 12.7262 22.8163 11.0364C25.3645 9.34659 28.4393 8.63879 31.4698 9.04435C34.5004 9.44991 37.2808 10.9413 39.2951 13.2417C41.3093 15.542 42.4204 18.4951 42.4223 21.5526V42.6053H50.8433V21.5526C50.8433 9.92526 41.418 0.5 29.7907 0.5Z"
            fill="#BCBEC0"
         />
         <path
            d="M29.498 0.5C39.2075 0.5 47.5644 7.06316 49.998 16L41.933 17.8383C41.1273 14.8888 39.2788 12.3318 36.7306 10.6421C34.1824 8.95227 31.1076 8.24446 28.0771 8.65002C25.0465 9.05559 22.266 10.547 20.2518 12.8473C18.2376 15.1477 17.1264 18.1007 17.1246 21.1583V42.2109H8.70356V21.1583C8.70356 9.53094 17.8707 0.5 29.498 0.5Z"
            fill="#BCBEC0"
         />
         <path
            d="M58.998 61.1111C58.998 63.4686 57.9796 65.7295 56.1667 67.3965C54.3539 69.0635 51.8951 70 49.3314 70H10.6647C8.10095 70 5.6422 69.0635 3.82935 67.3965C2.0165 65.7295 0.998047 63.4686 0.998047 61.1111V38.8889C0.998047 36.5314 2.0165 34.2705 3.82935 32.6035C5.6422 30.9365 8.10095 30 10.6647 30H49.3314C51.8951 30 54.3539 30.9365 56.1667 32.6035C57.9796 34.2705 58.998 36.5314 58.998 38.8889V61.1111Z"
            fill="#FFAC33"
         />
      </svg>
   );
};

export default LockIcon;
