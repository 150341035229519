/** @format */

import React from "react";
import "./index.scss";
import { Field as FormikField } from "formik";
import { height } from "@mui/system";
import { useSelector } from "react-redux";
import { getCurrency } from "utils/functions";

const CustomInput = ({
   field,
   form: { touched, errors },
   label,
   textArea,
   width,
   percentage,
   height,
   required,
   optional,
   Width,
   rupee,
   opacity,
   Select,
   defaultHolder,
   selectOnClick,
   Options,
   customPlaceholder,
   noLabel,
   maxLength,
   ...props
}) => {
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);

   return (
      <div
         style={{
            opacity: props.disabled ? 0.5 : 1,
         }}
         className={props.type === "checkbox" ? "inputMainContainer" : ""}>
         {!noLabel && (
            <div className={props.type === "checkbox" ? "CheckBoxLabel" : "labelText"}>
               {label} <span className="requiredtag">{required && "*"}</span>
               <span className="optionaltag">{optional && "(Optional)"}</span>
            </div>
         )}

         {(textArea && (
            <>
               <textarea
                  {...field}
                  className="TextArea text-xs"
                  maxLength={maxLength}
                  {...props}
                  rows={5}
                  style={{
                     borderColor: touched[field.name] && errors[field.name] && "red",
                     width: Width,
                  }}
               />
               <div className="ErrorMessage">{touched[field.name] && errors[field.name] ? errors[field.name] : ""}</div>
            </>
         )) || (
            <div className="InputErrorWrapper">
               <div
                  className={(props.type === "file" && "FileInput") || (props.type === "checkbox" && "inputCheckBox") ? "" : "inputContainer"}
                  style={{
                     borderColor: touched[field.name] && errors[field.name] && "red",
                     width: width,
                     height: height,
                     opacity: opacity,
                     backgroundColor: props.disabled && "#e7e8e9",
                  }}>
                  {rupee && <span className="RupeesIcon">{getCurrency(merchantDetails?.currency)}</span>}
                  {percentage && <span className="RupeesIcon">%</span>}
                  {(Select && (
                     <select
                        onChange={selectOnClick}
                        placeholder={"Select"}
                        className="Holder"
                        {...props}>
                        {defaultHolder && (
                           <option
                              hidden
                              color="#000000">
                              {defaultHolder}
                           </option>
                        )}
                        {Options.map((item, index) => (
                           <option
                              className="w-[100%] "
                              value={item.value}
                              key={index}>
                              <div>{item.name}</div> <div>{item?.symbol && `(${item?.symbol})`}</div>
                           </option>
                        ))}
                     </select>
                  )) || (
                     <>
                        <input
                           {...field}
                           {...props}
                        />
                        {customPlaceholder && (
                           <span
                              style={{
                                 fontWeight: 500,
                                 marginRight: "10px",
                              }}>
                              {customPlaceholder}
                           </span>
                        )}
                     </>
                  )}
               </div>

               {props.type !== "checkbox" && <div className="ErrorMessage">{touched[field.name] && errors[field.name] ? errors[field.name] : ""}</div>}
            </div>
         )}
      </div>
   );
};

const Input = (props, { normal }) => {
   return (
      <FormikField
         {...props}
         component={CustomInput}
      />
   );
};

export default Input;
