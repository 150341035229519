/** @format */

// import { Component } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import "./style.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getCurrency } from "utils/functions";

function OrderEdit(props) {
   const [tempOrder, setTempOrder] = useState(props.order);
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);

   const closeSection = (e) => {
      document.getElementById("side-wrapper-edit").classList.add("close");
      document.getElementById("side-wrapper-edit").classList.remove("open");
      document.getElementById("side-wrapper").classList.remove("close");
      document.getElementById("side-wrapper").classList.add("open");
   };
   const decQuantity = (e) => {
      if (tempOrder.items[e.target.parentElement.parentElement.dataset.index].quantity > 1) {
         setTempOrder({
            ...tempOrder,
            items: tempOrder.items.map((item, index) => {
               if (index === parseInt(e.target.parentElement.parentElement.dataset.index)) {
                  item.quantity -= 1;
               }
               return item;
            }),
         });
         console.log(tempOrder);
      }
   };
   const incQuantity = (e) => {
      setTempOrder({
         ...tempOrder,
         items: tempOrder.items.map((item, index) => {
            if (index === parseInt(e.target.parentElement.parentElement.dataset.index)) {
               item.quantity += 1;
            }
            return item;
         }),
      });
      console.log(tempOrder);
   };
   const delItem = (e) => {
      if (e.target.innerHTML === "DEL") {
         e.target.innerHTML = "UNDO";
      } else {
         e.target.innerHTML = "DEL";
      }
   };
   const confirmEdit = (e) => {
      document.querySelectorAll(".delBtn").forEach((btn) => {
         if (btn.innerHTML === "UNDO") {
            console.log(btn.parentElement.parentElement.dataset.index);
            tempOrder.items.splice(btn.parentElement.parentElement.dataset.index, 1);
            btn.innerHTML = "DEL";
         }
      });
      console.log(tempOrder);
      props.setOrders(
         props.orders?.map((order) => {
            if (order.id === tempOrder.id) {
               order = tempOrder;
            }
            return order;
         }),
      );
      closeSection();
   };
   const addItemClicked = (e) => {
      document.getElementById("side-wrapper-edit").classList.add("close");
      document.getElementById("side-wrapper-edit").classList.remove("open");
      document.getElementById("side-wrapper-add").classList.remove("close");
      document.getElementById("side-wrapper-add").classList.add("open");
   };

   return (
      <div
         id="side-wrapper-edit"
         className="d-flex justify-content-end close">
         <div>
            <button
               id="close"
               className="fs-3 m-4"
               onClick={closeSection}>
               ×
            </button>
         </div>
         <div
            id="order-side-edit"
            className="d-flex flex-column justify-content-start p-3">
            <h4 className="text-center">Edit Order</h4>
            <div className="d-flex justify-content-between p-4 px-5 align-items-center">
               <h5 className="p-0">Item Details</h5>
               <button
                  className="b-btn-h"
                  onClick={addItemClicked}>
                  Add Items
               </button>
            </div>
            <div id="padded-edit">
               <table
                  id="items-table-edit"
                  className="w-100">
                  <thead>
                     <tr>
                        <th scope="col">ITEM NAMES</th>
                        <th
                           className="text-center"
                           scope="col">
                           QTY
                        </th>
                        <th
                           className="text-center"
                           scope="col">
                           RATE
                        </th>
                        <th
                           className="text-center"
                           scope="col">
                           QTY
                        </th>
                        <th
                           className="text-center"
                           scope="col">
                           DELETE
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {tempOrder?.items_ordered?.map((item, index) => {
                        return (
                           <tr
                              key={index}
                              data-index={index}>
                              <td>{item.name}</td>
                              <td className="text-center">{item.quantity}</td>
                              <td className="text-center">
                                 {getCurrency(merchantDetails?.currency)}
                                 {item.price}
                              </td>
                              <td
                                 className="text-center"
                                 id="qty-edit">
                                 <button
                                    className="dg-btn"
                                    onClick={decQuantity}>
                                    -
                                 </button>
                                 <span className="">{item.quantity}</span>
                                 <button
                                    className="dg-btn"
                                    onClick={incQuantity}>
                                    +
                                 </button>
                              </td>
                              <td className="text-center">
                                 <button
                                    className="r-btn-h p-1 delBtn"
                                    onClick={delItem}>
                                    DEL
                                 </button>
                              </td>
                           </tr>
                        );
                     })}
                  </tbody>
               </table>
            </div>
            <div className="d-flex justify-content-between p-4 px-5 align-items-center">
               <button
                  className="r-btn-h2"
                  onClick={closeSection}>
                  Cancel
               </button>
               <button
                  className="g-btn"
                  onClick={confirmEdit}>
                  Confirm
               </button>
            </div>
         </div>
      </div>
   );
}

export default OrderEdit;
