import React from 'react';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import './index.scss';
import { Toaster } from 'react-hot-toast';

function Layout({ children }) {
  return (
    <div className='LayoutMainContainer'>
      <Sidebar />
      <div className='LayoutContainer'>
        {/* <Topbar /> */}
        <div className='BodyContainer'>
          <Toaster />
          {children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
