/** @format */

import DraggableIndicator from "components/DragableIndicator";
import React from "react";
import Chic from "./Chic.jpeg";
import "./style.scss";
import { useSelector } from "react-redux";
import { getCurrency } from "utils/functions";

function RecommendedItemAdded({ item, handleRemove, categoryTitle, isRemoved }) {
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);

   const style1 = {
      opacity: isRemoved ? "0.5" : "1",
      width: "100%",
      padding: "2%",
      border: "none",
      margin: 0,
   };

   return (
      <div
         className="recommended-item-added-container"
         style={style1}>
         <DraggableIndicator />
         <div className="recommended-item-added-image-wrapper">
            {item?.image_url !== null && item?.image_url !== "" ? (
               <img
                  className="recommended-item-added-image"
                  src={item?.image_url}
                  alt=""
               />
            ) : (
               <div className="recommended-item-added-image-logo">{item?.item_name.charAt(0).toUpperCase()}</div>
            )}
         </div>
         <div className="recommended-item-added-right-section">
            <div className="recommended-item-added-second-column">
               <button className="recommended-item-added-second-column-btn px-1">{categoryTitle}</button>
               <div className="recommended-item-added-second-column-item-name">{item?.item_name}</div>
               <div className="recommended-item-added-second-column-item-price">
                  {getCurrency(merchantDetails?.currency)} {item?.price}
               </div>
            </div>
            <div className="recommended-item-added-third-column-container">
               <button
                  className="recommended-item-added-third-column-btn px-1"
                  onClick={handleRemove}>
                  Remove
               </button>
            </div>
         </div>
      </div>
   );
}

export default RecommendedItemAdded;
