import React from 'react';

function ExportIcon() {
  return (
    <svg width='25' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 16.5V14.5H10V16.5H0ZM4 12.5V4.325L1.4 6.9L0 5.5L5 0.5L10 5.5L8.6 6.9L6 4.325V12.5H4Z'
        fill='white'
      />
    </svg>
  );
}

export default ExportIcon;
