/** @format */

import Button from "components/Button2";
import * as yup from "yup";
import { useEffect, useState, useRef } from "react";
import CrossIcon from "assets/icons/CrossIcon";
import TickIcon from "assets/icons/TickIcon";
import { Form, Formik } from "formik";
import Input from "components/FormikInput";
import NonVegIcon from "assets/icons/NonVegIcon";
import VegIcon from "assets/icons/VegIcon";
import TypeChip from "components/TypeChip";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CreateAddons, EditAddon, GetAddons } from "store/action/AddonsAction";
import CreateableSelectDropdown from "components/CreateableSelectDropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import { UpdateModal } from "components/UpdateModal";
import { getCurrency } from "utils/functions";
import toast from "react-hot-toast";

const AddonsTab = ({ onClose, defaultAdd, editIndex, addons, onAddNew, onSave, onRemove }) => {
   const [updatingIndex, setUpdatingIndex] = useState(null);
   const allAddons = useSelector((state) => state.AddonsReducer.addons);
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);
   const [showUpdateModal, setshowUpdateModal] = useState(false);
   const dispatch = useDispatch();
   const formRef = useRef();
   const inputRef = useRef(null);

   useEffect(() => {
      defaultAdd && setUpdatingIndex("new");
   }, [defaultAdd]);

   useEffect(() => {
      editIndex !== null && setUpdatingIndex(editIndex);
   }, [editIndex]);
   const [initialAddonsValues, setinitialAddonsValues] = useState({
      item_name: "",
      item_type: "",
      in_stock: false,
      price: "",
      discount: "",
   });

   const addonsValidationSchema = yup.object({
      item_name: yup.string().required("Field is required").nullable(),
      item_type: yup.string().required("Field is required").nullable(),
      price: yup.number("Enter a valid number").required("Field is required").nullable(),
   });

   const createAddon = async (data) => {
      const result = await dispatch(CreateAddons(data));
      onAddNew(result?.payload);
   };

   const onSubmit = (values) => {
      if (updatingIndex === "new") {
         createAddon(values);
         defaultAdd ? onClose() : setUpdatingIndex(null);
         toast.success("Addon created successfully");
      } else {
         setshowUpdateModal(true);
      }
   };

   const updateAddon = () => {
      dispatch(EditAddon(formRef?.current?.values));
      onClose();
      toast.success("Addon updated successfully");
   };

   const getSearchData = () => {
      let data = [];
      allAddons.map((item) => {
         const checkAlreadyAdded = addons?.findIndex((add_on) => add_on?.add_on_id === item?.add_on_id);
         if (checkAlreadyAdded === -1)
            data.push({
               ...item,
               value: item.item_name,
               label: item.item_name,
            });
      });
      return data;
   };

   const onCreateNewValue = (obj) => {
      setinitialAddonsValues({
         ...initialAddonsValues,
         item_name: obj.value,
      });
      setUpdatingIndex("new");
   };

   const handleFocus = () => {
      inputRef.current.select();
   };

   return updatingIndex !== null ? (
      <div className="add-on-form-container">
         <h3>{editIndex !== null ? "Edit add on" : "Add new add on"}</h3>
         <UpdateModal
            message={"If you modify this add-on, it will be updated in all items where it is included"}
            isVisible={showUpdateModal}
            handleUpdate={updateAddon}
            handleCancel={() => setshowUpdateModal(false)}
         />
         <Formik
            initialValues={updatingIndex === "new" ? initialAddonsValues : allAddons[updatingIndex]}
            validationSchema={addonsValidationSchema}
            innerRef={formRef}
            onSubmit={onSubmit}>
            {({ isValid, values, setFieldValue }) => {
               return (
                  <Form className="AddItemModalCustomizationForm">
                     <Input
                        required
                        placeholder={"(Eg: Chicken Soup)"}
                        width={"100%"}
                        label={"Item Name"}
                        name="item_name"
                     />

                     <div
                        className="d-flex justify-content-between flex-wrap"
                        style={{ gap: "1rem" }}>
                        <div className="itemTypeWrapper">
                           <label
                              htmlFor=""
                              className="labelText">
                              Item type <span className="requiredtag">*</span>
                           </label>
                           <div className="formGroup">
                              <TypeChip
                                 title={"Veg"}
                                 ActiveStyle={values.item_type === "veg" && "VegActive"}
                                 icon={<VegIcon />}
                                 onclick={() => {
                                    setFieldValue("item_type", "veg");
                                 }}
                              />
                              <TypeChip
                                 ActiveStyle={values.item_type === "non-veg" && "NonVegActive"}
                                 title={"Non Veg"}
                                 icon={<NonVegIcon />}
                                 onclick={() => {
                                    setFieldValue("item_type", "non-veg");
                                 }}
                              />
                           </div>{" "}
                        </div>
                        <div className="itemTypeWrapper">
                           <label
                              htmlFor=""
                              className="labelText">
                              Stock Details
                           </label>
                           <TypeChip
                              ActiveStyle={""}
                              title={"In-Stock"}
                              icon={null}
                              onclick={() => {
                                 setFieldValue("in_stock", !values.in_stock);
                              }}
                              showSwitch={true}
                              isActive={values.in_stock}
                           />
                        </div>
                     </div>

                     <div
                        className="d-flex"
                        style={{ gap: "1rem" }}>
                        <Input
                           required
                           placeholder={"0"}
                           rupee
                           width={"100%"}
                           label={"Price"}
                           name="price"
                           id="price"
                           onFocus={handleFocus}
                           ref={inputRef}
                        />
                     </div>
                     <div className="AddItemBtnWrapper">
                        <Button
                           Size="sm"
                           title="Cancel"
                           border="none"
                           icon={<CrossIcon color="white" />}
                           background={"#D90429"}
                           color={"#fff"}
                           onClick={() => {
                              defaultAdd ? onClose() : setUpdatingIndex(null);
                           }}
                        />
                        <Button
                           Size="sm"
                           title="Save"
                           border="none"
                           icon={<TickIcon />}
                           background={"#2FBF71"}
                           color={"#fff"}
                           type="submit"
                        />
                     </div>
                  </Form>
               );
            }}
         </Formik>
      </div>
   ) : (
      <div className="position-relative h-full">
         <div className="add-on-search-container">
            <CreateableSelectDropdown
               isClearable
               options={getSearchData()}
               onChange={(val) => onAddNew(val)}
               placeholder={"Search add on"}
               onCreate={(val) => onCreateNewValue(val)}
            />
         </div>
         <div className="my-3">
            {addons?.map((addon, index) => (
               <div
                  className="CustomizationCard"
                  key={index}>
                  <div>
                     <strong>{addon?.item_name}</strong>
                     <div className="optionText">
                        {getCurrency(merchantDetails.currency)} {addon?.price}
                     </div>
                  </div>
                  <div
                     style={{ cursor: "pointer" }}
                     onClick={() => onRemove(addon)}>
                     <DeleteIcon sx={{ color: "#D90429" }} />
                  </div>
               </div>
            ))}
         </div>

         <div className="position-absolute w-[100%] flex bottom-10 items-center gap-5 justify-center">
            <Button
               Size="sm"
               title="Cancel"
               border="none"
               icon={<CrossIcon color="white" />}
               background={"#D90429"}
               color={"#fff"}
               onClick={onClose}
            />
            <Button
               Size="sm"
               title="Save"
               border="none"
               icon={<TickIcon />}
               background={"#2FBF71"}
               color={"#fff"}
               onClick={onSave}
            />
         </div>
      </div>
   );
};

export default AddonsTab;
