/** @format */

import { getCountries, getStates } from "country-state-picker";
import getSymbolFromCurrency from "currency-symbol-map";

const structuredArray = (data) => {
   let result = data.map((item, index) => {
      return { key: index, ...item };
   });
   return result;
};

export const getStatesForCountry = (countryName) => {
   const getCountryIndex = getCountries()?.findIndex((country) => country.name.toLowerCase() === countryName.toLowerCase());
   if (getCountryIndex !== -1) {
      const statesResult = getStates(getCountries()[getCountryIndex]?.code);
      let states = statesResult.map((state, index) => {
         return {
            key: index,
            name: state,
         };
      });
      console.log("getStatesForCountry", states);
      return states;
   }
   return [];
};

export const getCountryCode = (countryName) => {
   const getCountryIndex = getCountries()?.findIndex((country) => country.name.includes(countryName));
   return getCountries()[getCountryIndex]?.code;
};

export const CountryData = structuredArray(getCountries());
