/** @format */

import Layout from "layouts";
import "./styles.scss";
import { TabPanel } from "pages/Analytics";
import { Box, Tabs, Tab, Dialog, Select, MenuItem, TextField } from "@mui/material";
import HourlySpecialCard from "components/HourlySpecialCard";
import Button from "components/Button2";
import CategoryItemCard from "components/CategoryItem";
import FoodItemCard from "components/FoodItem/index";
import NoItems from "components/NoItems";
import RecommendedItemAdded from "components/RecommendedItemAdded";
import Search from "components/Search";
import * as MenuAction from "store/action/MenuAction";
import React, { useEffect, useState } from "react";
import TickIcon from "assets/icons/TickIcon";
import { useDispatch, useSelector } from "react-redux";
import * as MenuActions from "store/action/MenuAction";
import Loader from "components/Loader";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ArrowLeftAlt from "assets/icons/ArrowLeftAlt";
import PlusIcon from "assets/icons/PlusIcon";
import { DeleteModal } from "components/DeleteModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import * as SpecialsAction from "store/action/SpecialsAction";
import toast from "react-hot-toast";

const HourlyDailySpecials = () => {
   const [value, setValue] = useState(0);
   const [editHourly, setEditHourly] = useState(false);
   const [editingHourlyData, seteditingHourlyData] = useState({
      start: null,
      end: null,
      items: [],
      isActive: true,
      type: "hourly",
   });
   const [editDaily, setEditDaily] = useState(false);
   const [editingDailyData, seteditingDailyData] = useState({
      dates: "Monday",
      items: [],
      isActive: true,
      type: "daily",
      type_of_day: "day",
   });

   const allItems = useSelector((state) => state.MenuReducer.Menu);
   const [loading, setloading] = useState(false);
   const [searchVal, setSearchVal] = useState([]);
   const [searchResults, setSearchResults] = useState([]);
   const [searchItemResults, setSearchItemResults] = useState([]);
   const [select, setSelect] = useState("");
   const [removedItems, setRemovedItems] = useState([]);
   // const BestSellingItems = useSelector((state) => state?.BestSellingReducer?.bestSellingItems?.best_selling_items);
   const [addedItems, setaddedItems] = useState(editingHourlyData?.items || []);
   const [delModal, setDelModal] = useState({ type: "", open: false, id: "" });
   const hourlyData = useSelector((state) => state?.SpecialsReducer?.hourlyData);
   const dailyData = useSelector((state) => state?.SpecialsReducer?.dailyData);
   console.log(editingHourlyData.end, editingHourlyData.start, "TIMINGCHECK");
   const FETCH_ITEMS = async (id) => {
      await allItems
         ?.filter((item) => item.id === id)
         .map((i) => {
            setSelect(i.category_name);
            localStorage.setItem("categoryRecommendedId", id);
         });
   };

   function removeDuplicates(arr) {
      let unique = [];
      for (const i in arr) {
         if (unique.indexOf(arr[i]) === -1) {
            unique.push(arr[i]);
         }
      }
      return unique;
   }

   const searchMenu = (input) => {
      let temp = [];
      let item = [];
      setSearchVal(input);
      if (input !== "") {
         for (const i in allItems) {
            for (const j in allItems[i].items)
               if (allItems[i].category_name.toLowerCase().includes(input.toLowerCase()) || allItems[i].items[j].item_name.toLowerCase().includes(input.toLowerCase())) {
                  temp.push(allItems[i]);
                  item.push(allItems[i].items[j]);
                  console.log("Searched Item", item);
               }
         }
         if (temp.length > 0 || item.length > 0) {
            setSearchItemResults(removeDuplicates(item));
            setSearchResults(removeDuplicates(temp));
         } else {
            setSearchResults([]);
            setSearchItemResults([]);
         }
      }
   };
   const catId = localStorage.getItem("categoryRecommendedId");
   const FoodItems = allItems.filter((i) => i.id === catId).map((i) => i.items);
   const dispatch = useDispatch();
   const FETCH_ALL_ITEMS = async () => {
      setloading(true);
      await dispatch(MenuActions.GetMenu());
      setloading(false);
   };

   const FETCH_HOURLY_DATA = async () => {
      setloading(true);
      const result = await dispatch(SpecialsAction.GetHourlySpecials());
      setloading(false);
   };
   const FETCH_DAILY_DATA = async () => {
      setloading(true);
      const result = await dispatch(SpecialsAction.GetDailySpecials());
      setloading(false);
   };

   useEffect(() => {
      FETCH_HOURLY_DATA();
      FETCH_DAILY_DATA();
      FETCH_ALL_ITEMS();
   }, []);

   useEffect(() => {
      FETCH_ITEMS(allItems[0]?.id);
   }, [allItems]);

   //AddItemsFunction
   const addOrRemoveItem = (item) => {
      if ((editHourly ? editingHourlyData : editingDailyData)?.items?.reduce((acc, i) => (i.item_id === item.item_id ? true : acc), false)) {
         console.log("yes");
         editHourly
            ? seteditingHourlyData((prev) => ({ ...prev, items: prev.items.filter((i) => i.item_id !== item.item_id) }))
            : seteditingDailyData((prev) => ({ ...prev, items: prev.items.filter((i) => i.item_id !== item.item_id) }));
      } else {
         console.log("no");
         editHourly ? seteditingHourlyData((prev) => ({ ...prev, items: [...prev.items, item] })) : seteditingDailyData((prev) => ({ ...prev, items: [...prev.items, item] }));
      }
   };
   const handleConfirm = async () => {
      setloading(true);
      setEditHourly(false);
      setEditDaily(false);
      if (editHourly) {
         if (editingHourlyData?.hourly_special_id) {
            await dispatch(SpecialsAction.UpdateHourlySpecials(editingHourlyData));
         } else {
            await dispatch(SpecialsAction.CreateHourlySpecials(editingHourlyData));
         }
      } else {
         if (editingDailyData?.daily_special_id) {
            await dispatch(SpecialsAction.UpdateDailySpecials(editingDailyData));
         } else {
            await dispatch(SpecialsAction.CreateDailySpecials(editingDailyData));
         }
      }
      seteditingDailyData({ dates: "Monday", items: [], isActive: true, type: "daily", type_of_day: "day", daily_special_id: undefined });
      seteditingHourlyData({ start: null, end: null, items: [], isActive: true, type: "hourly", hourly_special_id: undefined });
      setloading(false);
      toast.success("Specials updated successfully");
   };

   const getCategoryTitle = (item) => allItems.filter((i) => i.id === item.category_id).map((i) => i.category_name);

   function a11yProps(index) {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   }

   const handleDeleteItem = async (item) => {
      setloading(true);
      if (item.type === "hourly") {
         await dispatch(SpecialsAction.DeleteHourlySpecials({ hourly_special_id: delModal.id }));
         FETCH_HOURLY_DATA();
      } else {
         await dispatch(SpecialsAction.DeleteDailySpecials({ daily_special_id: delModal.id }));
         FETCH_DAILY_DATA();
      }
      setloading(false);
      setDelModal({ type: "", open: false, id: "" });
      toast.success("Specials deleted successfully");
   };

   console.log("Time DIFFF", dayjs(editingHourlyData?.end).diff(editingHourlyData?.start, "hour"));

   function checkIfTheTimeExceeds(startTimeStr, endTimeStr) {
      const totalHours = 4 * 60;
      const startTime = dayjs(editingHourlyData?.start);
      const endTime = dayjs(editingHourlyData?.end);

      // Calculate the difference in minutes
      const diffMinutes = endTime.diff(startTime, "minute");

      // Check if the difference exceeds 240 minutes (4 hours)
      return diffMinutes > totalHours;
   }

   return (
      <Layout>
         <Loader isLoading={loading} />
         {hourlyData?.concat(dailyData)?.map((item, index) => (
            <DeleteModal
               isVisible={
                  delModal.open &&
                  delModal.type === item.type &&
                  ((delModal.id === item.hourly_special_id && delModal.type === "hourly") || (delModal.id === item.daily_special_id && delModal.type === "daily"))
               }
               message={`Are you sure you want to delete this special?`}
               onClose={() => setDelModal({ type: "", open: false, id: "" })}
               handleDelete={() => {
                  handleDeleteItem(item);
               }}
            />
         ))}
         {(editHourly || editDaily) && (
            <>
               <div className="recommended-container-heading">
                  <Button
                     Size={"sm"}
                     background={"#fff"}
                     color={"#2D6B4D"}
                     title={<ArrowLeftAlt />}
                     border={"1px solid #0F191A40"}
                     width="auto"
                     onClick={() => {
                        setEditHourly(false);
                        setEditDaily(false);
                        seteditingHourlyData({ start: "", end: "", items: [], isActive: true, type: "hourly" });
                        seteditingDailyData({ dates: "Monday", type_of_day: "day", items: [], isActive: true, type: "daily" });
                     }}
                  />
                  <div className="recommended-heading-text1">Back to specials list</div>
               </div>
               {editHourly && (
                  <div className="specials-edit-hourly-dets">
                     <div>
                        <div>From time</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <TimePicker
                              required
                              value={editingHourlyData?.start}
                              onChange={(newValue) => {
                                 seteditingHourlyData((prev) => ({ ...prev, start: newValue }));
                              }}
                              ampm={false}
                              sx={{
                                 "& .MuiOutlinedInput-root": {
                                    borderRadius: "0.5rem",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                       borderColor: editingHourlyData.start === null ? "#E03744" : "",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                       borderColor: editingHourlyData.start === null ? "#E03744" : "",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                       borderColor: editingHourlyData.start === null ? "#E03744" : "",
                                    },
                                 },
                                 "& .MuiInputBase-input": {
                                    padding: "0.5rem 0 0.5rem 1rem",
                                 },
                              }}
                           />
                        </LocalizationProvider>
                     </div>
                     <div>
                        <div>
                           To time
                           <span className="specials-edit-hourly-dets-warning">
                              {editingHourlyData?.start && editingHourlyData?.end && dayjs(editingHourlyData?.end).diff(editingHourlyData?.start, "hour") < 1 && "(Minimum 1 hour should be selected)"}
                              {checkIfTheTimeExceeds() && "(Maximum 4 hours only)"}
                           </span>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <TimePicker
                              required
                              value={editingHourlyData?.end}
                              onChange={(newValue) => {
                                 seteditingHourlyData((prev) => ({ ...prev, end: newValue }));
                              }}
                              ampm={false}
                              sx={{
                                 "& .MuiOutlinedInput-root": {
                                    borderRadius: "0.5rem",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                       borderColor: editingHourlyData.end === null ? "#E03744" : "",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                       borderColor: editingHourlyData.end === null ? "#E03744" : "",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                       borderColor: editingHourlyData.end === null ? "#E03744" : "",
                                    },
                                 },
                                 "& .MuiInputBase-input": {
                                    padding: "0.5rem 0 0.5rem 1rem",
                                 },
                              }}
                           />
                        </LocalizationProvider>
                     </div>
                  </div>
               )}
               {editDaily && (
                  <div className="specials-edit-hourly-dets">
                     <div>
                        <div>Choose type of date</div>
                        <div className="d-flex">
                           <div
                              className={"type-of-day" + (editingDailyData.type_of_day === "day" ? " type-of-day-selected" : "")}
                              onClick={() => {
                                 seteditingDailyData({ ...editingDailyData, type_of_day: "day" });
                              }}>
                              Day of a week
                           </div>
                           <div
                              className={"type-of-day" + (editingDailyData.type_of_day === "date" ? " type-of-day-selected" : "")}
                              onClick={() => {
                                 seteditingDailyData({ ...editingDailyData, type_of_day: "date" });
                              }}>
                              Date
                           </div>
                        </div>
                     </div>
                     <div>
                        <div>Choose {editingDailyData.type_of_day}</div>
                        <div>
                           {editingDailyData.type_of_day === "day" && (
                              <Select
                                 value={editingDailyData?.dates}
                                 onChange={(e) => {
                                    seteditingDailyData((prev) => ({ ...prev, dates: e.target.value }));
                                 }}
                                 size="small">
                                 <MenuItem value={"Monday"}>Monday</MenuItem>
                                 <MenuItem value={"Tuesday"}>Tuesday</MenuItem>
                                 <MenuItem value={"Wednesday"}>Wednesday</MenuItem>
                                 <MenuItem value={"Thursday"}>Thursday</MenuItem>
                                 <MenuItem value={"Friday"}>Friday</MenuItem>
                                 <MenuItem value={"Saturday"}>Saturday</MenuItem>
                                 <MenuItem value={"Sunday"}>Sunday</MenuItem>
                              </Select>
                           )}
                           {editingDailyData.type_of_day === "date" && (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                 <DatePicker
                                    value={editingDailyData?.dates}
                                    onChange={(newValue) => {
                                       seteditingDailyData((prev) => ({ ...prev, dates: newValue }));
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    sx={{
                                       "& .MuiInputBase-root": {
                                          borderRadius: "0.5rem",
                                       },
                                       "& .MuiInputBase-input": {
                                          padding: "0.5rem 0 0.5rem 1rem",
                                       },
                                    }}
                                 />
                              </LocalizationProvider>
                           )}
                        </div>
                     </div>
                  </div>
               )}
            </>
         )}
         {(editHourly || editDaily) && (
            <div className="recommended-container">
               <div className="recommended-content-container">
                  <div className="recommended-categories-added-items-search-wrapper">
                     <Search
                        onChange={(e) => {
                           searchMenu(e.target.value);
                        }}
                        border={"1px solid rgba(0,0,0,0.3)"}
                        InputHeight={"3rem"}
                        padding={"0.8rem"}
                        width={"100%"}
                        placeholder={"Search in menu"}
                     />

                     <div className="recommended-categoies-added-items-main-container">
                        <div className="recommended-categories-container">
                           <div className="recommended-category-items-wrapper">
                              {searchVal != ""
                                 ? searchResults?.map((item, index) => (
                                      <CategoryItemCard
                                         item={item}
                                         select={select}
                                         name={item.category_name}
                                         filter={FETCH_ITEMS}
                                         key={index}
                                      />
                                   ))
                                 : allItems?.map((item, index) => (
                                      <CategoryItemCard
                                         item={item}
                                         select={select}
                                         name={item.category_name}
                                         filter={FETCH_ITEMS}
                                         key={index}
                                      />
                                   ))}
                           </div>
                        </div>
                        {
                           <div className="recommended-items-container">
                              <div className="recommended-add-items-wrapper">
                                 {searchVal != ""
                                    ? searchItemResults?.map((item, index) => (
                                         <FoodItemCard
                                            item={item}
                                            key={index}
                                            isAdded={(editHourly ? editingHourlyData : editingDailyData)?.items?.reduce((acc, i) => (i.item_id === item.item_id ? true : acc), false)}
                                            addToRecommended={() => {
                                               addOrRemoveItem(item);
                                            }}
                                            disabled={(editHourly && editingHourlyData?.items?.length === 8) || (editDaily && editingDailyData?.items?.length === 8) ? true : false}
                                         />
                                      ))
                                    : FoodItems[0]?.map((item, index) => (
                                         <FoodItemCard
                                            item={item}
                                            key={index}
                                            isAdded={(editHourly ? editingHourlyData : editingDailyData)?.items?.reduce((acc, i) => (i.item_id === item.item_id ? true : acc), false)}
                                            disabled={(editHourly && editingHourlyData?.items?.length === 8) || (editDaily && editingDailyData?.items?.length === 8) ? true : false}
                                            addToRecommended={() => {
                                               addOrRemoveItem(item);
                                            }}
                                         />
                                      ))}
                              </div>
                           </div>
                        }
                     </div>
                  </div>
                  <div className="recommended-added-items-container">
                     {((editHourly && editingHourlyData?.items?.length === 8) || (editDaily && editingDailyData?.items?.length === 8)) && (
                        <span className="recommended-items-warning">You can add only upto 8 items</span>
                     )}

                     <div className="recommended-added-items-container-header">
                        <span className="recommended-added-items-heading-text1">Items</span>
                        <span className="recommended-added-items-container-text1">
                           {editHourly ? editingHourlyData?.items?.length : editingDailyData?.items?.length}
                           /8 Items
                        </span>
                     </div>
                     <div
                        className={
                           (editHourly && editingHourlyData?.items?.length === 0) || (editDaily && editingDailyData?.items?.length === 0)
                              ? "recommended-added-items-added-items-container NoItemsWrapper"
                              : "recommended-added-items-added-items-container"
                        }>
                        {(editHourly && editingHourlyData?.items?.length > 0) || (editDaily && editingDailyData?.items?.length > 0) > 0 ? (
                           (editHourly ? editingHourlyData : editingDailyData)?.items?.map((item, index) => {
                              return (
                                 <div
                                    style={{
                                       width: "100%",
                                    }}
                                    className="recommended-item-added-container">
                                    <RecommendedItemAdded
                                       item={item}
                                       key={index}
                                       categoryTitle={getCategoryTitle(item)}
                                       handleRemove={() => {
                                          addOrRemoveItem(item);
                                       }}
                                    />
                                 </div>
                              );
                           })
                        ) : (
                           <NoItems />
                        )}
                     </div>

                     <div className="recommended-added-items-bottom-container">
                        <Button
                           Size={"sm"}
                           background={"#2FBF71"}
                           color={"#fff"}
                           title={"Confirm"}
                           icon={<TickIcon />}
                           border={"none"}
                           disabled={
                              (editHourly && editingHourlyData?.items?.length === 0) ||
                              (editDaily && editingDailyData?.items?.length === 0) ||
                              (editHourly && editingHourlyData?.items?.length > 8) ||
                              (editDaily && editingDailyData?.items?.length > 8) ||
                              (editHourly && !editingHourlyData?.start) ||
                              (editHourly && !editingHourlyData?.end) ||
                              (editHourly && dayjs(editingHourlyData?.end).diff(editingHourlyData?.start, "hour") < 1) ||
                              (editHourly && dayjs(editingHourlyData?.end).diff(editingHourlyData?.start, "hour") > 4) ||
                              (editDaily && !editingDailyData?.dates)
                                 ? true
                                 : false
                           }
                           onClick={handleConfirm}
                        />
                     </div>
                  </div>
               </div>
            </div>
         )}
         {!editHourly && !editDaily && (
            <div className="mainContainer">
               <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                     value={value}
                     onChange={(e, newVal) => setValue(newVal)}
                     TabIndicatorProps={{
                        style: {
                           background: "#2D6B4D",
                        },
                     }}
                     aria-label="basic tabs example">
                     <Tab
                        className=""
                        {...a11yProps(0)}
                        label="Hourly Specials"
                        classes={{ root: "tab-text", selected: "tab-text-selected" }}
                     />
                     <Tab
                        className=""
                        {...a11yProps(1)}
                        label="Daily Specials"
                        classes={{ root: "tab-text", selected: "tab-text-selected" }}
                     />
                  </Tabs>
               </Box>
               <div className="d-flex mt-4 align-items-center">
                  <div className="special-header">
                     <TabPanel
                        value={value}
                        index={0}>
                        Hourly Specials
                     </TabPanel>
                     <TabPanel
                        value={value}
                        index={1}>
                        Daily Specials
                     </TabPanel>
                  </div>
                  <div className="special-count">
                     <TabPanel
                        value={value}
                        index={0}>
                        {hourlyData?.length ?? 0}/8 items
                     </TabPanel>
                     <TabPanel
                        value={value}
                        index={1}>
                        {dailyData?.length ?? 0}/8 items
                     </TabPanel>
                  </div>
                  <Button
                     Size={"sm"}
                     margin="0 0 0 auto"
                     width="auto"
                     background={"#4361EE1A"}
                     color={"#4361EE"}
                     title={"Create Specials"}
                     icon={<PlusIcon color={"#4361EE"} />}
                     border={"none"}
                     disabled={value === 0 ? hourlyData?.length >= 8 : dailyData?.length >= 8}
                     onClick={() => {
                        if (value === 0) {
                           setEditHourly(true);
                           setaddedItems(editingHourlyData?.items || []);
                        } else {
                           setEditDaily(true);
                           setaddedItems(editingDailyData?.items || []);
                        }
                     }}
                  />
               </div>
               <TabPanel
                  value={value}
                  index={0}
                  className="specials-list">
                  {hourlyData?.map((item, index) => (
                     <HourlySpecialCard
                        item={item}
                        onEdit={() => {
                           setEditHourly(true);
                           seteditingHourlyData({
                              ...item,
                              start: dayjs(item.start),
                              end: dayjs(item.end),
                           });
                        }}
                        onDelete={() => {
                           setDelModal({ type: item.type, open: true, id: item.hourly_special_id });
                        }}
                        onActivate={async () => {
                           setloading(true);
                           await dispatch(SpecialsAction.ActivateHourlySpecials({ hourly_special_id: item.hourly_special_id }));
                           setloading(false);
                        }}
                        onDeactivate={async () => {
                           setloading(true);
                           await dispatch(SpecialsAction.DeactivateHourlySpecials({ hourly_special_id: item.hourly_special_id }));
                           setloading(false);
                        }}
                     />
                  ))}
                  {hourlyData?.length === 0 && <NoItems />}
               </TabPanel>
               <TabPanel
                  value={value}
                  index={1}
                  className="specials-list">
                  {dailyData?.map((item, index) => (
                     <HourlySpecialCard
                        item={item}
                        onEdit={() => {
                           setEditDaily(true);
                           seteditingDailyData({
                              ...item,
                              dates: item.type_of_day === "day" ? item.dates : dayjs(item.dates),
                           });
                        }}
                        onDelete={() => {
                           setDelModal({ type: item.type, open: true, id: item.daily_special_id });
                        }}
                        onActivate={async () => {
                           setloading(true);
                           await dispatch(SpecialsAction.ActivateDailySpecials({ daily_special_id: item.daily_special_id }));
                           setloading(false);
                        }}
                        onDeactivate={async () => {
                           setloading(true);
                           await dispatch(SpecialsAction.DeactivateDailySpecials({ daily_special_id: item.daily_special_id }));
                           setloading(false);
                        }}
                     />
                  ))}
                  {dailyData?.length === 0 && <NoItems />}
               </TabPanel>
            </div>
         )}
      </Layout>
   );
};

export default HourlyDailySpecials;
