import { createAsyncThunk } from "@reduxjs/toolkit";

const CREDITS_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/credit`;

//Get All Combos

export const getAllCredits = createAsyncThunk(
  `getAllCredits`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await fetch( `${CREDITS_ENDPOINT}/current_credits/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem( "savedT" )}`,
          "ngrok-skip-browser-warning": "any",
        },
      } );

      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.json();
        console.log( "RESPONSE OF ALL CREDITS ===>", resData );
        return resData;
      } else
      {
        return rejectWithValue( "Can't fetch CREDITS" );
      }
    } catch ( error )
    {
      throw error;
    }
  },
);

export const getAllCreditsPlan = createAsyncThunk(
  `getAllCredits`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await fetch( `${CREDITS_ENDPOINT}/get_credit_values/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem( "savedT" )}`,
          "ngrok-skip-browser-warning": "any",
        },
      } );

      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.json();
        console.log( "RESPONSE OF ALL CREDITS ===>", resData );
        return resData;
      } else
      {
        return rejectWithValue( "Can't fetch CREDITS" );
      }
    } catch ( error )
    {
      throw error;
    }
  },
);


export const CreateCredit = createAsyncThunk( `CreateCredit`, async data => {
  try
  {
    const response = await fetch( `${CREDITS_ENDPOINT}/create_credit_purchase/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem( "savedT" )}`,
        "ngrok-skip-browser-warning": "any",
      },
      body: JSON.stringify( data ),
    } );
    if ( response.status === 200 || response.status === 201 )
    {
      const resData = await response.json();
      console.log( "SUCCESSFULLY CREATED CREDIT DETAILS", resData );
      return resData;
    } else
    {
      return "Error";
    }
  } catch ( error )
  {
    throw error;
  }
} );

export const AttemptCredit = createAsyncThunk(
  `AttemptCredit`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await fetch( `${CREDITS_ENDPOINT}/attempt_credit_purchase/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem( "savedT" )}`,
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify( data ),
      } );
      if ( response.status === 200 || response.status === 201 )
      {
        const resData = await response.json();
        console.log( "SUCCESSFULLY ATTEMPT CREDIT DETAILS", resData );
        return resData;
      } else
      {
        return false;
      }
    } catch ( error )
    {
      throw error;
    }
  },
);
