/** @format */

import Button from "components/Button2";
import CrossIcon from "assets/icons/CrossIcon";
import TickIcon from "assets/icons/TickIcon";
import * as yup from "yup";
import TypeChip from "components/TypeChip";
import VegIcon from "assets/icons/VegIcon";
import NonVegIcon from "assets/icons/NonVegIcon";
import Input from "components/FormikInput";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CreateCustomizations, EditCustomization } from "store/action/AddonsAction";
import CreateableSelectDropdown from "components/CreateableSelectDropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import { UpdateModal } from "components/UpdateModal";
import toast from "react-hot-toast";

const { Formik, Form, FieldArray } = require("formik");

const CustomizationTab = ({ customizations, defaultAdd, editIndex, onClose, onAddNew, onRemove, onSave }) => {
   const allCustomizations = useSelector((state) => state.AddonsReducer.customizations);
   const [updatingIndex, setUpdatingIndex] = useState(null);
   const [showUpdateModal, setshowUpdateModal] = useState(false);
   const formRef = useRef();
   const dispatch = useDispatch();

   const [initialCustomizationValues, setinitialCustomizationValues] = useState({
      selection_type: "",
      customisation_title: "",
      options: ["", ""],
   });

   const customizationValidationSchema = yup.object({
      selection_type: yup.string().required("Field is required").nullable(),
      customisation_title: yup.string().required("Field is required").nullable(),
      options: yup.array().of(yup.string().required("Field is required").nullable()),
   });

   useEffect(() => {
      defaultAdd && setUpdatingIndex("new");
      editIndex !== null && setUpdatingIndex(editIndex);
   }, [defaultAdd, editIndex]);

   const createCustomization = async (data) => {
      const result = await dispatch(CreateCustomizations(data));
      onAddNew(result?.payload);
   };

   const onSubmit = (values) => {
      if (updatingIndex === "new") {
         createCustomization(values);
         defaultAdd ? onClose() : setUpdatingIndex(null);
         toast.success("Customization created successfully");
      } else {
         setshowUpdateModal(true);
      }
   };

   const updateCustomization = () => {
      dispatch(EditCustomization(formRef?.current?.values));
      onClose();
      toast.success("Customization updated successfully");
   };

   const getSearchData = () => {
      let data = [];
      allCustomizations.map((item) => {
         const checkAlreadyAdded = customizations?.findIndex((customisation) => customisation?.customisation_id === item?.customisation_id);
         if (checkAlreadyAdded === -1)
            data.push({
               id: item.customisation_id,
               value: item.customisation_title,
               label: item.customisation_title,
            });
      });
      return data;
   };

   const onCreateNewValue = (obj) => {
      setinitialCustomizationValues({
         ...initialCustomizationValues,
         customisation_title: obj.value,
      });
      setUpdatingIndex("new");
   };

   const onAddCustomization = (val) => {
      const findElement = allCustomizations.filter((item) => item.customisation_id === val.id);
      onAddNew(findElement[0]);
      toast.success("Customization created successfully");
   };

   return updatingIndex !== null ? (
      <div className="add-on-form-container">
         <UpdateModal
            message={"If you modify this customization, it will be updated in all items where it is included"}
            isVisible={showUpdateModal}
            handleUpdate={updateCustomization}
            handleCancel={() => setshowUpdateModal(false)}
         />
         <h3 style={{ marginTop: "1rem" }}>{editIndex !== null ? "Edit customization" : "Add customization"}</h3>
         <Formik
            innerRef={formRef}
            initialValues={updatingIndex === "new" ? initialCustomizationValues : allCustomizations[updatingIndex]}
            validationSchema={customizationValidationSchema}
            onSubmit={onSubmit}>
            {({ isValid, values, setFieldValue }) => {
               return (
                  <Form className="AddItemModalCustomizationForm">
                     <div className="itemTypeWrapper">
                        <label
                           htmlFor=""
                           className="labelText">
                           Selection type <span className="requiredtag">*</span>
                        </label>
                        <div className="formGroup">
                           <TypeChip
                              title={"Multiselect"}
                              ActiveStyle={values.selection_type === "checkbox" && "VegActive"}
                              icon={<VegIcon />}
                              onclick={() => {
                                 setFieldValue("selection_type", "checkbox");
                              }}
                           />
                           <TypeChip
                              ActiveStyle={values.selection_type === "radio" && "VegActive"}
                              title={"Single select"}
                              icon={<NonVegIcon />}
                              onclick={() => {
                                 setFieldValue("selection_type", "radio");
                              }}
                           />
                        </div>{" "}
                     </div>
                     <Input
                        required
                        placeholder={"(Eg: Sugar level)"}
                        width={"100%"}
                        label={"Title of customization"}
                        name="customisation_title"
                     />

                     <FieldArray name="options">
                        {({ insert, remove, push }) => (
                           <div>
                              <div className="d-flex justify-content-between">
                                 <div
                                    htmlFor=""
                                    className="labelText">
                                    Options <span className="requiredtag">*</span>
                                 </div>
                                 <div
                                    onClick={() => push("")}
                                    style={{ color: "#4361EE", textAlign: "right", fontSize: "0.9rem", width: "50%", cursor: "pointer" }}>
                                    + Add more option
                                 </div>
                              </div>
                              {values.options?.map((option, index) => (
                                 <div style={{ display: "flex" }}>
                                    <div style={{ width: "90%" }}>
                                       <Input
                                          required
                                          placeholder={"Add Option"}
                                          width={"100%"}
                                          noLabel
                                          name={`options[${index}]`}
                                       />
                                    </div>
                                    <div
                                       style={{ width: "10%", display: "inline-flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                                       onClick={() => remove(index)}>
                                       <CrossIcon color="rgb(217, 4, 41)" />
                                    </div>
                                 </div>
                              ))}
                           </div>
                        )}
                     </FieldArray>
                     <div className="AddItemBtnWrapper">
                        <Button
                           Size="sm"
                           title="Cancel"
                           border="none"
                           icon={<CrossIcon color="white" />}
                           background={"#D90429"}
                           color={"#fff"}
                           onClick={() => {
                              defaultAdd ? onClose() : setUpdatingIndex(null);
                           }}
                        />
                        <Button
                           Size="sm"
                           title="Save"
                           border="none"
                           icon={<TickIcon />}
                           background={"#2FBF71"}
                           color={"#fff"}
                           type="submit"
                        />
                     </div>
                  </Form>
               );
            }}
         </Formik>
      </div>
   ) : (
      <div className="position-relative h-full">
         <div className="add-on-search-container">
            <CreateableSelectDropdown
               isClearable
               options={getSearchData()}
               onChange={(val) => onAddCustomization(val)}
               placeholder={"Search customization"}
               onCreate={(val) => onCreateNewValue(val)}
            />
         </div>
         <div className="my-3">
            {customizations?.map((customization, index) => (
               <div
                  className="CustomizationCard"
                  key={index}>
                  <div>
                     <strong>
                        {customization?.customisation_title}{" "}
                        <span>
                           <div className="optionText">{customization?.options?.length} options</div>
                        </span>
                     </strong>
                     {customization?.options.map((item) => {
                        return <div className="optionText">{item} </div>;
                     })}
                  </div>
                  <div
                     style={{ cursor: "pointer" }}
                     onClick={() => onRemove(customization)}>
                     <DeleteIcon sx={{ color: "#D90429" }} />
                  </div>
               </div>
            ))}
         </div>
         <div className="position-absolute w-[100%] flex bottom-10 items-center gap-5 justify-center">
            <Button
               Size="sm"
               title="Cancel"
               border="none"
               icon={<CrossIcon color="white" />}
               background={"#D90429"}
               color={"#fff"}
               onClick={onClose}
            />
            <Button
               Size="sm"
               title="Save"
               border="none"
               icon={<TickIcon />}
               background={"#2FBF71"}
               color={"#fff"}
               onClick={onSave}
            />
         </div>
      </div>
   );
};

export default CustomizationTab;
