/** @format */

import ToggleSwitch from "components/ToggleSwitch";
import React, { useState } from "react";
import "./index.scss";
import Button from "components/Button2";
import Arrow from "assets/icons/Arrow";
import { EditCategory, DeleteCategory, GetMenu } from "store/action/MenuAction";
import { useDispatch } from "react-redux";
import { DeleteModal } from "components/DeleteModal";
import { DeleteAddon, DeleteCustomization } from "store/action/AddonsAction";
import { Tooltip } from "@mui/material";
import Loader from "components/Loader";
import DraggableIndicator from "components/DragableIndicator";
import toast from "react-hot-toast";
import { CATEGORY_NAME_CHARACTER_LIMIT } from "utils/definitions";

function MenuCard({ name, id, filter, handleClose, tab, select, category_idx, searchResults, setSearch, viewAddItems, initialRender, dragStart, index, dragEnter, drop }) {
   const [edit, setedit] = useState(false);
   const [isVisible, setVisible] = useState(false);
   const [loading, setloading] = useState(false);
   const [input, setinput] = useState(name);
   const dispatch = useDispatch();

   console.log("categoryidxXXXXX", category_idx);

   const UpdateCategory = async () => {
      if (name !== input) {
         if (input !== "") {
            const data = { id: id, body: { category_name: input, category_idx: category_idx } };
            const result = await dispatch(EditCategory(data));
            toast.success("Category updated successfully");
         } else {
            toast.error("Category name should not be empty");
         }
      }
      setedit(false);
   };

   const onPressEnter = (e) => {
      if (e.key === "Enter") {
         UpdateCategory();
      }
   };

   const Delete = async (e) => {
      e.stopPropagation();
      handleClose(false);
      setVisible(false);
      let tabName = "";
      switch (tab) {
         case 0:
            tabName = "Category";
            await dispatch(DeleteCategory({ id: id }));
            break;
         case 1:
            tabName = "Addon";
            await dispatch(DeleteAddon({ id: id }));
            break;
         case 2:
            tabName = "Customization";
            await dispatch(DeleteCustomization({ id: id }));
            break;

         default:
            break;
      }
      await initialRender();
      toast.success(`${tabName} deleted successfully`);
   };

   const getMenuCardData = () => {
      switch (tab) {
         case 0:
            return {
               deleteMessage: (
                  <>
                     Deleting a category will delete all the items in it.
                     <br />
                     Are you sure you want to delete?
                  </>
               ),
            };

         case 1:
            return {
               deleteMessage: "Are you sure you want to delete this add on?",
            };
         case 2:
            return {
               deleteMessage: "Are you sure you want to delete this customization?",
            };

         default:
            return {
               deleteMessage: "Are you sure you want to proceed?", // Provide a default message
               // Provide a no-operation function or a default delete handler
            };
      }
   };

   return (
      <div
         className={select === name ? "MenuCardContainer MenuCardActive relative" : "MenuCardContainer relative"}
         onClick={() => {
            filter(id);
         }}>
         {tab === 0 && <DraggableIndicator />}

         <div className="MenuCard">
            <Loader isLoading={loading} />
            <DeleteModal
               message={getMenuCardData().deleteMessage}
               isVisible={isVisible}
               onClose={setVisible}
               handleDelete={Delete}
            />
            {!edit ? (
               <Tooltip title={name}>
                  <div
                     style={{ cursor: "text" }}
                     onDoubleClickCapture={() => setedit(true)}>
                     {name.length > 18 ? name.slice(0, 14) + "..." : name}
                  </div>
               </Tooltip>
            ) : (
               <div className="h-full">
                  <input
                     autoFocus={true}
                     disabled={!edit}
                     value={input}
                     onChange={(e) => {
                        const { value } = e.target;
                        if (value.length < CATEGORY_NAME_CHARACTER_LIMIT + 1) {
                           setinput(e.target.value);
                        }
                     }}
                     className="category-input-container"
                     onBlur={UpdateCategory}
                     onKeyDown={onPressEnter}
                  />
                  <div className="text-gray-400 ml-4 text-[0.8rem] absolute bottom-0">Characters remaining {CATEGORY_NAME_CHARACTER_LIMIT - input.length}</div>
               </div>
            )}

            <div className="MenuCardSubContainer">
               <div className="MenuCardBtnWrapper">
                  <Button
                     Size={"sm"}
                     title={"Delete"}
                     color={"#D90429"}
                     border={"none"}
                     background={"transparent"}
                     onClick={(e) => {
                        e.stopPropagation();
                        setVisible((p) => !p);
                     }}
                  />
               </div>
            </div>
         </div>
      </div>
   );
}

export default MenuCard;
