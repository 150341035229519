/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";

const api = process.env.REACT_APP_DEV_URL;

export const deleteOrder = createAsyncThunk(`deleteOrder`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${api}/merchant/order/${data}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
         },
      });
      console.log(response);
      if (response.status == 201 || response.status == 200 || response.status == 204) {
         return { order_id: data };
      } else {
         return rejectWithValue("Can't delete the category");
      }
   } catch (error) {
      throw error;
   }
});

export const GetAllOrders = createAsyncThunk(`GetAllOrders`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${api}/merchant/order/all`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "true",
         },
      });
      console.log("Get all Orders response ", response);
      const resData = await response.json();
      console.log(resData);
      if (response.status == 201 || response.status == 200) {
         return resData;
      } else {
         return rejectWithValue("Can't  fetch the menu");
      }
   } catch (error) {
      throw error;
   }
});
export const GetSpecificOrder = createAsyncThunk(`GetSpecificOrder`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${api}/merchant/order/${data}`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      console.log("Get Specific response ", response);
      const resData = await response.json();
      console.log(resData);
      if (response.status == 201 || response.status == 200) {
         return resData;
      } else {
         return rejectWithValue("Can't  fetch the menu");
      }
   } catch (error) {
      throw error;
   }
});

export const UpdateOrder = createAsyncThunk(`UpdateOrder`, async (data, { rejectWithValue }) => {
   let unavailableItems = [];

   const items_ordered = data.payload.items_ordered;
   for (const key in items_ordered) {
      if (items_ordered[key].out_of_stock) {
         unavailableItems.push(items_ordered[key].item_id);
      }
   }
   let payloadData = { ...data.payload, unavailable_items: unavailableItems };
   try {
      const response = await fetch(`${api}/merchant/order/${data.id}`, {
         method: "PUT",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(payloadData),
      });
      console.log(response);
      let resData = await response.json();
      console.log(resData);
      if (response.status == 201 || response.status == 200) {
         resData = { ...resData, order_id: data.id };
         return resData;
      } else {
         return rejectWithValue(resData);
      }
   } catch (error) {
      throw error;
   }
});

export const ExportOrder = createAsyncThunk(`ExportOrder`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${api}/merchant/order/export_orders`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "true",
         },
      });

      let resData = await response.json();
      console.log(resData);
      if (response.status === 201 || response.status === 200) {
         console.log("EXPORTED ORDER....", resData);
         return resData;
      } else {
         return rejectWithValue("Can't  update the category");
      }
   } catch (error) {
      throw error;
   }
});

export const UpdateGuest = createAsyncThunk(`UpdateGuest`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${api}/merchant/order/add_no_of_guests/${data.order_id}`, {
         method: "PUT",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
         },
         body: JSON.stringify(data.payload),
      });
      console.log(response);
      let resData = await response.json();
      console.log(resData);
      if (response.status == 201 || response.status == 200) {
         resData = { ...resData, order_id: data.id };
         return resData;
      } else {
         return rejectWithValue("Can't Update the guest");
      }
   } catch (error) {
      throw error;
   }
});

export const GenerateBill = async (orderId) => {
   try {
      const response = await fetch(`${api}/merchant/order/get_overall_bill/${orderId}`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "true",
         },
      });
      const resData = await response.json();
      if (response?.status === 200 || response?.status === 201) {
         return resData;
      }
      return response;
   } catch (error) {
      throw error;
   }
};
