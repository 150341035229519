/** @format */

import Arrow from "assets/icons/Arrow";
import React, { useState } from "react";
import BlackArrowSvg from "../../assets/icons/BlackArrowSvg";
import "./style.scss";
import { Toolbar, Tooltip } from "@mui/material";

function CategoryItemCard({ item, name, select, filter }) {
   return (
      <div
         className={select === name ? "category-item-container category-active" : "category-item-container"}
         onClick={() => {
            filter(item?.id);
         }}>
         <Tooltip title={item.category_name}>
            <div className="category-item-name">{item?.category_name.length > 14 ? item?.category_name.slice(0, 10) + "..." : item?.category_name}</div>
         </Tooltip>
         <div>
            <BlackArrowSvg />
         </div>
      </div>
   );
}

export default CategoryItemCard;
