import React from 'react';

function NonVegIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='2.75'
        y='2.74998'
        width='18.5'
        height='18.5'
        rx='3.25'
        fill='white'
        stroke='#D90429'
        stroke-width='1.5'
      />
      <path
        d='M11.5439 6.275C11.7466 5.90833 12.2534 5.90833 12.4561 6.275L17.9287 16.175C18.1314 16.5417 17.878 17 17.4726 17H6.52739C6.12201 17 5.86865 16.5417 6.07134 16.175L11.5439 6.275Z'
        fill='#D90429'
      />
    </svg>
  );
}

export default NonVegIcon;
