/** @format */

import React, { useRef, useEffect, useState } from "react";
import "./index.scss";
import { useReactToPrint } from "react-to-print";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { GetMerchant } from "store/action/MerchantAction";
import { Container, Row, Col } from "react-bootstrap";
import { getCurrency } from "utils/functions";

export default function PrintOrder({ data, onClose, visible, printKOT }) {
   console.log("KOT", printKOT);
   const dispatch = useDispatch();
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);

   const [isPrinting, setIsPrinting] = useState(false);
   const printRef = useRef(null);

   const promiseResolveRef = useRef(null);

   // We watch for the state to change here, and for the Promise resolve to be available
   useEffect(() => {
      if (isPrinting && promiseResolveRef.current) {
         // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
         promiseResolveRef.current();
      }
   }, [isPrinting]);
   const handlePrint = useReactToPrint({
      content: () => printRef.current,
      onBeforeGetContent: () => {
         return new Promise((resolve) => {
            promiseResolveRef.current = resolve;
            setIsPrinting(true);
            onClose();
         });
      },
      onAfterPrint: () => {
         // Reset the Promise resolve so we can print again
         promiseResolveRef.current = null;
         setIsPrinting(false);
         onClose();
      },
   });

   useEffect(() => {
      // fetchMerchant();
      visible && handlePrint();
   }, [visible]);

   console.log("printORder", data, "printKOT", printKOT);

   return (
      <div
         style={{ display: "none" }}
         className="print-order-overlay">
         <body>
            <div className="print-order-container">
               <div
                  ref={printRef}
                  className="ticket">
                  <Container>
                     <Row>
                        <Col>
                           <h5> {data?.restaurant_name}</h5>
                           <p>Order ID : {data?.order_id}</p>
                           <p> {data?.order_type}</p>
                           {data?.order_type === "Dine-in" && <p> Table : {data?.table_number}</p>}
                           <div style={{ marginTop: 10, marginBottom: 10 }}>
                              <p>Date : {data?.order_date}</p>
                              <p>Time : {data?.order_time}</p>
                           </div>
                        </Col>
                     </Row>
                     <Row>
                        <Col md={12}>
                           <table>
                              <thead>
                                 <tr>
                                    <th>Item</th>
                                    <th>Qty</th>
                                    {!printKOT && <th>Price</th>}
                                    {!printKOT && <th>total</th>}
                                 </tr>
                              </thead>
                              <tbody>
                                 {data?.items_ordered?.map((item, index) => (
                                    <>
                                       <tr key={index}>
                                          <td className="print-item-name">{item?.item_name}</td>
                                          {printKOT && <td></td>}
                                          <td className="print-item-count">{item?.count}</td>
                                          {!printKOT && <td>{item?.price}</td>}
                                          {!printKOT && <td>{item.count * item?.price}</td>}
                                       </tr>
                                       <>
                                          {item?.addons?.map((addon, index) => (
                                             <tr key={index}>
                                                <td className="print-item-name">{addon?.item_name}</td>
                                                {printKOT && <td></td>}
                                                <td className="print-item-count">{addon?.count}</td>
                                                {!printKOT && <td>{addon?.price}</td>}
                                                {!printKOT && <td>{addon.count * addon?.price}</td>}
                                             </tr>
                                          ))}
                                       </>
                                       <>
                                          {item?.customizations?.map((customization, index) => (
                                             <tr key={index}>
                                                <td className="print-item-name">{customization?.customisation_title}</td>
                                             </tr>
                                          ))}
                                       </>
                                    </>
                                 ))}
                              </tbody>
                              <tfoot>
                                 <tr>
                                    <td></td>
                                    <td></td>
                                 </tr>
                                 <tr>
                                    <td></td>
                                    <td></td>
                                 </tr>
                                 <tr>
                                    <td></td>
                                    <td></td>
                                 </tr>
                                 {!printKOT && (
                                    <tr>
                                       <td>Subtotal:</td>
                                       <td></td>
                                       <td>
                                          {getCurrency(merchantDetails?.currency)} {data?.total_amount}
                                       </td>
                                       <td></td>
                                    </tr>
                                 )}
                                 {!printKOT && (
                                    <tr>
                                       <td>CGST:</td>
                                       <td></td>
                                       <td>{data?.cgst_percentage}%</td>
                                       <td></td>
                                    </tr>
                                 )}
                                 {!printKOT && (
                                    <tr>
                                       <td>SGST:</td>
                                       <td></td>
                                       <td>{data?.sgst_percentage}%</td>
                                       <td></td>
                                    </tr>
                                 )}

                                 {!printKOT && (
                                    <tr>
                                       <td>Total:</td>
                                       <td></td>
                                       <td>
                                          {getCurrency(merchantDetails?.currency)} {data?.amount_after_taxes}
                                       </td>
                                       <td></td>
                                    </tr>
                                 )}
                              </tfoot>
                           </table>
                        </Col>
                     </Row>
                  </Container>

                  {/* <p className="centered"> { data?.restaurant_name }</p>
                        <p className="centered">Order ID : { data?.order_id }</p>
                        <p className="centered"> { data?.order_type }</p>
                        { data?.order_type === "Dine-in" && <p className="centered"> Table : { data?.table_number }</p> }
                        { RenderCustomerDetails() && <div style={ { marginTop: 10, marginBottom: 10 } }>
                            <p className="left">Name :  { data?.customer_name }</p>
                            <p className="left">Mobile :  { data?.phone_number }</p>
                            <p className="left">Date :  { data?.order_date }</p>
                            <p className="left">Time :  { data?.order_time }</p>
                        </div> }

                        <table className='table-content'>
                            <thead>
                                <tr>
                                    <th className="columnText">ITEM</th>
                                    { printKOT && <td className='quantity'></td> }
                                    <th className="columnText">QTY</th>
                                    { !printKOT && <th className="columnText">TOTAL</th> }
                                </tr>
                            </thead>
                            <tbody>
                                { data?.items_ordered?.map( ( item, index ) => (
                                    <tr key={ index }>
                                        <td className="description">{ item?.item_name }</td>
                                        { printKOT && <td className="quantity"></td> }
                                        <td className="quantity">{ item?.count }</td>
                                        { !printKOT && <td className="price">{ item?.price }</td> }
                                    </tr>
                                ) ) }

                            </tbody>
                            { !printKOT &&
                                <>
                                    <tr >
                                        <td className="description">Sub total</td>
                                        <td className="quantity"></td>
                                        <td className="price">{ data?.total_amount }</td>
                                    </tr>
                                    <tr >
                                        <td className="description">Cgst</td>
                                        <td className="quantity"></td>
                                        <td className="price">{ data?.cgst_percentage }%</td>
                                    </tr>
                                    <tr >
                                        <td className="description">Sgst</td>
                                        <td className="quantity"></td>
                                        <td className="price">{ data?.sgst_percentage }%</td>
                                    </tr>
                                    <tr >
                                        <td className="description">Total</td>
                                        <td className="quantity"></td>
                                        <td className="price">{getCurrency(merchantDetails?.currency)} { data?.amount_after_taxes }</td>
                                    </tr>
                                </>
                            }
                        </table> */}
                  {!printKOT && <p className="centered">Thanks for your order!</p>}
                  <p className="centered">powered by www.thebeautifulmenu.com</p>
               </div>
               <div className="button-container">
                  <button
                     onClick={onClose}
                     className="cancel-btn">
                     Cancel
                  </button>
                  <button
                     onClick={handlePrint}
                     className="print-btn">
                     Print
                  </button>
               </div>
            </div>
         </body>
      </div>
   );
}
