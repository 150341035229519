/** @format */

import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { setWaiterMode } from "store/reducer/WaiterReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Alert from "components/Alert";

const validationSchema = yup.object().shape({
   username: yup.string().required("Username is required"),
   password: yup.string().required("Password is required"),
});

const theme = createTheme({
   components: {
      MuiTextField: {
         styleOverrides: {
            root: {
               "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                     borderColor: "#393D3D",
                     borderRadius: "10px",
                  },
                  "&:hover fieldset": {
                     borderColor: "#393D3D",
                  },
                  "&.Mui-focused fieldset": {
                     borderColor: "#000000",
                  },
               },
            },
         },
      },
   },
});

const WaiterLogin = () => {
   const [showPassword, setShowPassword] = useState(false);
   const WAITER_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/waiter/signin`;
   const dispatch = useDispatch();
   const togglePasswordVisibility = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
   };
   const navigate = useNavigate();
   const [open, setOpen] = useState(false);

   const handleLogin = async (data) => {
      try {
         const response = await fetch(WAITER_API_ENDPOINT, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${localStorage.getItem("savedT")}`,
               "ngrok-skip-browser-warning": "any",
            },
            body: JSON.stringify(data),
         });
         if (response.status == 201 || response.status == 200) {
            const resData = await response.json();
            localStorage.setItem("waiter_id", resData.waiter_id);
            localStorage.setItem("savedT", resData.jwt_token);
            console.log(resData, "resDataWAITER");
            localStorage.setItem("waiter_mode", resData.waiter_mode);
            if (resData.waiter_mode) {
               navigate(`/orders`);
            }
            return resData;
         } else if ([404, 401].includes(response.status)) {
            setOpen(true);
         } else {
            return console.log("Can't find the user");
         }
      } catch (error) {
         throw error;
      }
   };

   const handleClose = (event, reason) => {
      if (reason === "clickaway") {
         return;
      }

      setOpen(false);
   };

   return (
      <ThemeProvider theme={theme}>
         <Alert
            open={open}
            onClose={(e) => handleClose(e)}
            message={"Username and password are incorrect"}
         />
         <Formik
            initialValues={{ username: "", password: "", waiter_info: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
               handleLogin(values);
            }}>
            {({ values, handleChange, handleBlur, handleSubmit }) => (
               <Form
                  className="w-[70%]"
                  onSubmit={handleSubmit}>
                  <h2 className="text-[#0F6656] text-[2.5rem] text-center font-semibold mb-3">LOG IN</h2>
                  <div className="flex flex-col gap-[2rem]">
                     <div>
                        <p className="text-[1.3rem] mb-2 text-[#393D3D] font-medium">User Name</p>
                        <Field name="username">
                           {({ field }) => (
                              <TextField
                                 {...field}
                                 fullWidth
                                 variant="outlined"
                                 placeholder="Enter Username"
                              />
                           )}
                        </Field>
                        <ErrorMessage
                           name="username"
                           component="div"
                           className="text-red-500 text-[1.2rem]"
                        />
                     </div>
                     <div>
                        <p className="text-[1.3rem] mb-2 text-[#393D3D] font-medium">Password</p>
                        <Field name="password">
                           {({ field }) => (
                              <TextField
                                 {...field}
                                 type={showPassword ? "text" : "password"}
                                 fullWidth
                                 variant="outlined"
                                 placeholder="Enter Password"
                              />
                           )}
                        </Field>
                        <ErrorMessage
                           name="password"
                           component="div"
                           className="text-red-500 text-[1.2rem]"
                        />
                     </div>
                     <button
                        onClick={handleLogin}
                        type="submit"
                        className="bg-[#0F6656] mt-[1.5rem] text-[1.3rem] p-[0.8rem] rounded-lg text-[#fff] font-medium">
                        Log In
                     </button>
                  </div>
               </Form>
            )}
         </Formik>
      </ThemeProvider>
   );
};

export default WaiterLogin;
