/** @format */

import { Box } from "@mui/material";
import React from "react";

const NewFeatureTag = ({ isNew, isActive }) => {
   return (
      <Box
         sx={{
            backgroundColor: isActive ? "#ffff" : "#2FBF71",
            fontSize: "12px",
            borderRadius: "14px",
            height: "25px",
            width: "50px",
            color: isActive ? "#000" : "#ffff",
            alignItems: "center",
            justifyContent: "center",
         }}
         display={!isNew ? "none" : "flex"}>
         New
      </Box>
   );
};

export default NewFeatureTag;
