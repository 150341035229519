/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";

const OFFERS_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/offers/`;

export const GetAllOffers = createAsyncThunk(`GetAllOffers`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${OFFERS_API_ENDPOINT}`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "true",
         },
      });

      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         console.log("Get all Offers response ", resData);
         return resData;
      } else {
         return rejectWithValue("Can't  fetch the Offers");
      }
   } catch (error) {
      throw error;
   }
});

export const CreateOffer = createAsyncThunk(`CreateOffer`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${OFFERS_API_ENDPOINT}`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "true",
         },
         body: JSON.stringify(data),
      });

      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         console.log("Offer Created response ", resData);
         return resData;
      } else {
         return rejectWithValue("Can't create the Offer");
      }
   } catch (error) {
      throw error;
   }
});

export const UpdateOffer = createAsyncThunk(`UpdateOffer`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${OFFERS_API_ENDPOINT}${data.offer_id}`, {
         method: "PUT",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "true",
         },
         body: JSON.stringify(data),
      });

      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         console.log("Offer Updated response ", resData);
         return resData;
      } else {
         return rejectWithValue("Can't update the Offer");
      }
   } catch (error) {
      throw error;
   }
});

export const DeleteOffer = createAsyncThunk(`DeleteOffer`, async (data, { rejectWithValue }) => {
   console.log("DeleteOffer data", data);
   try {
      const response = await fetch(`${OFFERS_API_ENDPOINT}${data}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "true",
         },
      });

      if (response.status == 201 || response.status == 200 || response.status == 204) {
         return { offer_id: data };
      } else {
         return rejectWithValue("Can't delete the Offer");
      }
   } catch (error) {
      throw error;
   }
});

export const GetOfferById = createAsyncThunk(`GetOfferById`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${OFFERS_API_ENDPOINT}${data}`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });

      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         console.log("Get Specific Offer response ", resData);
         return resData;
      } else {
         return rejectWithValue("Can't fetch the Offer");
      }
   } catch (error) {
      throw error;
   }
});
