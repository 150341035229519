/** @format */
import React, { useState } from "react";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { toast } from "react-hot-toast";
import { SubmitContactUs } from "store/action/ContactUsAction";
import Loader from "components/Loader";

const api = process.env.REACT_APP_DEV_URL;

export default function ContactUsModal({ visible, onClose }) {
   const [data, setdata] = useState({
      title: "",
      description: "",
   });

   const [loading, setloading] = useState(false);
   const isFormValid = () => Object.values(data).every((item) => item !== "");

   const onSubmit = () => {
      setloading(true);
      SubmitContactUs(data)
         .then((res) => {
            setloading(false);
            toast.success("Submitted your query successfully");
            setdata({
               title: "",
               description: "",
            });
         })
         .catch((err) => {
            setloading(false);
            toast.error("Something went wrong");
         });
   };

   return (
      <Modal
         onClose={onClose}
         className="deleteModal"
         open={visible}>
         <div className="deleteModalMainContainer position-relative">
            <Loader isLoading={loading} />
            <h5>Contact us</h5>
            <CloseIcon
               onClick={onClose}
               className="position-absolute right-2 top-2 cursor-pointer"
            />
            <div className="w-full flex flex-col gap-3 mt-3 p-3">
               <div>
                  <div>Title *</div>
                  <input
                     value={data.title}
                     onChange={(e) => setdata({ ...data, title: e.target.value.trim() })}
                     placeholder="Enter the title"
                     className="h-[3rem] border-[1px] mt-1 border-solid  border-[#c4c4ca]"
                  />
               </div>
               <div>
                  <div>Description *</div>
                  <textarea
                     value={data.description}
                     onChange={(e) => setdata({ ...data, description: e.target.value.trim() })}
                     placeholder="Enter the title"
                     className="h-[7rem] outline-none w-full px-3 py-2 border-[1px] mt-1 border-solid  border-[#c4c4ca]"
                  />
               </div>
               <div className="flex items-center justify-center mt-4 gap-3">
                  <button
                     onClick={onClose}
                     className="bg-[#fff] hover:bg-[red] hover:text-[#fff] border-[1px] border-solid border-[red] text-[red] cursor-pointer px-3 py-1 rounded-[0.4rem] text-[1.2rem] w-[7rem]">
                     Cancel
                  </button>
                  <Button
                     disabled={!isFormValid()}
                     onClick={onSubmit}
                     sx={{
                        backgroundColor: "#2D6B4D",
                        color: "#fff",
                        paddingLeft: "12px", // 'px-3' generally translates to 12px
                        paddingRight: "12px",
                        paddingTop: "4px", // 'py-1' generally translates to 4px
                        paddingBottom: "4px",
                        borderRadius: "0.4rem",
                        fontSize: "1.2rem",
                        width: "7rem",
                        textTransform: "none",
                        "&:hover": {
                           backgroundColor: "#2D6B4D",
                           color: "#fff",
                        },
                        ":disabled": {
                           backgroundColor: "#c4c4ca", // Ensures the color remains when disabled
                           color: "#fff", // Optional: set text color for disabled state
                        },
                     }}>
                     Submit
                  </Button>
               </div>
            </div>
         </div>
      </Modal>
   );
}
