/** @format */

import React from "react";
import { Modal } from "@mui/material";
import AddonsTab from "components/AddItemModal/AddonsTab";

export default function AddonItemModal({ visible, onClose, editIndex }) {
   return (
      <Modal
         onClose={onClose}
         open={visible}>
         <div className="AddItemModalContainer">
            <AddonsTab
               defaultAdd={true}
               onClose={onClose}
               editIndex={editIndex}
            />
         </div>
      </Modal>
   );
}
