import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const BEST_SELLING_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/best_selling_items/`;

export const GetBestSellingItems = createAsyncThunk(
  `GetBestSellingItems`,
  async ( data, { rejectWithValue } ) => {
    console.log( "GET BEST SELLING ITEMS" );
    try
    {
      const response = await axios.get( BEST_SELLING_API_ENDPOINT, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
          'ngrok-skip-browser-warning': 'any',
        },
      } );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;
        return resData;
      } else
      {
        return rejectWithValue( "Can't Get Recommended Items" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  },
);

export const CreateBestSellingItems = createAsyncThunk(
  `CreateBestSellingItems`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.post(
        BEST_SELLING_API_ENDPOINT,
        { item_ids: data },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant'Create Best selling items" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  },
);
