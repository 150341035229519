import React from 'react';

function VegIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='2.75'
        y='2.74998'
        width='18.5'
        height='18.5'
        rx='3.25'
        fill='white'
        stroke='#2D6B4D'
        stroke-width='1.5'
      />
      <circle cx='12' cy='12' r='6' fill='#2D6B4D' />
    </svg>
  );
}

export default VegIcon;
