/** @format */

import React from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const DraggableIndicator = () => {
  return (
    <span
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        marginRight: "1%",
      }}
    >
      <DragIndicatorIcon
        sx={{
          color: "#878C8C",
        }}
      />
    </span>
  );
};

export default DraggableIndicator;
