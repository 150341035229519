import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const RECOMMENDED_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/recommended_items/`;

export const GetRecommendedItems = createAsyncThunk(
  `GetRecommendedItems`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(RECOMMENDED_API_ENDPOINT, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('savedT')}`,
          'ngrok-skip-browser-warning': 'any',
        },
      });
      console.log('Get Recommended Response ', response);
      if (response.status == 201 || response.status == 200) {
        const resData = await response.json();
        console.log(resData);
        return resData;
      } else {
        return rejectWithValue("Can't  fetch the recommendedItems");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const CreateRecommendedItems = createAsyncThunk(
  `CreateRecommendedItems`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        RECOMMENDED_API_ENDPOINT,
        { item_ids: data },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('savedT')}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      console.log('DATA', data);
      console.log('RESPONSe', response);
      if (response.status === 201 || response.status === 200) {
        const resData = await response.data;

        return resData;
      } else {
        return rejectWithValue("Cant'Create RecommendedItems");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);
