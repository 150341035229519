/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { CreateLoyaltyRules, EditLoyaltyRules, EnableLoyalty, GetLoyaltyDetails, GetLoyaltyRules } from "store/action/LoyaltyAction";

const initialState = {
   enable_loyalty: false,
   loyalty_rules: {},
};

const LoyaltyReducer = createSlice({
   name: "LoyaltyReducer",
   initialState,
   extraReducers: {
      // Enable Loyalty
      [EnableLoyalty.fulfilled]: (state, { payload }) => {
         console.log("ENABLE LOYALTY PAYLOAD IN RD => ", payload);
         state.enable_loyalty = !state.enable_loyalty;
      },
      [GetLoyaltyRules.fulfilled]: (state, { payload }) => {
         console.log("GET LOYALTY RULES PAYLOAD IN RD => ", payload);
         state.loyalty_rules = payload;
      },
      [GetLoyaltyDetails.fulfilled]: (state, { payload }) => {
         console.log("GET LOYALTY DETAILS PAYLOAD IN RD => ", payload);
         state.enable_loyalty = payload;
      },

      // [EditLoyaltyRules.fulfilled]: (state, { payload }) => {
      //    console.log("EDIT LOYALTY RULES PAYLOAD IN RD => ", payload);
      //    state.loyalty_rules = payload;
      // },
      // [CreateLoyaltyRules.fulfilled]: (state, { payload }) => {
      //    console.log("CREATE LOYALTY RULES PAYLOAD IN RD => ", payload);
      //    state.loyalty_rules = payload;
      // },
   },
});

export const {} = LoyaltyReducer.actions;

export default LoyaltyReducer.reducer;
