/** @format */

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import Alert from "@mui/material/Alert";
import DeleteIcon from "assets/icons/DeleteIcon";
import Button2 from "../Button2/index";

export function DialogBox({ open, handleClose, title, content, handleContinue, noButton, autoConfirm }) {
   return (
      <Dialog
         open={open}
         onClose={handleClose}
         PaperProps={{
            sx: {
               borderRadius: "10px",
               width: "20%",
            },
         }}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description">
         <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
         <DialogContent
            sx={{
               height: "fit-content",
            }}>
            <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
         </DialogContent>
         {autoConfirm && <Alert severity="warning">Only paid orders will be auto confirmed, you will still have to confirm un-paid orders</Alert>}
         <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {!noButton && (
               <Button
                  onClick={handleContinue}
                  autoFocus>
                  Continue
               </Button>
            )}
         </DialogActions>
      </Dialog>
   );
}
