/** @format */

import React, { useEffect, useState } from "react";
import Button from "components/Button2";
import "./index.scss";
import CrossIcon from "assets/icons/CrossIcon";
import TickIcon from "assets/icons/TickIcon";
import { DeleteModal } from "components/DeleteModal";
import { useSelector } from "react-redux";
import Arrow from "assets/icons/Arrow";
import RightArrow from "assets/icons/RightArrow";
import PrintOrder from "pages/Orders/PrintOrder";
import CustomAccordion from "components/CustomAccordion";
import { capitalizeFirstLetter, getCurrency } from "utils/functions";

function DineInOrderCard({ item, index, handleUpdateSubOrder, showOrderStatus, isSuborderCancel, setSuborderCancel, orderDetails, data }) {
	const [open, setOpen] = useState(item?.suborder_status === "new_order");
	//Temporary function

	const { merchantDetails } = useSelector((state) => state.MerchantReducer);

	const [isVisible, setVisible] = useState(false);
	const [printOrder, setprintOrder] = useState(false);
	const BTNText = (orderStatus) => {
		console.log("NEW ORDER BTN", orderStatus);
		if (orderStatus === "new_order" || orderStatus === "order_updated") {
			return "Accept order";
		} else if (orderStatus === "order_confirmed") {
			return "Start preparation";
		} else if (orderStatus === "start_preparation") {
			return "Ready to serve";
		} else if (orderStatus === "cancelled") {
			return "Order cancelled";
		} else if (orderStatus === "order_completed") {
			return "Complete order ";
		}
	};

	useEffect(() => {
		BTNText(item?.suborder_status);
	}, [item?.suborder_items_ordered]);

	console.log("DATA DINE IN ORDER CARD", data);

	const showModalMessage = () => {
		if (item?.suborder_status === "order_confirmed") {
			return "Are you sure you want to start prepartion for this order ?";
		} else if (item?.suborder_status === "start_preparation") {
			return "Are you sure you want to serve this order ?";
		} else {
			return "Are you sure you want to confirm this order ?";
		}
	};

	return (
		<>
			<DeleteModal
				custom
				BtnBg={isVisible ? "#2FBF71" : "#D90429"}
				Btntitle={"Yes"}
				message={isVisible ? <>{showModalMessage()}</> : <>Are you sure you want to cancel this order ?</>}
				isVisible={isVisible ? isVisible : isSuborderCancel}
				onClose={isVisible ? setVisible : setSuborderCancel}
				handleClick={() => {
					handleUpdateSubOrder(item?.suborder_id);
					if (isVisible) {
						setVisible(false);
					} else {
						setSuborderCancel(false);
					}
				}}
			/>

			<div className="dine-in-order-card-table">
				<div
					className="dine-in-order-card-header"
					onClick={() => {
						setOpen(!open);
					}}>
					<div className="dine-in-order-card-header-order">
						<span
							style={{
								whiteSpace: "nowrap",
							}}>
							Order {index + 1}
						</span>
						<span className={`${showOrderStatus(item?.suborder_status)?.className} dine-in-status`}>{showOrderStatus(item?.suborder_status)?.status}</span>
					</div>
					<span>
						<RightArrow open={open} />
					</span>
				</div>
				{open && (
					<>
						<table id="dine-in-table">
							<thead>
								<tr>
									<th scope="col">ITEM NAMES</th>
									<th
										className="text-center"
										scope="col">
										QTY
									</th>
									<th
										className="text-center"
										scope="col">
										RATE
									</th>
								</tr>
							</thead>
							<PrintOrder
								data={{ ...data, items_ordered: item?.suborder_items_ordered }}
								printKOT={true}
								onClose={() => setprintOrder(false)}
								visible={printOrder}
							/>
							<tbody>
								{item?.suborder_items_ordered?.map((i, index) => {
									return (
										<>
											<tr>
												<td className="td-card-item-name">
													{index + 1}. {capitalizeFirstLetter(i?.item_name)}
												</td>
												<td className="td-card-item-name text-center">{i?.count}</td>
												<td className="td-card-item-name text-center">
													{getCurrency(merchantDetails?.currency)} {i?.count * i?.price_after_discount}
												</td>
											</tr>
											{i?.addons?.map((item, index) => {
												return (
													<tr key={index}>
														<td
															style={{ paddingLeft: "1.5rem" }}
															className="td-card-item-name">
															{item?.item_name} <span className="add-on-tag">Addons</span>
														</td>
														<td className="td-card-item-name text-center">{item?.count}</td>
														<td className="td-card-item-name text-center">
															{getCurrency(merchantDetails?.currency)} {item?.count * item.price}
														</td>
													</tr>
												);
											})}
											{i?.customizations?.map((customization, index) => {
												return (
													<tr key={index}>
														<td
															style={{ paddingLeft: "1.5rem" }}
															className="td-card-item-name">
															{customization?.selected_options?.map((item) => {
																return (
																	<div>
																		{customization.customisation_title} - {item} <span className="customization-tag">customization</span>
																	</div>
																);
															})}
														</td>
													</tr>
												);
											})}
											{i?.other_customization !== undefined && i?.other_customization !== "" && (
												<tr key={index}>
													<td className="td-card-item-name">
														{i?.other_customization} <span className="customization-tag">customization</span>
													</td>
												</tr>
											)}
										</>
									);
								})}{" "}
							</tbody>
						</table>

						<div className="dine-in-order-card-btn-wrapper">
							<>
								{orderDetails?.order_status !== "order_completed" && item?.suborder_status !== "cancelled" && item?.suborder_status !== "start_preparation" && item?.suborder_status !== "ready_to_serve" && (
									<Button
										height={"2.2rem"}
										width={"150px"}
										Size="sm"
										title="Cancel order"
										icon={<CrossIcon color="#D90429" />}
										border={"1px solid #D90429"}
										color={"#D90429"}
										onClick={() => {
											setSuborderCancel(true);
										}}
									/>
								)}
								{item?.suborder_status !== "ready_to_serve" && (
									<Button
										opacity={item?.suborder_status === "cancelled" ? "0.5" : "1"}
										disabled={item?.suborder_status === "cancelled" ? true : false}
										height={"2.2rem"}
										width={"auto"}
										Size="sm"
										title={BTNText(item?.suborder_status)}
										border="none"
										icon={item?.suborder_status !== "cancelled" && <TickIcon />}
										background={item?.suborder_status !== "cancelled" ? "#2FBF71" : "#D90429"}
										color={"#fff"}
										onClick={() => {
											setVisible(true);
										}}
									/>
								)}

								{item?.suborder_status !== "cancelled" && item?.suborder_status !== "new_order" && (
									<Button
										Size="sm"
										height={"2.2rem"}
										width={"180px"}
										title="Send to KOT"
										border={"none"}
										color={"#fff"}
										background={"#4361EE"}
										onClick={() => setprintOrder(true)}
									/>
								)}
							</>
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default DineInOrderCard;
