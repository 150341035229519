/** @format */

import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import { faCircleCheck as faRegularCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck as faSolidCirclecheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { getCurrency } from "utils/functions";

function SubscriptionCard(props) {
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);

   console.log("SUBSCRIPTION CARD PROPS", props);

   return (
      <div className={"d-flex col-5 flex-column subscription-card flex-nowrap p-3 align-items-center  mt-4 mx-2" + (props.dark ? " dark" : " light")}>
         {props.recommended && <div id="recommended">Recommended</div>}
         <div className="d-flex flex-column align-items-center">
            {/* <div className='card-highlight p-1'>{ `${props.discount}% Off` }</div> */}
            <div className="fs-3 fw-bold my-2">{props.title}</div>
            <div className="fw-bold">
               <span className="fc-grey">{getCurrency(merchantDetails?.currency)}</span>
               <span className="">{props.price}</span>
               <span className="fc-grey">{props.timePeriod === "monthly" ? "/month" : "/annually"}</span>
            </div>
         </div>
         <div
            className="d-flex flex-column "
            style={{
               height: "15rem",
            }}>
            <div className="horizontal-rule"></div>
            {/* <div className='d-flex mb-3'>
          <div className='me-2'>
            <FontAwesomeIcon icon={props.dark ? faSolidCirclecheck : faRegularCircleCheck} />
          </div>
          <div className=''>{props.point1}</div>
        </div>
        <div className='d-flex mb-3'>
          <div className='me-2'>
            <FontAwesomeIcon icon={props.dark ? faSolidCirclecheck : faRegularCircleCheck} />
          </div>
          <div>{props.point2}</div>
        </div>
        <div className='d-flex mb-3'>
          <div className='me-2'>
            <FontAwesomeIcon icon={props.dark ? faSolidCirclecheck : faRegularCircleCheck} />
          </div>
          <div>{props.point3}</div>
        </div> */}
            {props.description !== ""
               ? props.description?.map((i) => (
                    <div className="d-flex mb-3">
                       <div className="me-2">
                          <FontAwesomeIcon icon={props.dark ? faSolidCirclecheck : faRegularCircleCheck} />{" "}
                       </div>
                       <div>
                          <div className="fw-bold">
                             <span>{i}</span>
                             {/* <span className='fc-grey'> your physical menu</span> */}
                          </div>
                       </div>
                    </div>
                 ))
               : null}
         </div>
         <div className="card-btn-wrapper">
            <button
               className="card-highlight px-3 py-2"
               onClick={() => {
                  props.handleChoosePlan(props.plan);
               }}>
               {"Choose this plan"}
            </button>
         </div>
      </div>
   );
}

export default SubscriptionCard;
