import QRCodeCard from 'components/QRCodeCard';
import React, { useState } from 'react';
import Button from 'components/Button2';
import PlusIcon from 'assets/icons/PlusIcon';

function QRCodes({
  setInput,
  getQRDetails,
  input,
  setEdit,
  data,
  handleDownload,
  handleDelete,
  activeTab,
}) {
  const [isVisible, setVisible] = useState('');

  console.log(data, 'QR CODES DARA');

  return (
    <>
      <div className='QRHeader'>
        <></>
        {/* {activeTab === 'dine-in' && (
          <Button
            onClick={() => {
              setInput((p) => !p);
            }}
            title={'Generate More QR'}
            Size='md'
            icon={<PlusIcon />}
            background={'#4361EE'}
            border='none'
            color={'#fff'}
            disabled={input}
          />
        )} */}
      </div>

      <div className='QRCardWrapper'>
        {data?.map((qr, index) => (
          <QRCodeCard
            qr={qr}
            isVisible={isVisible === qr?.qr_code_id ? true : false}
            // isVisible={true}
            setVisible={setVisible}
            handleDelete={handleDelete}
            activeTab={activeTab}
            setInput={setInput}
            setEdit={setEdit}
            getQRDetails={getQRDetails}
          />
        ))}
      </div>

      {/* {data?.length > 0 && (
        <Button
          title={'Download QR Codes'}
          Size='md'
          background={'#2D6B4D'}
          border='none'
          color={'#fff'}
          onClick={handleDownload}
        />
      )} */}
    </>
  );
}

export default QRCodes;
