/** @format */

import Layout from "layouts";
import React, { useEffect, useState } from "react";
import "./index.scss";
import * as yup from "yup";
import QRCodes from "./QRCodes";
import Loader from "components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { CreateQRCode, deleteQRCode, DownloadQrCodes, GetAllQrCodes, ModifyQRCode } from "store/action/QRActions";
import QRInputModal from "components/QRInputModal";
import Button from "components/Button2";
import PlusIcon from "assets/icons/PlusIcon";
import { GetMerchant } from "store/action/MerchantAction";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Standalone from "./Standalone";
import Tables from "./Tables";

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}>
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function QRCode() {
   const [loading, setloading] = useState(false);
   const [isEdit, setEdit] = useState(false);
   const [qrDetails, getQRDetails] = useState({});
   const [activeTab, setactiveTab] = useState("standalone");
   const dispatch = useDispatch();
   const qrCodes = useSelector((state) => state.QRCodeReducer.qrCodes);
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);
   const [QR, setQR] = useState([]);
   const [QRInput, setQRInput] = useState(false);
   const [value, setvalue] = useState(1);
   const initialValues = {
      table_count: 0,
   };
   const editInitialValues = {
      table_identifier: "",

      // qr_table_identifier: str = Field(...)
      // qr_code_table_number: str = Field(...)
      // qr_code_type: str = Field(...)
   };
   const validationSchema = yup.object({
      table_count: yup.number().max(10, "Count should be less than or equal to 10"),
   });
   const editValidationSchema = yup.object({
      table_identifier: yup.string().required("Field is required"),
   });
   const onSubmit = async (values) => {
      if (!isEdit) {
         const body = {
            table_count: activeTab === "dine-in" ? parseInt(values?.table_count) : 3,
            qr_code_type: "standalone",
            restaurant_name: merchantDetails.restaurant_name,
         };
         setloading(true);
         const result = await dispatch(CreateQRCode(body));
         setQR(result?.payload?.qr_codes);
         console.log("QrResult", result);
         setQRInput(false);
         setloading(false);
      } else {
         console.log("QRDETAILS", qrDetails);
         setloading(true);
         const body = {
            qr_table_identifier: values?.table_identifier,
            qr_code_table_number: qrDetails?.table_number,
            qr_code_type: "dine-in",
         };
         console.log("EDITED", body);
         // await dispatch(ModifyQRCode({ qr_code_id: qrDetails?.qr_code_id, data: body }));
         setQRInput(false);
         setEdit(false);
         setloading(false);
      }
   };
   const FETCH_ALL_QRCODES = async () => {
      console.log("FETCH_ALL_QRCODES");
      setloading(true);
      setQRInput(false);
      const result = await dispatch(GetAllQrCodes());
      await dispatch(GetMerchant());
      console.log("Fetching All QRCodes....", result?.payload);
      setQR(result.payload?.qr_codes);
      if (result?.payload?.qr_codes?.length === 0) {
         setQRInput(true);
      }
      setloading(false);
   };
   const handleDelete = async (id) => {
      await dispatch(deleteQRCode(id));
      FETCH_ALL_QRCODES();
   };

   useEffect(() => {
      FETCH_ALL_QRCODES();
   }, []);

   function a11yProps(index) {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   }

   console.log("QR CODES IN SCREEN", qrCodes);

   return (
      <div>
         <Layout>
            <Loader isLoading={loading} />
            <div className="QRContainer">
               <div className="text-[1.5rem] text-[#2D6B4D] font-bold">Tables</div>
               <Tables
                  handleDelete={handleDelete}
                  data={qrCodes["dine-in"]?.filter((item) => item?.qr_code_type === "dine-in")}
               />
            </div>
         </Layout>
      </div>
   );
}

export default QRCode;
