import NoItems from 'components/NoItems';
import Layout from 'layouts';
import PlusBtn from 'assets/icons/PlusBtnSvg';
import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from 'components/Button2';
import PlusIcon from 'assets/icons/PlusIcon';
import AddCombo from './AddCombo';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import comboReducer from 'store/reducer/comboReducer';
import { deleteCombo, getAllCombos, getSingleCombo } from 'store/action/CombosAction';
import Loader from 'components/Loader';
import toast from 'react-hot-toast';

function Combos() {
  const [isAdding, setAdding] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  const FETCH_COMBOS = async () => {
    setloading(true);
    const result = await dispatch(getAllCombos());
    if (result.type === 'getAllCombos/fulfilled') {
      console.log('GOT COMBOS', result);
    } else {
      console.log('Error');
      toast.error('Error occured');
    }
    setloading(false);
  };

  const handleDeleteItem = async (id) => {
    setloading(true);
    console.log(id);
    const result = await dispatch(deleteCombo(id));
    toast.success('Combo deleted successfully');
    console.log(result);
    setloading(false);
  };

  useEffect(() => {
    FETCH_COMBOS();
  }, []);

  const handleEditItem = async (id) => {
    const result = await dispatch(getSingleCombo(id));
    console.log(result);
    setEdit(true);
  };

  const combos = useSelector((state) => state.comboReducer.combos);

  console.log('ALL COmbOS', combos);

  const tableHead = [
    'Image',
    'Combo Name',
    'Created at',
    'Combo Price',
    'Discount',
    'Price after discount',
    'Quantities Sold',
  ];

  return (
    <Layout>
      <Loader isLoading={loading} />
      {isAdding || isEdit ? (
        <AddCombo
          isEdit={isEdit}
          setAdding={setAdding}
          isAdding={isAdding}
          setEdit={setEdit}
          handleClose={setAdding}
        />
      ) : (
        <div className='combosMainContainer'>
          <div className='combosHeader'>
            Combo Items
            {combos?.length > 0 && (
              <Button
                Size='md'
                title={`Create combo`}
                icon={<PlusIcon color={'#4361EE'} />}
                border='none'
                background='#ECEFFD'
                color={'#4361EE'}
                fontWeight='bold'
                onClick={() => setAdding((p) => !p)}
              />
            )}
          </div>
          <div
            className={
              combos.length === 0 ? 'combosItemWrapper ActiveNoItems' : 'combosItemWrapper'
            }
          >
            {combos.length > 0 ? (
              <Table
                sx={{
                  borderCollapse: 'separate',
                  borderSpacing: '0 10px',
                  border: 'none',
                  height: '30rem',
                  overflow: 'scroll',
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: '#F5F5F9',
                      position: 'sticky',
                      top: 0,
                    }}
                  >
                    {tableHead?.map((head, index) => (
                      <TableCell
                        align='center'
                        className='combo-table-head-cell'
                        sx={{
                          fontSize: '0.8rem',
                          color: '#000',
                          fontWeight: '600',
                        }}
                        key={index}
                      >
                        {head}
                      </TableCell>
                    ))}
                    {/*Extra Cell for Spacing */}
                    <TableCell align='center' className='combo-table-head-cell'></TableCell>
                    <TableCell align='center' className='combo-table-head-cell'></TableCell>
                    <TableCell align='center' className='combo-table-head-cell'></TableCell>
                    <TableCell align='center' className='combo-table-head-cell'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {combos?.map((item, index) => (
                    <TableRow className='combo-table-body-row' key={index}>
                      <TableCell align='center' className='combo-table-cell'>
                        <img className='combo-card-image-wrapper' src={item?.image} alt='' />
                      </TableCell>
                      <TableCell align='center' className='combo-table-cell'>
                        {item?.combo_name}
                      </TableCell>
                      <TableCell align='center' className='combo-table-cell'>
                        {item.created_at.slice(0, 10)}
                      </TableCell>
                      <TableCell align='center' className='combo-table-cell'>
                        {item?.combo_price}
                      </TableCell>
                      <TableCell align='center' className='combo-table-cell'>
                        {item?.discount}
                      </TableCell>
                      <TableCell align='center' className='combo-table-cell'>
                        {item?.combos_price_after_discount}
                      </TableCell>
                      <TableCell align='center' className='combo-table-cell'>
                        {item.quantities_sold}
                      </TableCell>
                      <TableCell align='center' className='combo-table-cell'></TableCell>
                      <TableCell align='center' className='combo-table-cell'></TableCell>
                      <TableCell align='center' className='combo-table-cell'>
                        <Button
                          Size='xs'
                          title='Delete'
                          color={'#D90429'}
                          border='none'
                          onClick={() => {
                            handleDeleteItem(item?.combo_id);
                          }}
                        />
                      </TableCell>
                      <TableCell className='combo-table-cell'>
                        {' '}
                        <Button
                          Size='xs'
                          title='Edit'
                          color='#4361EE'
                          border='none'
                          onClick={() => {
                            handleEditItem(item?.combo_id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <>
                {' '}
                <NoItems
                  title={'No combos created!'}
                  description={'Create combos to add items to your combos thst'}
                />
                <Button
                  Size='md'
                  title={`Create combo`}
                  icon={<PlusIcon color={'#4361EE'} />}
                  border='none'
                  background='#ECEFFD'
                  color={'#4361EE'}
                  fontWeight='bold'
                  onClick={() => setAdding((p) => !p)}
                />
              </>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Combos;
