import { createAsyncThunk } from '@reduxjs/toolkit';
const QR_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/qr_code/`;
const MODIFYQR_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/qr_code/modify_table_identifier/`;

export const CreateQRCode = createAsyncThunk(`CreateQRCode`, async (data, { rejectWithValue }) => {
  try {
    const response = await fetch(QR_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('savedT')}`,
      },
      body: JSON.stringify(data),
    });
    console.log(response);
    if (response.status == 201 || response.status == 200) {
      const resData = await response.json();
      console.log(resData);
      return resData;
    } else {
      return rejectWithValue("Can't create the QRCode");
    }
  } catch (error) {
    throw error;
  }
});

export const GetAllQrCodes = createAsyncThunk(
  `GetAllQrCodes`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(`${QR_ENDPOINT}all`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('savedT')}`,
          'ngrok-skip-browser-warning': 'any',
        },
      });
      console.log(response);
      if (response.status == 201 || response.status == 200) {
        const resData = await response.json();
        return resData;
      } else {
        return rejectWithValue("Can't create the QRCode");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const deleteManyQRCodes = createAsyncThunk(
  `deleteManyQRCodes`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(`${QR_ENDPOINT}delete_many`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('savedT')}`,
        },
        body: JSON.stringify({ qr_codes: data }),
      });
      console.log(response);
      if (response.status == 201 || response.status == 200 || response.status == 204) {
        const resData = await response.json();
        console.log('DELTETE    QR CODE', resData);
        return resData;
      } else {
        return rejectWithValue("Can't delete the category");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const DownloadQrCodes = createAsyncThunk(
  `DownloadQrCodes`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(`${QR_ENDPOINT}all_table_tent_pdf`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('savedT')}`,
          'ngrok-skip-browser-warning': 'any',
        },
      });
      console.log(response);
      if (response.status == 201 || response.status == 200) {
        const resData = await response.json();
        return resData;
      } else {
        return rejectWithValue("Can't create the QRCode");
      }
    } catch (error) {
      throw error;
    }
  },
);

export const deleteQRCode = createAsyncThunk(`deleteQRCode`, async (id, { rejectWithValue }) => {
  try {
    const response = await fetch(`${QR_ENDPOINT}${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('savedT')}`,
      },
    });
    console.log(response);
    if (response.status == 201 || response.status == 200 || response.status == 204) {
      const resData = await response.json();
      console.log('DELTETE QR CODE', resData);
      return resData;
    } else {
      return rejectWithValue("Can't delete the category");
    }
  } catch (error) {
    throw error;
  }
});

export const ModifyQRCode = createAsyncThunk(`ModifyQRCode`, async (data, { rejectWithValue }) => {
  try {
    const response = await fetch(`${MODIFYQR_ENDPOINT}${data.qr_code_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('savedT')}`,
      },
      body: JSON.stringify(data),
    });
    console.log(response);
    if (response.status == 201 || response.status == 200) {
      const resData = await response.json();
      console.log(resData);
      return resData;
    } else {
      return rejectWithValue("Can't create the QRCode");
    }
  } catch (error) {
    throw error;
  }
});
