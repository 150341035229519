import { createSlice } from '@reduxjs/toolkit';
import {
  GetSubscription,
  GetMerchantPlans,
  GetCurrentSubscription,
  CreateSubscription,
} from 'store/action/SubscriptionAction';

const initialState = {
  subscriptionDetails: [],
  currentSubscription: {},
  allPlans: [],
};

const SubscriptionReducer = createSlice({
  name: 'subcriptions',
  initialState,
  reducers: {},
  extraReducers: {
    [GetCurrentSubscription.fulfilled]: (state, { payload }) => {
      state.currentSubscription = payload;
    },
    [CreateSubscription.fulfilled]: (state, { payload }) => {
      state.currentSubscription = payload;
    },
    [GetSubscription.fulfilled]: (state, { payload }) => {
      state.subscriptionDetails = payload;
    },
    [GetMerchantPlans.fulfilled]: (state, { payload }) => {
      state.allPlans = payload;
    },
  },
});

export const {} = SubscriptionReducer.actions;

export default SubscriptionReducer.reducer;
