/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware } from "redux";
import MenuReducer from "store/reducer/MenuReducer";
import RecommendedReducer from "store/reducer/RecommendedReducer";
import OrderReducer from "store/reducer/OrderReducer";
import MerchantReducer from "store/reducer/MerchantReducer";
import SubscriptionReducer from "store/reducer/SubscriptionReducer";
import BestSellingReducer from "store/reducer/BestSellingReducer";
import SpecialsReducer from "store/reducer/SpecialsReducer";
import NewlyAddedReducer from "store/reducer/NewlyAddedReducer";
import QRCodeReducer from "store/reducer/QRCodeReducer";
import AuthReducer from "store/reducer/AuthReducer";
import comboReducer from "store/reducer/comboReducer";
import CreditsReducer from "store/reducer/CreditsReducer";
import LoyaltyReducer from "store/reducer/LoyaltyReducer";
import OffersReducer from "store/reducer/OffersReducer";
import AddonsReducer from "store/reducer/AddonsReducer";
import ManualOrderReducer from "store/reducer/ManualOrderReducer";
import WaiterReducer from "store/reducer/WaiterReducer";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

const Store = configureStore(
  {
    reducer: {
      MenuReducer,
      RecommendedReducer,
      OrderReducer,
      MerchantReducer,
      SubscriptionReducer,
      BestSellingReducer,
      NewlyAddedReducer,
      QRCodeReducer,
      AuthReducer,
      comboReducer,
      CreditsReducer,
      OffersReducer,
      LoyaltyReducer,
      SpecialsReducer,
      AddonsReducer,
      ManualOrderReducer,
      WaiterReducer,
    },
  },
  applyMiddleware(ReduxThunk)
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={Store}>
      <App />
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
