/** @format */

import Layout from "layouts";
import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import * as MenuAction from "store/action/MenuAction";
import { useDispatch, useSelector } from "react-redux";
import MenuList from "./MenuList";
import Loader from "components/Loader";
import MenuItemList from "./MenuItemList";
import Search from "components/Search";
import { updateData } from "store/reducer/RecommendedReducer";
import { DragDropContext } from "react-beautiful-dnd";
import { dropItem, dropMenu } from "store/reducer/MenuReducer";
import { Modal, Tabs, Tab } from "@mui/material";
import AddonItemModal from "components/AddonItemModal";
import { GetCustomizations } from "store/action/AddonsAction";
import CustomizationTabModal from "components/CustomizationModal";
import { CreateAddons, EditAddon, GetAddons } from "store/action/AddonsAction";
import { styled } from "@mui/material/styles";
import _ from "lodash";

const StyledTab = styled(Tab)({
   color: "gray", // Example inactive color
   textTransform: "none", // Active text transform
   fontSize: "1.2rem",
   fontFamily: "DM Sans", // Active font
   fontWeight: "bold",
   // Styles for the active state
   "&.Mui-selected": {
      color: "#2D6B4D", // Active color
   },
});

function Menu() {
   const [searchResults, setSearchResults] = useState();
   const [searchItemResults, setSearchItemResults] = useState();
   const [openItem, setOpenItem] = useState(false);
   const [tab, setTab] = useState(0);
   const [searchVal, setSearchVal] = useState("");
   const [addCategory, setCategory] = useState(false);
   const Menu = useSelector((state) => state.MenuReducer.Menu);
   const [load, setloading] = useState(false);
   const [select, setSelect] = useState("");
   const [showAddonItemModal, setshowAddonItemModal] = useState(false);
   const [addonEditIndex, setaddonEditIndex] = useState(null);
   const [showCustomizationModal, setshowCustomizationModal] = useState(false);
   const [customizationIndex, setcustomizationIndex] = useState(null);
   const addons = useSelector((state) => state.AddonsReducer.addons);
   const customizations = useSelector((state) => state.AddonsReducer.customizations);
   console.log(searchResults, "searchresultdrommenu");
   const FETCH_ITEMS = async (id) => {
      await Menu.filter((item) => item.id === id).map((i) => {
         setSelect(i.category_name);
         setOpenItem(true);
         localStorage.setItem("categoryId", id);
      });
   };

   const fetchAddons = () => {
      dispatch(GetAddons());
   };

   const fetchCustomizations = () => {
      dispatch(GetCustomizations());
   };

   useEffect(() => {
      fetchAddons();
      fetchCustomizations();

      return localStorage.removeItem("categoryId");
   }, []);

   const tmp = async () => {
      console.log("Menu", Menu);
      if (Menu.length > 0) {
         setSelect(Menu[0]?.category_name);
         setOpenItem(true);
         localStorage.setItem("categoryId", Menu[0]?.id);
      }
   };

   useEffect(() => {
      tmp();
   }, [addCategory]);

   const searchMenu = () => {
      let temp = [];
      let item = [];
      console.log(temp, item, "tempitem");

      if (searchVal != "") {
         if (tab === 0) {
            for (const i in Menu) {
               for (const j in Menu[i].items)
                  if (Menu[i].category_name.toLowerCase().includes(searchVal.toLowerCase()) || Menu[i].items[j].item_name.toLowerCase().includes(searchVal.toLowerCase())) {
                     temp.push(Menu[i]);
                     item.push(Menu[i].items[j]);
                     console.log("Searched Item", item);
                  }
            }
         } else if (tab === 1) {
            for (const i in addons) {
               if (addons[i].item_name.toLowerCase().includes(searchVal.toLowerCase())) {
                  temp.push(addons[i]);
               }
            }
         } else if (tab === 2) {
            for (const i in customizations) {
               if (customizations[i].customisation_title.toLowerCase().includes(searchVal.toLowerCase())) {
                  temp.push(customizations[i]);
               }
            }
         }
         if (temp.length > 0 || item.length > 0) {
            setSearchItemResults(item);
            setSearchResults(temp);
            FETCH_ITEMS(temp[0].id);
         } else {
            setSearchResults([]);
            setSearchItemResults([]);
         }
      }
   };

   function handleAddCategory() {
      setCategory((p) => !p);
   }

   const dispatch = useDispatch();

   const toggleShowAddonItemModal = () => setshowAddonItemModal(!showAddonItemModal);
   const toggleShowCustomizationModal = () => setshowCustomizationModal(!showCustomizationModal);

   const FETCH_CATEGORIES = async () => {
      setloading(true);
      const result = await dispatch(MenuAction.GetMenu());
      if (result.type === "GetMenu/fulfilled") {
         if (result?.payload?.length > 0) {
            setSelect(result?.payload[0]?.category_name);
            localStorage.setItem("categoryId", result?.payload[0].id);
            setOpenItem(true);
         }
      }
      dispatch(updateData(Menu));
      setloading(false);
   };

   const onDragEndMenu = async (result) => {
      console.log("DRAGGED", result);
      const copyListItems = [...Menu];
      console.log("copyListItemsss", copyListItems);
      const dragItemContent = copyListItems[result.source.index];
      copyListItems.splice(result.source.index, 1);
      copyListItems.splice(result.destination.index, 0, dragItemContent);
      await dispatch(dropMenu({ copyListItems, type: "category" }));
      await dispatch(
         MenuAction.DragAndDrop({
            items_array: copyListItems.map((i) => {
               return {
                  category_id: i.id,
                  category_idx: copyListItems.indexOf(i),
                  category_name: i.category_name,
               };
            }),
            source: "categories",
         }),
      );
   };

   const onDragEndItem = async (result) => {
      let catId = localStorage.getItem("categoryId");
      const items = Menu?.find((i) => i.id === catId).items;
      const copyListItems = [...items];
      const dragItemContent = copyListItems[result.source.index];
      copyListItems.splice(result.source.index, 1);
      copyListItems.splice(result.destination.index, 0, dragItemContent);
      await dispatch(dropMenu({ copyListItems, catId, type: "item" }));
      await dispatch(
         MenuAction.DragAndDrop({
            items_array: copyListItems.map((i) => {
               return {
                  item_id: i.item_id,
                  item_idx: copyListItems.indexOf(i),
               };
            }),
            source: "items",
         }),
      );
   };
   useEffect(() => {}, [select]);

   useEffect(() => {
      FETCH_CATEGORIES();
   }, []);

   useEffect(() => {
      searchMenu();
   }, [searchVal, tab]);

   const getListContainerText = () => {
      switch (tab) {
         case 0:
            return { name: "Categories", buttonText: "Add category", data: Menu };
            break;
         case 1:
            return { name: "Add ons", buttonText: "Create add on", data: addons };
            break;
         case 2:
            return {
               name: "Customization",
               buttonText: "Add Customization",
               data: customizations,
            };
            break;
         default:
            break;
      }
   };

   const onClickAddButton = () => {
      switch (tab) {
         case 0:
            handleAddCategory();
            break;
         case 1:
            setshowAddonItemModal(true);
            break;
         case 2:
            setshowCustomizationModal(true);
            break;
         default:
            break;
      }
   };

   const onClickCard = (clickedCardId) => {
      switch (tab) {
         case 0:
            FETCH_ITEMS(clickedCardId);
            break;
         case 1:
            setaddonEditIndex(addons?.findIndex((item) => item.add_on_id === clickedCardId));
            setOpenItem(true);
            setshowAddonItemModal(true);
            break;
         case 2:
            setcustomizationIndex(customizations?.findIndex((item) => item.customisation_id === clickedCardId));
            setOpenItem(true);
            setshowCustomizationModal(true);
            break;
         default:
            break;
      }
   };

   const onCloseShowAddonItemModal = () => {
      setaddonEditIndex(null);
      toggleShowAddonItemModal();
   };

   const onCloseCustomizationModal = () => {
      setcustomizationIndex(null);
      toggleShowCustomizationModal();
   };

   const firstItemId = searchResults?.[0]?.id;

   const [isMobileView, setIsMobileView] = useState(null);
   const innerWidth = window.innerWidth;
   useEffect(() => {
      if (innerWidth <= 500) {
         setIsMobileView(true);
      } else {
         setIsMobileView(false);
      }
   });

   console.log(innerWidth, isMobileView, "IW");

   return (
      <div>
         {isMobileView ? (
            <div className="bg-[#F5F5F5] h-[100vh]">
               <div className="w-[90%] mx-auto py-[1rem]">
                  <Search
                     onChange={(e) => {
                        setSearchVal(e.target.value);
                     }}
                     border={"1px solid rgba(0,0,0,0.3)"}
                     InputHeight={"3rem"}
                     padding={"0.8rem"}
                     width={"100%"}
                     placeholder={"Search in menu"}
                  />
               </div>
               <div>
                  <DragDropContext onDragEnd={onDragEndItem}>
                     <MenuItemList
                        mobileView={isMobileView}
                        initialRender={tmp}
                        header={select}
                        openItem={openItem}
                        searchResults={searchItemResults}
                        setSearch={setSearchItemResults}
                        searchVal={searchVal}
                        setloading={setloading}
                        searchItemId={firstItemId}
                     />
                  </DragDropContext>
               </div>
            </div>
         ) : (
            <Layout>
               <Loader isLoading={load} />
               <Tabs
                  className="menu-tab-container"
                  value={tab}
                  onChange={(e, val) => {
                     setTab(val);
                  }}>
                  <StyledTab label="Items" />
                  <StyledTab label="Add Ons" />
                  <StyledTab label="Customization" />
               </Tabs>
               <AddonItemModal
                  visible={showAddonItemModal}
                  onClose={onCloseShowAddonItemModal}
                  editIndex={addonEditIndex}
               />
               <CustomizationTabModal
                  visible={showCustomizationModal}
                  onClose={onCloseCustomizationModal}
                  editIndex={customizationIndex}
               />
               <Search
                  onChange={(e) => {
                     setSearchVal(e.target.value);
                  }}
                  border={"1px solid rgba(0,0,0,0.3)"}
                  InputHeight={"3rem"}
                  padding={"0.8rem"}
                  width={"100%"}
                  placeholder={"Search in menu"}
               />
               <div className="MenuMainContainer">
                  <div className="MenuContainer hidden">
                     <DragDropContext onDragEnd={onDragEndMenu}>
                        <MenuList
                           searchVal={searchVal}
                           tab={tab}
                           searchResults={searchResults?.filter((item, index) => searchResults?.indexOf(item) === index)}
                           searchItemResults={searchItemResults}
                           listTitle={getListContainerText().name}
                           addCategory={addCategory}
                           setSearch={setSearchResults}
                           handle={onClickAddButton}
                           filter={onClickCard}
                           data={getListContainerText().data}
                           openItem={false}
                           handleClose={setOpenItem}
                           header={select}
                           buttonText={getListContainerText().buttonText}
                           setloading={setloading}
                           initialRender={tmp}
                        />
                     </DragDropContext>
                     {tab === 0 && (openItem || searchItemResults?.length > 0) && (
                        <div>
                           <DragDropContext onDragEnd={onDragEndItem}>
                              <MenuItemList
                                 initialRender={tmp}
                                 header={select}
                                 openItem={openItem}
                                 searchResults={searchItemResults}
                                 setSearch={setSearchItemResults}
                                 searchVal={searchVal}
                                 setloading={setloading}
                                 searchItemId={firstItemId}
                              />
                           </DragDropContext>
                        </div>
                     )}
                  </div>
               </div>
            </Layout>
         )}
      </div>
   );
}

export default Menu;
