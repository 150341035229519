import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const HOURLY_SPECIALS_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/specials/hourly_specials`;
const DAILY_SPECIALS_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/specials/daily_specials`;

export const GetHourlySpecials = createAsyncThunk(
  `GetHourlySpecials`,
  async ( data, { rejectWithValue } ) => {
    console.log( "GET HOURLY SPECIALS" );
    try
    {
      const response = await axios.get( HOURLY_SPECIALS_API_ENDPOINT, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
          'ngrok-skip-browser-warning': 'any',
        },
      } );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;
        return resData;
      } else
      {
        return rejectWithValue( "Can't Get Hourly Specials" );
      }
    } catch ( error )
    {
      if (error.response.status === 404) {
        console.log( 404 );
        return [];
      }
      console.log( 'ERROR', error );
      throw error;
    }
  },
);

export const CreateHourlySpecials = createAsyncThunk(
  `CreateHourlySpecials`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.post(
        HOURLY_SPECIALS_API_ENDPOINT,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant'Create Hourly Special" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  },
);

export const DeleteHourlySpecials = createAsyncThunk(
  `DeleteHourlySpecials`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.delete(
        `${HOURLY_SPECIALS_API_ENDPOINT}/${data.hourly_special_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant Delete Hourly Special" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  },
);

export const ActivateHourlySpecials = createAsyncThunk(
  `ActivateHourlySpecials`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.put(
        `${HOURLY_SPECIALS_API_ENDPOINT}/${data.hourly_special_id}/activate`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      // console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant Activate Hourly Special" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  }
);

export const DeactivateHourlySpecials = createAsyncThunk(
  `DeactivateHourlySpecials`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.put(
        `${HOURLY_SPECIALS_API_ENDPOINT}/${data.hourly_special_id}/deactivate`,
        {}, 
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      // console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant Deactivate Hourly Special" );
      }
    }
    catch ( error )
    {
      console.log( error );
      throw error;
    }
  }
);

export const UpdateHourlySpecials = createAsyncThunk(
  `UpdateHourlySpecials`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.put(
        `${HOURLY_SPECIALS_API_ENDPOINT}/${data.hourly_special_id}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant Update Hourly Special" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  }
);

export const GetDailySpecials = createAsyncThunk(
  `GetDailySpecials`,
  async ( data, { rejectWithValue } ) => {
    console.log( "GET DAILY SPECIALS" );
    try
    {
      const response = await axios.get( DAILY_SPECIALS_API_ENDPOINT, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
          'ngrok-skip-browser-warning': 'any',
        },
      } );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;
        return resData;
      } else
      {
        return rejectWithValue( "Can't Get Daily Specials" );
      }
    } catch ( error )
    {
      if (error.response.status === 404) {
        console.log( 404 );
        return [];
      }
      console.log( 'ERROR', error );
      throw error;
    }
  },
);
export const CreateDailySpecials = createAsyncThunk(
  `CreateDailySpecials`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.post(
        DAILY_SPECIALS_API_ENDPOINT,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant'Create Daily Special" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  },
);
export const DeleteDailySpecials = createAsyncThunk(
  `DeleteDailySpecials`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.delete(
        `${DAILY_SPECIALS_API_ENDPOINT}/${data.daily_special_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant Delete Daily Special" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  },
);

export const ActivateDailySpecials = createAsyncThunk(
  `ActivateDailySpecials`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.put(
        `${DAILY_SPECIALS_API_ENDPOINT}/${data.daily_special_id}/activate`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      // console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant Activate Daily Special" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  }
);

export const DeactivateDailySpecials = createAsyncThunk(
  `DeactivateDailySpecials`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.put(
        `${DAILY_SPECIALS_API_ENDPOINT}/${data.daily_special_id}/deactivate`,
        {}, 
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      // console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant Deactivate Daily Special" );
      }
    }
    catch ( error )
    {
      console.log( error );
      throw error;
    }
  }
);
export const UpdateDailySpecials = createAsyncThunk(
  `UpdateDailySpecials`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.put(
        `${DAILY_SPECIALS_API_ENDPOINT}/${data.daily_special_id}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant Update Daily Special" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  }
);