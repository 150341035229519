/** @format */

import React, { useState } from "react";
import { capitalizeFirstLetter } from "utils/functions";
import CartActionButtons from "components/CartActionButtons";
import Customization from "components/Customization";
import { useSelector, useDispatch } from "react-redux";
import { addOrRemoveAddonFromItem, toggleCustomizationForItem, clearCustomization } from "store/reducer/ManualOrderReducer";
import { getCurrency } from "utils/functions";

export default function CartItem({ item, onClickActionButtons }) {
   const { Menu } = useSelector((state) => state.MenuReducer);
   const { Cart } = useSelector((state) => state.ManualOrderReducer);
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);
   const dispatch = useDispatch();
   const { item_name, count, item_id, category_id, price, total_price } = item;

   const [showAddon, setshowAddon] = useState(false);
   const [showCustomization, setshowCustomization] = useState(false);

   const toggleAddon = () => setshowAddon(!showAddon);
   const toggleCustomization = () => setshowCustomization(!showCustomization);

   console.log("CART ITEM", Cart);

   const getAddons = () => {
      const allItems = Menu.filter((category) => category.id === category_id)[0].items;
      const currentItem = allItems.filter((Item) => Item.item_id == item_id)[0];
      console.log(currentItem, "getAddons");
      return currentItem?.add_ons;
   };

   const getCustomizations = () => {
      const allItems = Menu.filter((category) => category.id === category_id)[0].items;
      const currentItem = allItems.filter((Item) => Item.item_id == item_id)[0];
      console.log(currentItem, "getAddons");
      return currentItem?.customizations;
   };

   const getCurrentItemFromCart = () => {
      const cartItemIndex = Cart.findIndex((Item) => Item.item_id === item_id);
      return Cart[cartItemIndex];
   };

   const getAddonCount = (add_on) => {
      const cartItem = getCurrentItemFromCart();
      if (cartItem?.addons.length > 0) {
         const addonItemIndex = cartItem?.addons.findIndex((addon) => addon.add_on_id === add_on.add_on_id);
         console.log(cartItem?.addons[addonItemIndex], "getAddonCount", addonItemIndex);
         if (addonItemIndex !== -1) {
            return cartItem.addons[addonItemIndex].count;
         }
         return 0;
      }
      return 0;
   };

   const onClickAddonActionButtons = (type, add_on) => {
      const payload = {
         item_id,
         add_on,
         type,
      };
      dispatch(addOrRemoveAddonFromItem(payload));
   };

   const updateCustomizationInCartItem = (customization, option) => {
      const payload = {
         item_id,
         option,
         ...customization,
      };
      dispatch(toggleCustomizationForItem(payload));
   };

   const clearCustomizationForItem = (customisation_id) => {
      const payload = {
         item_id,
         customisation_id,
      };
      dispatch(clearCustomization(payload));
   };

   return (
      <div className="w-full py-1 px-4">
         <div className="flex items-center justify-between">
            <div className="font-bold">{capitalizeFirstLetter(item_name)}</div>
            <div className="flex gap-2">
               <div>
                  {getCurrency(merchantDetails?.currency)}
                  {price}
               </div>
               <CartActionButtons
                  quantity={count}
                  onClickActionButtons={onClickActionButtons}
               />
            </div>
         </div>
         <div className="ml-2">
            {getAddons()?.length > 0 && (
               <>
                  <div
                     className="cursor-pointer text-[#4361EE] hover:underline"
                     onClick={toggleAddon}>
                     Add ons {getCurrentItemFromCart()?.addons.length > 0 && `(${getCurrentItemFromCart()?.addons.length})`}
                  </div>
                  {showAddon && (
                     <div className="transition ease-in-out flex flex-col gap-2 mt-2 mb-2">
                        {getAddons().map((item) => {
                           return (
                              <div className="flex items-center justify-between">
                                 <div>{capitalizeFirstLetter(item.item_name)}</div>
                                 <div className="flex gap-2">
                                    <div>
                                       {getCurrency(merchantDetails?.currency)}
                                       {item.price}
                                    </div>
                                    <CartActionButtons
                                       quantity={getAddonCount(item)}
                                       onClickActionButtons={(type) => onClickAddonActionButtons(type, item)}
                                    />
                                 </div>
                              </div>
                           );
                        })}
                     </div>
                  )}
               </>
            )}
            {getCustomizations()?.length > 0 && (
               <>
                  <div
                     className="cursor-pointer text-[#4361EE] hover:underline"
                     onClick={toggleCustomization}>
                     Customizations {getCurrentItemFromCart()?.customizations.length > 0 && `(${getCurrentItemFromCart()?.customizations.length})`}
                  </div>
                  {showCustomization && (
                     <div className="flex flex-col gap-1 mt-2">
                        <Customization
                           updateCustomizationInCartItem={updateCustomizationInCartItem}
                           customizations={getCustomizations()}
                           clearCustomizationForItem={clearCustomizationForItem}
                           selectedCustomizations={getCurrentItemFromCart().customizations}
                        />
                     </div>
                  )}
               </>
            )}
         </div>
      </div>
   );
}
