import { createSlice } from '@reduxjs/toolkit';
import { CreateRecommendedItems, GetRecommendedItems } from 'store/action/RecommendedAction';

const RecommendedReducer = createSlice({
  name: 'Recommended',
  initialState: {
    recommendedItems: { recommended_items: [] },
    allItems: [],
  },
  reducers: {
    updateData: (state, { payload }) => {
      state.allItems = payload;
    },
  },
  extraReducers: {
    [GetRecommendedItems.fulfilled]: (state, { payload }) => {
      state.recommendedItems.recommended_items = payload?.recommended_items;
    },
    [CreateRecommendedItems.fulfilled]: (state, { payload }) => {
      state.recommendedItems.recommended_items = payload.item_ids;
    },
  },
});
export const { updateData } = RecommendedReducer.actions;
export default RecommendedReducer.reducer;
