import React from "react";

function RightArrow({ open }) {
  return (
    <svg
      style={{
        transform: open ? "rotate(90deg)" : "rotate(0deg)",
        transition: "all 0.3s ease-in-out",
      }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.19893 4.35779C7.79047 4.86745 7.79047 5.59233 8.19893 6.102L12.174 11.0619C12.6133 11.6101 12.6133 12.3899 12.174 12.9381L8.19893 17.898C7.79047 18.4077 7.79047 19.1325 8.19893 19.6422C8.75718 20.3388 9.81704 20.3388 10.3753 19.6422L15.7482 12.9381C16.1876 12.3899 16.1876 11.6101 15.7482 11.0619L10.3753 4.35779C9.81704 3.66123 8.75718 3.66123 8.19893 4.35779Z"
        fill="#0F191A"
      />
    </svg>
  );
}

export default RightArrow;
