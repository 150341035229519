/** @format */

import React from "react";

const UnlockIcon = () => {
   return (
      <svg
         width="105"
         height="70"
         viewBox="0 0 70 96"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
         <path
            d="M35.9748 0.448339C23.9998 0.448339 13.9488 8.56497 10.9473 19.5871L20.8139 22.0317C21.8077 18.394 24.2303 15.5273 27.3731 13.4432C30.5159 11.3591 34.3081 10.4862 38.0458 10.9864C41.7835 11.4866 45.2127 13.3259 47.6969 16.163C50.1811 19.0001 51.5516 22.6423 51.5538 26.4133V52.3782H61.9398V26.4133C61.9398 12.0728 50.3153 0.448339 35.9748 0.448339Z"
            fill="#BCBEC0"
         />
         <path
            d="M71.9982 75.2021C71.9982 78.1096 70.7421 80.8981 68.5062 82.9541C66.2704 85.01 63.2379 86.165 60.076 86.165H12.3871C9.2251 86.165 6.19263 85.01 3.95678 82.9541C1.72093 80.8981 0.464844 78.1096 0.464844 75.2021V47.7947C0.464844 44.8871 1.72093 42.0986 3.95678 40.0427C6.19263 37.9867 9.2251 36.8317 12.3871 36.8317H60.076C63.2379 36.8317 66.2704 37.9867 68.5062 40.0427C70.7421 42.0986 71.9982 44.8871 71.9982 47.7947V75.2021Z"
            fill="#FFAC33"
         />
      </svg>
   );
};

export default UnlockIcon;
