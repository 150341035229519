/** @format */

import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import Type1SVG from "assets/icons/qrCodeDesgins/Type1SVG";
import Type2SVG from "assets/icons/qrCodeDesgins/Type2SVG";
import Type3SVG from "assets/icons/qrCodeDesgins/Type3SVG";
import Type4SVG from "assets/icons/qrCodeDesgins/Type4SVG";
import { FormControl, Select, MenuItem, Snackbar, Alert, Tooltip } from "@mui/material";
import { Button, TextField } from "@mui/material";
import EditIcon from "assets/icons/EditIcon";
import { jsPDF } from "jspdf";
import { CreateQRCode, ModifyQRCode, GetAllQrCodes } from "store/action/QRActions";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import Loader from "components/Loader";
import Type1SVGL from "assets/icons/qrCodeDesgins/Type1SVGL";
import Type2SVGL from "assets/icons/qrCodeDesgins/Type2SVGL";
import Type3SVGL from "assets/icons/qrCodeDesgins/Type3SVGL";
import Type4SVGL from "assets/icons/qrCodeDesgins/Type4SVGL";
import CreateIcon from "@mui/icons-material/Create";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DialogBox } from "components/DialogBox";
import { DeleteModal } from "components/DeleteModal";

export const colours = [
   "linear-gradient( 90deg, #2e6d4f 25%, #058944 106.97%)",
   "linear-gradient( 90deg, #f15a29 25%, #f7941d 106.97%)",
   "linear-gradient( 90deg, #ef4136 25%, #be1e2d 106.97%)",
   "linear-gradient( 90deg, #c02033 25%, #7f133a 106.97%)",
];

export default function Tables({ data, handleDelete }) {
   const [renderIndex, setrenderIndex] = useState(0);
   const [renderOrientation, setrenderOrientation] = useState("portrait");
   const [tableIdentifierEdit, setTableIdentifierEdit] = useState(-1);
   const [createQrModal, setCreateQrModal] = useState(false);
   const [loading, setLoading] = useState(false);
   const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [isVisible, setVisible] = useState(false);
   const [Id, setId] = useState("");

   const dispatch = useDispatch();

   useEffect(() => { }, [renderIndex]);

   const downloadAllQrCodesAsPdf = async () => {
      const doc = new jsPDF({
         orientation: "p",
         unit: "mm",
         format: "a4",
      });

      const qrCodeElements = document.querySelectorAll(".qr-codes-container .qr-codes");
      const qrCodesPerRow = 2;
      const qrCodesPerColumn = 2;
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const margin = 10; // margin on all sides
      const spacing = 5; // spacing between QR codes

      // Calculate the available space for QR codes, accounting for margins and spacings
      const qrCodeWidth = (pageWidth - margin * 2 - spacing) / qrCodesPerRow;
      const qrCodeHeight = (pageHeight - margin * 2 - spacing) / qrCodesPerColumn;

      // Ensure the QR code is square by taking the min of width and height
      const qrCodeSize = Math.min(qrCodeWidth, 500);

      let currentRow = 0;
      let currentColumn = 0;

      for (let i = 0; i < qrCodeElements.length; i++) {
         // Calculate position for the QR code on the PDF
         const x = margin + currentColumn * (qrCodeSize + spacing);
         const y = margin + currentRow * (qrCodeSize + spacing);

         // Render QR code onto canvas
         const canvas = await html2canvas(qrCodeElements[i]);
         const imgData = canvas.toDataURL("image/png");

         // Add image to document, centered in its space
         doc.addImage(imgData, "PNG", x, y, qrCodeSize, qrCodeSize);

         // Move to the next column, and if at the end, move to the next row
         currentColumn++;
         if (currentColumn >= qrCodesPerColumn) {
            currentColumn = 0;
            currentRow++;
         }

         // Add a new page if we've finished a row and there are more QR codes to process
         if (currentRow >= qrCodesPerRow && i < qrCodeElements.length - 1) {
            doc.addPage();
            currentRow = 0;
         }
      }

      // Save the PDF
      doc.save("qr_codes.pdf");
   };

   const handleContinue = async () => {
      setLoading(true);
      await dispatch(
         CreateQRCode({
            table_count: document.getElementById("count").value,
            qr_code_type: "dine-in",
         }),
      );
      setCreateQrModal(false);
      setLoading(false);
   };
   console.log("DATA", data);
   const exportAsImage = async (index, imageFileName = "image.png") => {
      setLoading(true);
      setTimeout(async () => {
         const el = document.getElementById(`qr-codes-${index}`);
         const canvas = await html2canvas(el, {
            scale: 3,
            ignoreElements: (element) => {
               return element.classList.contains("qr-buttons");
            },
         });
         const pngUrl = canvas.toDataURL("image/png");
         const downloadLink = document.createElement("a");
         downloadLink.download = imageFileName;
         downloadLink.href = pngUrl;
         downloadLink.click();
         setLoading(false);
      }, 100);
   };

   const handleCopy = (index) => {
      navigator.clipboard.writeText(data[index].customer_url);
      setSnackbarOpen(true);
   };
   const handleIdentifierEnter = async (e) => {
      setLoading(true);
      await dispatch(
         ModifyQRCode({
            qr_table_identifier: e.target.value,
            qr_code_table_number: data[tableIdentifierEdit].table_number,
            qr_code_type: "dine-in",
            qr_code_id: data[tableIdentifierEdit].qr_code_id,
         }),
      );
      await dispatch(GetAllQrCodes());
      setTableIdentifierEdit(-1);
      setLoading(false);
   };

   return (
      <div>
         <Loader isLoading={loading} />
         <DialogBox
            title="Create QR Code"
            open={createQrModal}
            handleClose={() => {
               setCreateQrModal(false);
            }}
            handleContinue={handleContinue}
            content={
               <TextField
                  id="count"
                  label="Table Count"
                  name="Table Count"
                  inputProps={{ min: 0 }}
                  onWheel={(e) => e.target.blur()}
                  autoComplete="off"
                  type="number"
                  sx={{ height: "100%", marginY: "10px", width: "100%" }}
               />
            }
         />
         {/* <Button
            variant="outlined"
            onClick={downloadAllQrCodesAsPdf}
            className="download-all-qr">
            Download all qr codes
         </Button> */}
         <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}>
            <Alert
               onClose={() => setSnackbarOpen(false)}
               severity="success"
               sx={{ width: "100%" }}>
               URL copied to clipboard!
            </Alert>
         </Snackbar>
         <div className="tables-topbar">
            <div className="tables-topbar-1">
               {data?.length > 0 && (
                  <>
                     <FormControl size="small">
                        <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={renderIndex}
                           onChange={(e) => {
                              setrenderIndex(e.target.value);
                           }}
                           sx={{
                              marginRight: "1rem",
                              borderRadius: "8px",
                           }}>
                           {colours.map((item, index) => {
                              return (
                                 <MenuItem value={index}>
                                    <div
                                       className="palette-sm"
                                       style={{
                                          background: item,
                                          opacity: renderIndex === index ? 1 : 0.5,
                                          margin: "0",
                                       }}></div>
                                 </MenuItem>
                              );
                           })}
                        </Select>
                     </FormControl>

                     <FormControl size="small">
                        <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={renderOrientation}
                           onChange={(e) => {
                              setrenderOrientation(e.target.value);
                           }}
                           className="green"
                           sx={{
                              borderRadius: "8px",
                           }}>
                           <MenuItem
                              value="portrait"
                              className="green">
                              Portrait
                           </MenuItem>
                           <MenuItem
                              value="landscape"
                              className="green">
                              Landscape
                           </MenuItem>
                        </Select>
                     </FormControl>
                  </>
               )}
            </div>
            <div>
               {data?.length > 0 && (
                  <Button
                     variant="outlined"
                     sx={{
                        marginRight: "1rem",
                        borderRadius: "8px",
                        textTransform: "none",
                     }}
                     onClick={() => {
                        setCreateQrModal(true);
                     }}>
                     Add tables
                  </Button>
               )}

               {/* {
                        data.length > 0 && (
                            <Button variant='outlined' color='secondary' sx={{ borderRadius: '8px' }} >
                                Download all QR
                            </Button>
                        )
                    } */}
            </div>
         </div>
         <DeleteModal
            onClose={setVisible}
            handleDelete={() => {
               if (Id !== "" && Id !== undefined) {
                  handleDelete(Id);
               }
               setVisible(false);
            }}
            message={"Are you sure you want to delete this QR ?"}
            isVisible={isVisible}
         />
         <div className="qr-codes-container">
            {data?.length > 0 ? (
               data?.map((item, index) => {
                  return (
                     <>
                        <div
                           className="qr-codes-wrapper"
                           style={{
                              width: renderOrientation === "landscape" ? "22rem" : "15rem",
                           }}>
                           <div>
                              <span className="grey">Table no: </span>
                              {item?.table_number}
                           </div>
                           <div
                              className="qr-codes"
                              id={`qr-codes-${index}`}>
                              <div className="qr-buttons">
                                 <Button
                                    variant="contained"
                                    color="black"
                                    className="qr-button"
                                    onClick={() => {
                                       exportAsImage(index, `Table-${item?.table_number}-${item?.qr_code_identifier}`);
                                    }}
                                    sx={{
                                       backgroundColor: "#fff",
                                       borderRadius: "8px",
                                    }}>
                                    Download
                                 </Button>
                                 <Button
                                    variant="contained"
                                    color="black"
                                    className="qr-button"
                                    onClick={() => {
                                       handleCopy(index);
                                    }}
                                    sx={{
                                       backgroundColor: "#fff",
                                       borderRadius: "8px",
                                    }}>
                                    Copy link
                                 </Button>
                              </div>

                              {renderIndex === 0 && (renderOrientation === "landscape" ? <Type1SVGL url={item?.s3_path} /> : <Type1SVG url={item?.s3_path} />)}
                              {renderIndex === 1 && (renderOrientation === "landscape" ? <Type2SVGL url={item?.s3_path} /> : <Type2SVG url={item?.s3_path} />)}
                              {renderIndex === 2 && (renderOrientation === "landscape" ? <Type3SVGL url={item?.s3_path} /> : <Type3SVG url={item?.s3_path} />)}
                              {renderIndex === 3 && (renderOrientation === "landscape" ? <Type4SVGL url={item?.s3_path} /> : <Type4SVG url={item?.s3_path} />)}
                           </div>
                           <div className="bottom-border smaller">
                              <span
                                 className="grey"
                                 style={{ wordBreak: "keep-all" }}>
                                 Table identifier:{" "}
                              </span>
                              {tableIdentifierEdit === index ? (
                                 <input
                                    type="text"
                                    defaultValue={item?.qr_code_identifier}
                                    autoFocus
                                    className="table-identifier-input"
                                    onChange={(e) => {
                                       item.qr_code_identifier = e.target.value;
                                    }}
                                    onKeyDown={(e) => {
                                       if (e.key === "Enter") {
                                          handleIdentifierEnter(e);
                                       }
                                    }}
                                    style={{
                                       flexGrow: 0,
                                       maxWidth: "50%",
                                    }}
                                 />
                              ) : (
                                 item?.qr_code_identifier || "None"
                              )}
                              <span
                                 style={{
                                    zIndex: 1,
                                    marginLeft: "auto",
                                    cursor: "pointer",
                                 }}
                                 onMouseEnter={() => {
                                    return <Tooltip color="primary">Delete</Tooltip>;
                                 }}
                                 onClick={() => {
                                    setId(item?.qr_code_id);
                                    setVisible(true);
                                 }}>
                                 <DeleteOutlineOutlinedIcon
                                    sx={{
                                       color: "red",
                                    }}
                                    fontSize="1.5rem"
                                 />
                              </span>
                              {tableIdentifierEdit === index ? (
                                 ""
                              ) : (
                                 <span
                                    style={{
                                       zIndex: 1,
                                       cursor: "pointer",
                                    }}
                                    onClick={() => {
                                       setTableIdentifierEdit(index);
                                    }}>
                                    <CreateIcon
                                       color="primary"
                                       fontSize="1.5rem"
                                    />
                                 </span>
                              )}
                           </div>
                        </div>
                     </>
                  );
               })
            ) : (
               <div className="flex items-center justify-center h-[60vh]">
                  <div className="flex flex-col items-center justify-center gap-2">
                     <div>No tables found, create QR code for tables by clicking "Add tables"</div>
                     <Button
                        variant="outlined"
                        sx={{
                           marginRight: "1rem",
                           borderRadius: "8px",
                           textTransform: "none",
                        }}
                        onClick={() => {
                           setCreateQrModal(true);
                        }}>
                        Add tables
                     </Button>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
}
