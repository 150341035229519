import React, {useState, useEffect} from 'react';
import './index.scss';

const RefreshingCounter = ({action, duration}) => {
    const [counter, setCounter] = useState(duration);
    useEffect(() => {
        const interval = setInterval(() => {
        if (counter > 0) {
            setCounter(counter - 1);
        } else {
            setCounter(duration);
            action();
        }
        }, 1000);
        return () => clearInterval(interval);
    }, [counter]);

    return (
        <>
        <span className='refresh' onClick={() => {action(); setCounter(duration);}}>
              Refresh&nbsp;&nbsp;
        </span>
        <span style={{textTransform: 'none'}}>
            {'(Refreshing in ' + counter + ' seconds)'}
        </span>
        </>
    );
    }

export default RefreshingCounter;