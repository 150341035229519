/** @format */

import React from "react";

function DeleteIcon({ color, sx }) {
   return (
      <svg
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         style={{ ...sx }}>
         <path
            d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
            stroke={color ? color : "white"}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
            stroke={color ? color : "white"}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M18.8504 9.14001L18.2004 19.21C18.0904 20.78 18.0004 22 15.2104 22H8.79039C6.00039 22 5.91039 20.78 5.80039 19.21L5.15039 9.14001"
            stroke={color ? color : "white"}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M10.3301 16.5H13.6601"
            stroke={color ? color : "white"}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M9.5 12.5H14.5"
            stroke={color ? color : "white"}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
      </svg>
   );
}

export default DeleteIcon;
