/** @format */

import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import logo from "assets/logo/logo.png";
import Input from "components/FormikInput";
import Button2 from "components/Button2";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CountryData, getStatesForCountry, getCountryCode } from "assets/data/Countries";
import * as MerchantAction from "store/action/MerchantAction";
import vector1 from "assets/vectors/onboardingVector1.svg";
import vector2 from "assets/vectors/onboardingVector2.svg";
import Loader from "components/Loader";
import countryToCurrency from "country-to-currency";
import getSymbolFromCurrency from "currency-symbol-map";
import Select from "components/Select";

const Index = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const form = useRef();

   const validationSchema = yup.object({
      restaurant_name: yup.string().required("Restaurant name is required"),
      phone_number: yup.number("Enter a valid phone number").required("Phone number is required"),
      address: yup.string().required("Address is required"),
      country: yup.string().required("Country is required"),
      pincode: yup.string().required("Pincode is required"),
   });

   const [merchantId, setmerchantId] = useState("");
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);
   const [initialValues, setinitialValues] = useState({
      restaurant_name: "",
      phone_number: "",
      address: "",
      country: "",
      pincode: "",
   });
   const [loading, setloading] = useState(false);

   const onSubmit = async (values) => {
      console.log("ONBOARDING SUBMIT", values.country);
      console.log("ONBOARDING SUBMIT --", getCountryCode(values.country).toUpperCase());
      const payload = {
         ...values,
         country_code: getCountryCode(values.country),
         currency: countryToCurrency[getCountryCode(values.country).toUpperCase()],
      };
      setloading(true);
      await dispatch(MerchantAction.UpdateMerchant(payload));
      setloading(false);
      navigate("/menu");
   };

   const getMerchantDetails = async () => {
      setloading(true);
      const result = await dispatch(MerchantAction.GetMerchant());
      console.log("RESULT", result);
      if (result.type === "GetAllOrders/fulfilled") {
         setinitialValues({
            restaurant_name: "",
            phone_number: "",
            address: "",
            country: "",
            pincode: "",
         });
      }
      setloading(false);
   };

   useEffect(() => {
      getMerchantDetails();
   }, []);

   console.log("initialValues", initialValues);
   const getCountries = () => {
      const countries = [];
      CountryData.map((country) => {
         let payload = {
            ...country,
            symbol: getSymbolFromCurrency(countryToCurrency[getCountryCode(country.name).toUpperCase()]),
         };
         countries.push(payload);
      });
      return countries;
   };

   return (
      <div className="loginContainer">
         <div className="loginScreenContainer">
            <div className="logoAndNameContainer">
               <img
                  className="logo"
                  src={logo}
               />
               <div className="logoNameText">The Beautiful Menu</div>
            </div>
            <Loader isLoading={loading} />
            <div className="vector1">
               <img src={vector1} />
            </div>
            <div className="vector2">
               <img
                  src={vector2}
                  className="vector2"
               />
            </div>
            <Formik
               innerRef={form}
               initialValues={initialValues}
               onSubmit={onSubmit}
               validationSchema={validationSchema}
               enableReinitialize={true}>
               {({ setFieldValue, values }) => {
                  return (
                     <div className="onBoardingWrapper">
                        <Form className="onBoardingMainContainer">
                           <div>
                              <div className="headerText">Tell us about yourself</div>
                              <div className="loginInputWrapper">
                                 <Input
                                    label={"Restaurant name"}
                                    placeholder="Enter your restaurant name"
                                    name="restaurant_name"
                                    required={true}
                                 />
                                 <div className="space" />
                                 <Input
                                    type="number"
                                    label={"Phone number"}
                                    placeholder="Enter your phone number"
                                    name="phone_number"
                                    required={true}
                                 />
                                 <div className="space" />
                                 <Input
                                    required={true}
                                    label={"Restaurant address"}
                                    placeholder="Enter your restaurant address"
                                    name="address"
                                 />
                                 <div className="space" />
                                 <Input
                                    required={true}
                                    label={"Country"}
                                    placeholder="Select your country"
                                    name="country"
                                    selectOnClick={(e) => {
                                       let value = e.target.value.split("(")[0];
                                       console.log("VALUE", value);
                                       setFieldValue("country", value.trim());
                                    }}
                                    Select={true}
                                    Options={getCountries()}
                                 />

                                 <div className="space" />
                                 <Input
                                    required={true}
                                    label={"Pincode"}
                                    placeholder="Enter your pincode"
                                    name="pincode"
                                 />
                              </div>
                           </div>
                           <div style={{ marginTop: "2rem" }} />
                           <Button2
                              color="#fff"
                              background="#2D6B4D"
                              border="none"
                              type="submit"
                              width={"100%"}
                              title={"Continue"}
                           />
                        </Form>
                     </div>
                  );
               }}
            </Formik>
         </div>
      </div>
   );
};

export default Index;
