/** @format */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
const MERCHANT_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/`;

export const GetMerchant = createAsyncThunk(
  `GetMerchant`,
  async (data, { rejectWithValue }) => {
    console.log("Get Merchant Action");
    try {
      const response = await fetch(MERCHANT_API_ENDPOINT, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("savedT")}`,
          "ngrok-skip-browser-warning": "any",
        },
      });
      console.log("Get Merchant Response ", response);
      const resData = await response.json();
      console.log(resData);
      if (response.status == 201 || response.status == 200) {
        return resData;
      } else {
        return rejectWithValue("Can't  fetch the merchantDetails");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const UpdateMerchant = createAsyncThunk(
  `UpdateMerchant`,
  async (data, { rejectWithValue }) => {
    console.log("UpdateMerchant", data);
    try {
      const response = await axios.put(MERCHANT_API_ENDPOINT, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("savedT")}`,
          "ngrok-skip-browser-warning": "any",
        },
      });
      if (response.status === 200 || response.status === 201) {
        const resData = await response.data;
        console.log("SUCCESSFULLY UPDATED MERCHANT DETAILS", resData);
        return resData;
      } else {
        return rejectWithValue("Can't Update Merchant Details");
      }
    } catch (error) {
      throw error;
    }
  }
);

// export const updateAcceptOrder = createAsyncThunk(
//   `updateAcceptOrder`,
//   async (data, { rejectWithValue }) => {
//     console.log("UpdateMerchant", data);
//     try {
//       const response = axios.put(
//         `${MERCHANT_API_ENDPOINT}accept_order_status/${data}`,
//         {
//           method: "Put",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${localStorage.getItem("savedT")}`,
//             "ngrok-skip-browser-warning": "any",
//           },
//         }
//       );
//       console.log("Update Accept Order Response", response);
//       const resData = await response.json();
//       console.log(resData);
//       if (response.status == 201 || response.status == 200) {
//         return resData;
//       } else {
//         return rejectWithValue("Can't  fetch the merchantDetails");
//       }
//     } catch (error) {
//       throw error;
//     }
//   }
// );
