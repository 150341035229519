/** @format */

import CategoryItemCard from "components/CategoryItem";
import FilterButton from "components/FilterButton";
import FoodItemCard from "components/FoodItem/index";
import NoItems from "components/NoItems";
import RecommendedItemAdded from "components/RecommendedItemAdded";
import Search from "components/Search";
import Layout from "layouts";
import Button from "components/Button2";
import * as MenuAction from "store/action/MenuAction";
import React, { useEffect, useState } from "react";
import "./style.scss";
import TickIcon from "assets/icons/TickIcon";
import { useDispatch, useSelector } from "react-redux";
import * as BestSellingAction from "store/action/BestSellingAction";
import * as MenuActions from "store/action/MenuAction";
import Loader from "components/Loader";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import toast from "react-hot-toast";

function BestSelling() {
   const allItems = useSelector((state) => state.MenuReducer.Menu);
   const [loading, setloading] = useState(false);
   const [searchVal, setSearchVal] = useState([]);
   const [searchResults, setSearchResults] = useState([]);
   const [searchItemResults, setSearchItemResults] = useState([]);
   const [select, setSelect] = useState("");
   const [removedItems, setRemovedItems] = useState([]);
   const BestSellingItems = useSelector((state) => state?.BestSellingReducer?.bestSellingItems?.best_selling_items);
   const [addedItems, setaddedItems] = useState(BestSellingItems);
   const [itemsOrderChanged, setItemsOrderChanged] = useState(false);

   const FETCH_ITEMS = async (id) => {
      await allItems
         ?.filter((item) => item.id === id)
         .map((i) => {
            setSelect(i.category_name);
            localStorage.setItem("categoryRecommendedId", id);
         });
   };

   function removeDuplicates(arr) {
      let unique = [];
      for (const i in arr) {
         if (unique.indexOf(arr[i]) === -1) {
            unique.push(arr[i]);
         }
      }
      return unique;
   }

   const searchMenu = (input) => {
      let temp = [];
      let item = [];
      setSearchVal(input);
      if (input != "") {
         for (const i in allItems) {
            for (const j in allItems[i].items)
               if (allItems[i].category_name.toLowerCase().includes(input.toLowerCase()) || allItems[i].items[j].item_name.toLowerCase().includes(input.toLowerCase())) {
                  temp.push(allItems[i]);
                  item.push(allItems[i].items[j]);
                  console.log("Searched Item", item);
               }
         }
         if (temp.length > 0 || item.length > 0) {
            setSearchItemResults(removeDuplicates(item));
            setSearchResults(removeDuplicates(temp));
         } else {
            setSearchResults([]);
            setSearchItemResults([]);
         }
      }
   };
   const catId = localStorage.getItem("categoryRecommendedId");
   const FoodItems = allItems.filter((i) => i.id === catId).map((i) => i.items);
   const dispatch = useDispatch();
   const FETCH_ALL_ITEMS = async () => {
      setloading(true);
      await dispatch(MenuActions.GetMenu());
      setloading(false);
   };

   const FETCH_BEST_SELLING_ITEMS = async () => {
      setloading(true);
      const result = await dispatch(BestSellingAction.GetBestSellingItems());
      setaddedItems(result?.payload.best_selling_items);
      console.log("FETCH_BEST_SELLING_ITEMS", result);
      setloading(false);
   };

   useEffect(() => {
      FETCH_BEST_SELLING_ITEMS();
      FETCH_ALL_ITEMS();
   }, []);

   useEffect(() => {
      FETCH_ITEMS(allItems[0]?.id);
   }, [allItems]);

   //AddItemsFunction
   const addOrRemoveItem = (item) => {
      const findIfExist = addedItems.findIndex((i) => item.item_id === i.item_id);
      const item_to_be_removed = [...removedItems];

      if (findIfExist != -1) {
         const index = addedItems.findIndex((i) => i.item_id === item.item_id);
         const existing = [...addedItems];
         existing.splice(index, 1);
         setaddedItems(existing);

         if (BestSellingItems?.length > 0) {
            item_to_be_removed?.push(item);
            setRemovedItems(item_to_be_removed);
         }
         console.log("ADDED_ITEMS ==>", addedItems);
         console.log("REMOVED_ITEMS ==>", removedItems);
      } else {
         if (addedItems.length < 5) {
            setaddedItems([...addedItems, item]);
         } else {
            alert("You cannot add more than 5 items");
         }
      }
   };
   const onDragEnd = async (result) => {
      const copyListItems = [...addedItems];
      const dragItemContent = copyListItems[result.source.index];
      copyListItems.splice(result.source.index, 1);
      copyListItems.splice(result.destination.index, 0, dragItemContent);
      result.source.index = null;
      result.destination.index = null;
      setaddedItems(copyListItems);
      setItemsOrderChanged(true);
   };
   const handleConfirm = async () => {
      setloading(true);

      if (itemsOrderChanged) {
         await dispatch(
            MenuAction.DragAndDrop({
               items_array: addedItems.map((e, i) => {
                  return {
                     item_id: e.item_id,
                     item_idx: i,
                  };
               }),
               source: "best_selling_items",
            }),
         );
         setItemsOrderChanged(false);
      }

      if (addedItems.length >= 0) {
         const result = addedItems.map((e) => {
            return {
               category_id: e.category_id,
               description: e.description,
               discount: e.discount,
               image: e.image,
               item_id: e.item_id,
               item_name: e.item_name,
               merchant_id: e.merchant_id,
               out_of_stock: e.out_of_stock,
               price: e.price,
               price_after_discount: e.price_after_discount,
               type: e.type,
               updated_on: e.updated_on,
               added: "True",
               item_idx: addedItems.indexOf(e),
            };
         });
         console.log("CREATE_RECOMMENDED_ITEMS");
         await dispatch(BestSellingAction.CreateBestSellingItems(result));
         console.log("ADDED RECOMMENDED_ITEMS", result);
      }
      if (removedItems > 0) {
         let existingItems = [...BestSellingItems];
         const remove_result = existingItems?.map((e) => {
            for (const key in removedItems) {
               if (removedItems[key]?.item_id === e.item_id) {
                  let object = { ...e };
                  delete object["added"];
                  return { ...object, removed: "True" };
               } else {
                  return e;
               }
            }
         });
         const request = await dispatch(BestSellingAction.CreateBestSellingItems(remove_result));
         console.log("====================================");
         console.log("Removed BestSelling Items", request);
         console.log("====================================");
      }
      setRemovedItems([]);
      setloading(false);
      toast.success("Items updated successfully");
   };

   const getCategoryTitle = (item) => allItems.filter((i) => i.id === item.category_id).map((i) => i.category_name);

   return (
      <Layout>
         <Loader isLoading={loading} />
         <div className="recommended-container">
            <div className="recommended-container-heading">
               <div className="recommended-heading-text1">Best selling items</div>
               <div className="recommended-heading-text2">(Select items to add in best selling items list)</div>
            </div>

            <div className="recommended-content-container">
               <div className="recommended-categories-added-items-search-wrapper">
                  <Search
                     onChange={(e) => {
                        searchMenu(e.target.value);
                     }}
                     border={"1px solid rgba(0,0,0,0.3)"}
                     InputHeight={"3rem"}
                     padding={"0.8rem"}
                     width={"100%"}
                     placeholder={"Search in menu"}
                  />

                  <div className="recommended-categoies-added-items-main-container">
                     <div className="recommended-categories-container">
                        <div className="recommended-category-items-wrapper">
                           {searchVal != ""
                              ? searchResults?.map((item, index) => (
                                   <CategoryItemCard
                                      item={item}
                                      select={select}
                                      name={item.category_name}
                                      filter={FETCH_ITEMS}
                                      key={index}
                                   />
                                ))
                              : allItems?.map((item, index) => (
                                   <CategoryItemCard
                                      item={item}
                                      select={select}
                                      name={item.category_name}
                                      filter={FETCH_ITEMS}
                                      key={index}
                                   />
                                ))}
                        </div>
                     </div>
                     {
                        <div className="recommended-items-container">
                           <div className="recommended-add-items-wrapper">
                              {searchVal != ""
                                 ? searchItemResults?.map((item, index) => {
                                      if (item.category_id === catId)
                                         return (
                                            <FoodItemCard
                                               item={item}
                                               key={index}
                                               isAdded={addedItems?.findIndex((i) => item.item_id === i.item_id) != -1 ? true : false}
                                               addToRecommended={() => {
                                                  addOrRemoveItem(item);
                                               }}
                                               disabled={addedItems?.length === 5 ? true : false}
                                            />
                                         );
                                   })
                                 : FoodItems[0]?.map((item, index) => (
                                      <FoodItemCard
                                         item={item}
                                         key={index}
                                         isAdded={addedItems?.findIndex((i) => item.item_id === i.item_id) != -1 ? true : false}
                                         disabled={addedItems?.length === 5 ? true : false}
                                         addToRecommended={() => {
                                            addOrRemoveItem(item);
                                         }}
                                      />
                                   ))}
                           </div>
                        </div>
                     }
                  </div>
               </div>
               <div className="recommended-added-items-container">
                  {(addedItems?.length === 5 || BestSellingItems?.length === 5) && <span className="recommended-items-warning">You can add only upto 5 items</span>}

                  <div className="recommended-added-items-container-header">
                     <span className="recommended-added-items-heading-text1">Best Selling Items</span>
                     <span className="recommended-added-items-container-text1">
                        {/* {BestSellingItems.length > 0
                  ? BestSellingItems.length
                  : addedItems.length - removedItems.length} */}
                        {addedItems?.length}
                        /5 Items
                     </span>
                  </div>
                  <DragDropContext onDragEnd={onDragEnd}>
                     <Droppable droppableId="recommended-added-list">
                        {(provided) => (
                           <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className={addedItems?.length === 0 ? "recommended-added-items-added-items-container NoItemsWrapper" : "recommended-added-items-added-items-container"}>
                              {addedItems?.length > 0 ? (
                                 addedItems?.map((item, index) => {
                                    return (
                                       <Draggable
                                          draggableId={item.item_id}
                                          index={index}
                                          key={item.item_id}>
                                          {(provided) => (
                                             <div
                                                style={{
                                                   width: "100%",
                                                }}
                                                className="recommended-item-added-container"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <RecommendedItemAdded
                                                   item={item}
                                                   key={index}
                                                   categoryTitle={getCategoryTitle(item)}
                                                   handleRemove={() => {
                                                      addOrRemoveItem(item);
                                                   }}
                                                />
                                             </div>
                                          )}
                                       </Draggable>
                                    );
                                 })
                              ) : (
                                 <NoItems />
                              )}
                           </div>
                        )}
                     </Droppable>
                  </DragDropContext>

                  <div className="recommended-added-items-bottom-container">
                     <Button
                        Size={"sm"}
                        background={"#2FBF71"}
                        color={"#fff"}
                        title={"Confirm"}
                        icon={<TickIcon />}
                        border={"none"}
                        disabled={addedItems?.length > BestSellingItems?.length || removedItems?.length > 0 || itemsOrderChanged ? false : true}
                        onClick={handleConfirm}
                     />
                  </div>
               </div>
            </div>
         </div>
      </Layout>
   );
}

export default BestSelling;
