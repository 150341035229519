import AnalyticsTable from "../AnalyticsTable";
import BarChart from "../BarChart";
import AnalyticsCard from "components/AnalyticsCard";
import { TabPanel } from "..";

const TabPanelByOrderType = ({ data, ordersTab, orderType, index, recencySelected }) => {
    return (
        <TabPanel value={ordersTab} index={index}>
            <div className='analyticsTotalValues'>
                {
                    Object.keys(data?.item_analytics || {})?.map((key, index) => {
                        if (data?.item_analytics[key]?.type_of_order === orderType && (data?.item_analytics[key]?.recency === recencySelected)) {
                            if (data?.item_analytics[key]?.type === 'tile') {
                                return <AnalyticsCard item={{title: data?.item_analytics[key]?.title, value: data?.item_analytics[key]?.value}} key={index} />
                            }
                        } return '';
                    })
                } 
            </div>
            <div className='analyticsTotalValues'>
                {
                    Object.keys(data?.item_analytics || {})?.map((key, index) => {
                        if (data?.item_analytics[key]?.type_of_order === orderType && (data?.item_analytics[key]?.recency === recencySelected)) {
                            if (data?.item_analytics[key]?.type === 'bar') {
                                return (
                                    <>
                                    <BarChart dets={data?.item_analytics[key]} keys={key} />
                                    {/* <BarChart dets={data?.item_analytics[key]} keys={key} /> */}
                                    </>
                                )
                            }
                        } return '';
                    })
                } 
            </div>

            <div className="analyticsTotalValues">
                {
                    Object.keys(data?.items_analytics || {})?.map((key, index) => {
                        if (data?.items_analytics[key]?.type === 'table' && data?.items_analytics[key]?.recency === recencySelected && data?.items_analytics[key]?.type_of_order === orderType) {
                            return <AnalyticsTable value={data?.items_analytics[key]?.value} keys={key} title={data?.items_analytics[key]?.title} page={'items'} />
                        } return '';
                    })
                }
            </div>
        </TabPanel>

    )
}


const ItemsPage = ({ data, ordersTab, recencySelected }) => {
    return (
        <>
            <TabPanelByOrderType data={data} ordersTab={ordersTab} orderType='Dine-in' index={0} recencySelected={recencySelected} />
            <TabPanelByOrderType data={data} ordersTab={ordersTab} orderType='Take away' index={1} recencySelected={recencySelected} />
            <TabPanelByOrderType data={data} ordersTab={ordersTab} orderType='Delivery' index={2} recencySelected={recencySelected} />
        </>
    )
}

export default ItemsPage;