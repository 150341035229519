/** @format */

import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

// Customized Accordion
const Accordion = styled(MuiAccordion)(({ theme }) => ({
   boxShadow: "none",
   border: "0px",
   padding: 0,
   margin: 0,
   "&:before": {
      display: "none",
   },
   "&.Mui-expanded": {
      margin: "0px",
   },
   // Override transitions here
   transition: "none",
}));

// Customized Accordion Summary
const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
   margin: 0,
   padding: theme.spacing(0),

   "&.Mui-expanded": {
      margin: 0,
   },
   // Override transitions here
   transition: "none",
   "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0px", // Remove additional margin when expanded
   },
}));

// Customized Accordion Details
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
   margin: 0,
   padding: theme.spacing(0),
   "&.Mui-expanded": {
      margin: "0px",
   },
   // Override transitions here
   transition: "none",
}));

export default function CustomAccordion({ header, details }) {
   return (
      <Accordion>
         <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header">
            <div>{header}</div>
         </AccordionSummary>
         <AccordionDetails>{details}</AccordionDetails>
      </Accordion>
   );
}
