/** @format */

import React, { useState } from "react";
import { StockSwitch } from "components/ToggleSwitch";
import Button2 from "components/Button2";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { DeleteItem, EditCategoryItem } from "store/action/MenuAction";
import { DeleteModal } from "components/DeleteModal";
import { Tooltip } from "@mui/material";
import DraggableIndicator from "components/DragableIndicator";
import { getCurrency } from "utils/functions";
import toast from "react-hot-toast";

function MenuItemCard({ item, setVisible, setEdit, setCurrentItem, addCategory, handleDrop, dragEnter, dragStart, index, mobileView, categoryTitle }) {
   const [showMore, setShowMore] = useState(true);
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);
   const [showDeleteModal, setshowDeleteModal] = useState(false);
   const [stock, setStock] = useState(item?.out_of_stock);
   const dispatch = useDispatch();
   console.log(item, "item in menu card");
   function handleDeleteItem() {
      dispatch(DeleteItem({ id: item.item_id }));
      setshowDeleteModal(false);
      toast.success("Item deleted successfully");
   }
   const handleEditItem = () => {
      setEdit(true);
      setVisible((p) => !p);
      setCurrentItem(item);
      console.log(item);
   };

   function handleDescription() {
      setShowMore((p) => !p);
   }

   const handleStock = async () => {
      const body = {
         category_id: item.category_id,
         image_url: item?.image_url,
         item_name: item.item_name,
         price: item.price,
         discount: item.discount,
         price_after_discount: item.price_after_discount,
         type: item.type,
         description: item.description,
         quantity: item.quantity,
         manage_inventory: item?.manage_inventory,
         remaining_quantity: item?.quantity,
         item_idx: item?.item_idx,
      };

      const result = await dispatch(
         EditCategoryItem({
            item_id: item.item_id,
            out_of_stock: !stock,
            ...body,
         }),
      );
      console.log(result, "EditCategoryItem");
      setStock(!stock);
   };

   const getItemImage = () => item.image_url;

   return (
      <>
         <DeleteModal
            message={"Are you sure you want to delete this item?"}
            isVisible={showDeleteModal}
            onClose={setshowDeleteModal}
            handleDelete={handleDeleteItem}
         />
         <div className="MenuItemCardContainer ">
            <span
               className="dragbtn flex items-start h-fit mt-4"
               // style={{ display: "flex", alignItems: "start" }}
            >
               <DraggableIndicator />
            </span>
            <div className="MenuItemImageWrapper mt-1">
               {item?.image_url !== null && item?.image_url !== "" ? (
                  <img
                     className="MenuItemImage"
                     src={`${item?.image_url}?${new Date().getMilliseconds()}`}
                     alt=""
                  />
               ) : (
                  <div className="MenuItemNameLogo">{item?.item_name.charAt(0).toUpperCase()}</div>
               )}
            </div>
            <div className={`MenuItemContentWrapper ${mobileView ? "flex-row" : ""}`}>
               <div className="MenuItemDetails">
                  {mobileView && <span className="font-medium">{categoryTitle}</span>}
                  <Tooltip title={item.item_name}>
                     <div className="ItemName mt-2">{item?.item_name.length > 18 ? item.item_name.slice(0, 14) + "..." : item.item_name}</div>
                  </Tooltip>
                  <div className="ItemPriceContainer">
                     <div className="ItemPrice">
                        {getCurrency(merchantDetails?.currency)} {item?.price}
                     </div>
                     {item?.manage_inventory === true && <div className="ItemRemainingItems"> Remaining quantity : {item?.remaining_quantity}</div>}
                  </div>
                  <div className="ItemDescription">
                     {item.description?.length > 50 ? (
                        <>
                           {showMore ? item.description.substring(0, 35) + "..." : item.description}
                           <br />
                           <span
                              onClick={handleDescription}
                              className="SliceBtn cursor-pointer">
                              {showMore ? "See more" : " See less"}
                           </span>
                        </>
                     ) : (
                        item.description
                     )}
                  </div>
               </div>
               {!addCategory && (
                  <div className="MenuItemBtns">
                     <div className="stockBtn">
                        {!item?.manage_inventory ? (
                           <StockSwitch
                              disabled={item?.manage_inventory === true ? true : false}
                              handleStock={handleStock}
                              isAvailable={!item.out_of_stock}
                           />
                        ) : (
                           <div className="text-[1rem] text-white">ss</div>
                        )}
                     </div>

                     {!mobileView && (
                        <div className="itemCardBtn">
                           <Button2
                              Size="xs"
                              title="Delete"
                              // background={'#FBE6EA'}
                              color={"#D90429"}
                              border="none"
                              onClick={() => setshowDeleteModal(true)}
                           />
                           <Button2
                              Size="xs"
                              title="Edit"
                              // background={'#ECEFFD'}
                              color="#4361EE"
                              border="none"
                              onClick={handleEditItem}
                           />
                        </div>
                     )}
                  </div>
               )}
            </div>
         </div>
      </>
   );
}

export default MenuItemCard;
