import { Modal } from '@mui/material';
import Input from 'components/FormikInput';
import { Form, Formik } from 'formik';
import React from 'react';
import Button from 'components/Button2';
import './index.scss';

function QRInputModal({ initialValues, onSubmit, validationSchema, open, setQRInput, isEdit }) {
  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ setFieldTouched, handleSubmit, validateField, setFieldValue, values }) => {
          return (
            <Form className='QRMainContainer'>
              {isEdit ? (
                <Input
                  label='Enter table identifier'
                  name='table_identifier'
                  placeholder={'Eg: “Group A, T1”'}
                  onChange={(e) => {
                    setFieldValue('table_identifier', e.target.value);
                    // if (e.target.value <= 10) {
                    //   setFieldTouched('table_count', true, true);
                    // }
                  }}
                />
              ) : (
                <Input
                  label='Enter QR code count'
                  name='table_count'
                  type={'number'}
                  placeholder={'Eg: “10”'}
                  onChange={(e) => {
                    setFieldValue('table_count', e.target.value);
                    if (e.target.value <= 10) {
                      setFieldTouched('table_count', true, true);
                    }
                  }}
                />
              )}

              <div className='QRInputModalbtnContainer'>
                <Button
                  title={isEdit ? 'Save' : 'Generate QR code'}
                  Size='md'
                  background={'#2D6B4D'}
                  border='none'
                  color={'#fff'}
                  type={'submit'}
                  disabled={
                    values?.table_identifier !== '' ||
                    (values?.table_count !== '' &&
                      values?.table_count <= 10 &&
                      values?.table_count > 0)
                      ? false
                      : true
                  }
                />{' '}
                <Button
                  title={'Cancel'}
                  Size='md'
                  background={'#D90429'}
                  border='none'
                  color={'#fff'}
                  type={'button'}
                  onClick={() => {
                    setQRInput(false);
                  }}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default QRInputModal;
