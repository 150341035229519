import PlusIcon from 'assets/icons/PlusIcon';
import TickIcon from 'assets/icons/TickIcon';
import React from 'react';
import './index.scss';

function AddButton({ add, onClick, ...props }) {
  const style = {
    opacity: props.disabled ? '0.6' : '2',
  };

  return (
    <button
      style={style}
      disabled={props.disabled}
      className={add ? 'add-btn-container added ' : 'add-btn-container'}
      {...props}
      onClick={onClick}
    >
      {add ? <TickIcon /> : <PlusIcon />}
      {add ? 'Added' : 'Add'}
    </button>
  );
}

export default AddButton;
