import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CrossIcon from "assets/icons/CrossIcon";
import CustomButton from "components/Button2";
import "./index.scss";
import TickIcon from "assets/icons/TickIcon";
import { useSelector } from "react-redux";

export default function InputModal({
  handleClose,
  open,
  setPaymentData,
  paymentData,
  handleClick,
}) {
  const { merchantDetails } = useSelector((state) => state?.MerchantReducer);
  const [isIndianUser, setIsIndianUser] = useState(false);
  console.log("merchantDetailsadscadcas", merchantDetails);
  console.log(isIndianUser, "isIndianUser");
  useEffect(() => {
    if (merchantDetails?.country === "India") {
      setIsIndianUser(true);
    } else {
      setIsIndianUser(false);
    }
  }, [merchantDetails]);

  return (
    <div>
      <Dialog open={open} fullWidth onClose={handleClose}>
        <DialogTitle>Payment status</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",

            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <FormControl sx={{ m: 1, minWidth: 320 }} size="small">
            <InputLabel id="demo-select-small">Payment method</InputLabel>
            <Select
              defaultValue={"Choose the payment method"}
              labelId="demo-select-small"
              id="demo-select-small"
              value={paymentData?.payment_method}
              label="Payment method"
              onChange={(e) => {
                setPaymentData({
                  ...paymentData,
                  payment_method: e.target.value,
                });
              }}
            >
              <MenuItem className="MuiMenuItem" value={"cash"}>
                Cash
              </MenuItem>
              <MenuItem className="MuiMenuItem" value={"credit"}>
                Credit
              </MenuItem>
              <MenuItem className="MuiMenuItem" value={"debit"}>
                Debit
              </MenuItem>
              {isIndianUser && (
                <MenuItem className="MuiMenuItem" value={"upi"}>
                  UPI
                </MenuItem>
              )}
            </Select>
          </FormControl>{" "}
          <FormControl sx={{ m: 1, minWidth: 320 }} size="small">
            <TextField
              onChange={(e) => {
                setPaymentData({ ...paymentData, notes: e.target.value });
              }}
              value={paymentData?.notes}
              multiline
              label={"Notes"}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <CustomButton
            Size="sm"
            height="2.5rem"
            title="Cancel"
            background={"#D90429"}
            border="none"
            color={"#fff"}
            onClick={handleClose}
            icon={<CrossIcon color="white" />}
          />
          <CustomButton
            Size="md"
            height="2.5rem"
            title="Save and complete"
            border="none"
            icon={<TickIcon />}
            background={"#2FBF71"}
            color={"#fff"}
            disabled={paymentData?.payment_method !== "" ? false : true}
            onClick={handleClick}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
