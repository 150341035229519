import React from 'react';
import { ToggleSwitch } from 'components/ToggleSwitch';
import './index.scss';

function TypeChip({ icon, ActiveStyle, title, onclick, showSwitch, isActive }) {
  return (
    <div className={`ChipContainer ${ActiveStyle}`} onClick={onclick}>
      {icon && <div className='ChipIconWrapper'>{icon}</div>}
      <div className='ChipTitle'>{title}</div>
      {showSwitch && (
        <div className='ChipSwitchWrapper'>
          <ToggleSwitch 
            noWidth
            isAvailable={isActive}
            style={{
              marginBottom: 0,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default TypeChip;
