/** @format */

import React, { useState } from "react";
import Layout from "layouts";
import Button from "components/Button2";
import PlusIcon from "assets/icons/PlusIcon";
import { StockSwitch, ToggleSwitch } from "components/ToggleSwitch";
import "./index.scss";
import { Grid } from "@mui/material";
import LoyaltyAnalytics from "./LoyaltyAnalytics";
import LoyaltyRules from "./LoyaltyRules";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CreateLoyaltyRules, EnableLoyalty, GetLoyaltyDetails, GetLoyaltyRules } from "store/action/LoyaltyAction";
import toast from "react-hot-toast";
import { useEffect } from "react";
import Loader from "components/Loader";

const LoyaltyProgram = () => {
   const { enable_loyalty, loyalty_rules } = useSelector((state) => state.LoyaltyReducer);
   const [isEdit, setEdit] = useState(false);
   const [loading, setLoading] = useState(false);
   const dispatch = useDispatch();

   const [initialValues, setInitialValues] = useState({
      signup_bonus_coins: loyalty_rules?.signup_bonus_coins,
      value_for_1000_coins: loyalty_rules?.value_for_1000_coins,
      coins_for_each_rupee: 10,
   });

   const handleEnableLoyalty = async () => {
      setLoading(true);
      const body = {
         enable_loyalty: !enable_loyalty,
      };
      const request = await dispatch(EnableLoyalty(body));

      if (request.type === "EnableLoyalty/fulfilled") {
         toast.success(request.payload.message);
         setLoading(false);
      } else {
         toast.error("Something went wrong");
         setLoading(false);
      }
   };
   const getLoyaltyDetails = async () => {
      setLoading(true);
      const request = await dispatch(GetLoyaltyDetails());
      if (request.type === "GetLoyaltyDetails/fulfilled") {
         console.log("GETTING LOYALTY DETAILS PAYLOAD", request.payload);
      } else {
         toast.error("Something went wrong");
      }
      setLoading(false);
   };
   const getLoyaltyRules = async () => {
      setLoading(true);
      const request = await dispatch(GetLoyaltyRules());
      if (request.type === "GetLoyaltyRules/fulfilled") {
         console.log("GETTING LOYALT_YRUES PAYLOAD", request.payload);
         setInitialValues({
            signup_bonus_coins: request.payload.signup_bonus_coins,
            value_for_1000_coins: request.payload.value_for_1000_coins,
            coins_for_each_rupee: request.payload.coins_for_each_rupee,
         });
         setEdit(true);
      } else {
         setEdit(false);
      }
      setLoading(false);
   };

   useEffect(() => {
      getLoyaltyDetails();
      getLoyaltyRules();
   }, []);

   return (
      <Layout>
         <Loader isLoading={loading} />{" "}
         <div className="MenuSubContainer">
            <div className="MenuHeader">
               <div className="d-flex gap-3 align-items-center">
                  <div className="loyalty-header-title">Loyalty program</div>
                  <span className="loyalty-switch-wrapper">
                     <ToggleSwitch
                        showAllValue
                        style={{
                           flexDirection: "row",
                           marginBottom: "0px",
                        }}
                        noWidth
                        handleValue={handleEnableLoyalty}
                        isAvailable={enable_loyalty}
                        trueValue={
                           <div className="custom-switch-label">
                              <span style={{ color: enable_loyalty ? "#2fbf71" : "#000" }}>On</span>
                           </div>
                        }
                        falseValue={
                           <div className="custom-switch-label">
                              <span style={{ color: !enable_loyalty ? "#d90429" : "#000" }}>Off</span>
                           </div>
                        }
                     />
                  </span>
               </div>
            </div>
            <Grid
               container
               direction={"row"}
               gap={1.8}
               columns={12}>
               <Grid
                  bgcolor={"#fff"}
                  item
                  xs={12}
                  // lg={6.8}
                  // md={6.8}
                  lg={12}
                  md={12}>
                  <LoyaltyRules
                     setLoading={setLoading}
                     initialValues={initialValues}
                     loyalty_rules={loyalty_rules}
                     isEdit={isEdit}
                     setEdit={setEdit}
                  />
               </Grid>
               {/* <Grid
                  bgcolor={"#fff"}
                  item
                  xs={12}
                  lg={5}
                  md={4.9}>
                  <LoyaltyAnalytics />
               </Grid> */}
            </Grid>
         </div>
      </Layout>
   );
};

export default LoyaltyProgram;
