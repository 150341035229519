import React from 'react';
import './index.scss';

function AnalyticsCard(props) {

  let title=<div className='analyticsCardValue'>{props.item.value}</div>
 

  if(props.item.title === "Avg. order value" )
  {
     title= <div className='analyticsCardValue'>&#x20B9;{props.item.value}</div>
  }
  else if(props.item.title === 'Total Revenue'){

     title= <div className='analyticsCardValue'>&#x20B9;{props.item.value}</div>

  }
  else
  {
     title=<div className='analyticsCardValue'>{props.item.value}</div>

  }
  return (
    <div className='analyticsCardMainContainer'>
       <div className='analyticsCardHeader'>{props.item.title}</div>
       {title}
    </div>
  );
}

export default AnalyticsCard;