/** @format */

import AddComboForm from "components/AddComboForm";
import Search from "components/Search";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button2";
import CategoryItemCard from "components/CategoryItem";
import FoodItemCard from "components/FoodItem";
import RecommendedItemAdded from "components/RecommendedItemAdded";
import NoItems from "components/NoItems";
import * as RecommendedAction from "store/action/RecommendedAction";
import * as MenuActions from "store/action/MenuAction";
import TickIcon from "assets/icons/TickIcon";
import CrossIcon from "assets/icons/CrossIcon";
import TabChanger from "components/TabChanger";
import EditForm from "../EditForm";
import { Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import { createCombo, editCombo, getSingleCombo } from "store/action/CombosAction";
import Loader from "components/Loader";

function AddCombo({ handleClose, setAdding, isAdding, setEdit, isEdit }) {
   const allItems = useSelector((state) => state.MenuReducer.Menu);
   const [loading, setloading] = useState(false);
   const [onNext, setNext] = useState(false);
   const [tab, setTab] = useState("Items");
   const [searchVal, setSearchVal] = useState([]);
   const [searchResults, setSearchResults] = useState([]);
   const [searchItemResults, setSearchItemResults] = useState([]);
   const [select, setSelect] = useState("");
   const [removedItems, setRemovedItems] = useState([]);
   const singleCombo = useSelector((state) => state.comboReducer?.singleCombo);
   const [addedItems, setaddedItems] = useState(isEdit ? singleCombo?.item_ids : []);
   const [initialValues, setInitialValues] = useState({
      combo_name: singleCombo?.combo_name,
      image: "",
      price: singleCombo?.item_total_price,
      combo_price: singleCombo?.combo_price,
      discount: singleCombo?.discount || 0,
      price_after_discount: singleCombo?.combos_price_after_discount,
      combos: singleCombo?.item_ids,
      discountType: "percent",
   });

   const FETCH_ITEMS = async (id) => {
      await allItems
         ?.filter((item) => item.id === id)
         .map((i) => {
            setSelect(i.category_name);
            localStorage.setItem("categoryRecommendedId", id);
         });
   };

   const priceAfterDiscount = (price, discount, type) => {
      var pad = 0;
      var temp = 0;

      if (type === "percent") {
         temp = (price / 100) * discount;
         pad = price - temp;
      } else {
         pad = price - discount;
      }
      return pad;
   };

   const totalPrice = () => {
      var total = 0;
      for (let i = 0; i < addedItems?.length; i++) {
         total += addedItems[i]?.price_after_discount;
      }
      return total;
   };
   const formRef = useRef();

   const validationSchema = yup.object({
      combo_name: yup.string().required("Field is required"),
      combo_price: yup.string().required("Field is required"),
   });

   function removeDuplicates(arr) {
      let unique = [];
      for (const i in arr) {
         if (unique.indexOf(arr[i]) === -1) {
            unique.push(arr[i]);
         }
      }
      return unique;
   }

   const searchMenu = (input) => {
      let temp = [];
      let item = [];
      setSearchVal(input);
      if (input != "") {
         for (const i in allItems) {
            for (const j in allItems[i].items)
               if (allItems[i].category_name.toLowerCase().includes(input.toLowerCase()) || allItems[i].items[j].item_name.toLowerCase().includes(input.toLowerCase())) {
                  temp.push(allItems[i]);
                  item.push(allItems[i].items[j]);
                  console.log("Searched Item", item);
               }
         }
         if (temp.length > 0 || item.length > 0) {
            setSearchItemResults(removeDuplicates(item));
            setSearchResults(removeDuplicates(temp));
         } else {
            setSearchResults([]);
            setSearchItemResults([]);
         }
      }
   };
   const catId = localStorage.getItem("categoryRecommendedId");
   const FoodItems = allItems.filter((i) => i.id === catId).map((i) => i.items);
   const dispatch = useDispatch();

   useEffect(() => {
      FETCH_ALL_ITEMS();
   }, []);

   const FETCH_ALL_ITEMS = async () => {
      setloading(true);
      await dispatch(MenuActions.GetMenu());
      setloading(false);
   };

   //AddItemsFunction
   const addOrRemoveItem = (item) => {
      const findIfExist = addedItems.findIndex((i) => item.item_id === i.item_id);
      const item_to_be_removed = [...removedItems];

      if (findIfExist != -1) {
         const index = addedItems.findIndex((i) => i.item_id === item.item_id);
         const existing = [...addedItems];
         existing.splice(index, 1);
         setaddedItems(existing);

         if (addedItems?.length > 0) {
            item_to_be_removed?.push(item);
            setRemovedItems(item_to_be_removed);
         }
         console.log("ADDED_ITEMS ==>", addedItems);
         console.log("REMOVED_ITEMS ==>", removedItems);
      } else {
         if (addedItems.length < 5) {
            setaddedItems([...addedItems, item]);
         } else {
            alert("You cannot add more than 5 items");
         }
      }
   };

   const onSubmit = async (values) => {
      setloading(true);
      const body = {
         // combo_id: '1',
         combo_name: values?.combo_name,
         item_total_price: totalPrice(),
         combo_price: parseInt(values?.combo_price),
         discount: values?.discount,
         combos_price_after_discount: values?.price_after_discount,
         item_ids: addedItems.map((i) => {
            return {
               item_id: i.item_id,
            };
         }),
      };
      if (!isEdit) {
         const result = await dispatch(createCombo(body));
         if (result.type === "createCombo/fulfilled") {
            setloading(false);
            console.log("Created combo", result);
            toast.success("Combo created sucessfully");
            setAdding(false);
         } else {
            toast.error("Error occured");
            setloading(false);
         }
      } else {
         const result = await dispatch(editCombo({ combo_id: singleCombo?.combo_id, data: body }));
         if (result.type === "editCombo/fulfilled") {
            setloading(false);
            console.log("Edited combo", result);
            toast.success("Edited combo sucessfully");
            setAdding(false);
            setEdit(false);
         } else {
            console.log("Error in editing combo");
            toast.error("Error occured");
            setloading(false);
         }
      }
   };

   const handleSave = () => {
      if (formRef.current) {
         formRef.current.handleSubmit();
      }
   };

   const getCategoryTitle = (item) => allItems.filter((i) => i.id === item.category_id).map((i) => i.category_name);

   return (
      <div className="addComboMainContainer">
         <Loader isLoading={loading} />
         <div className="addComboFormSection">
            <div>
               <Formik
                  innerRef={formRef}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}>
                  {({ isValid, values, setFieldValue }) => {
                     return (
                        <AddComboForm
                           values={values}
                           totalPrice={totalPrice}
                           priceAfterDiscount={priceAfterDiscount}
                           setFieldValue={setFieldValue}
                           isEdit={isEdit}
                        />
                     );
                  }}
               </Formik>
            </div>
         </div>

         <div className="addComboItemsSection">
            <div className="combo-content-container">
               <div className="combo-categories-added-items-search-wrapper">
                  <Search
                     onChange={(e) => {
                        searchMenu(e.target.value);
                     }}
                     border={"1px solid rgba(0,0,0,0.3)"}
                     InputHeight={"3rem"}
                     padding={"0.8rem"}
                     width={"100%"}
                     placeholder={"Search in menu"}
                  />

                  <div className="combo-categoies-added-items-main-container">
                     <div className="combo-categories-container">
                        <div className="combo-category-items-wrapper">
                           {searchVal != ""
                              ? searchResults?.map((item, index) => (
                                   <CategoryItemCard
                                      item={item}
                                      select={select}
                                      name={item.category_name}
                                      filter={FETCH_ITEMS}
                                      key={index}
                                   />
                                ))
                              : allItems?.map((item, index) => (
                                   <CategoryItemCard
                                      item={item}
                                      select={select}
                                      name={item.category_name}
                                      filter={FETCH_ITEMS}
                                      key={index}
                                   />
                                ))}
                        </div>
                     </div>
                     <div className="combo-items-container">
                        <div className="combo-add-items-wrapper">
                           {searchVal != ""
                              ? searchItemResults?.map((item, index) => (
                                   <FoodItemCard
                                      item={item}
                                      key={index}
                                      addToRecommended={() => {
                                         addOrRemoveItem(item);
                                      }}
                                      //   disabled={true}
                                   />
                                ))
                              : FoodItems[0]?.map((item, index) => (
                                   <FoodItemCard
                                      item={item}
                                      key={index}
                                      isAdded={addedItems?.findIndex((i) => item.item_id === i.item_id) != -1 ? true : false}
                                      disabled={addedItems?.length === 5 ? true : false}
                                      addToRecommended={() => {
                                         addOrRemoveItem(item);
                                      }}
                                   />
                                ))}
                        </div>
                     </div>
                  </div>
               </div>
               <div className="combo-added-items-container">
                  {addedItems?.length === 5 && <span className="recommended-items-warning">You can add only upto 5 items</span>}

                  <div className="combo-added-items-container-header">
                     <span className="combo-added-items-heading-text1">Items</span>
                  </div>
                  {/* {isEdit && onNext && <TabChanger setNext={setNext} setTab={setTab} tab={tab} />}
            {(isAdding && (
              <div
                className={
                  addedItems?.length === 0
                    ? 'combo-added-items-added-items-container NoItemsWrapper'
                    : 'combo-added-items-added-items-container'
                }
              >
                {addedItems?.length > 0 ? (
                  addedItems?.map((item, index) => {
                    return (
                      <RecommendedItemAdded
                        item={item}
                        key={index}
                        categoryTitle={getCategoryTitle(item)}
                        handleRemove={() => {
                          addOrRemoveItem(item);
                        }}
                      />
                    );
                  })
                ) : (
                  <NoItems />
                )}
              </div>
            )) ||
            (isEdit && tab === 'Items') ? ( */}
                  <div className={addedItems?.length === 0 ? "combo-added-items-added-items-container NoItemsWrapper" : "combo-added-items-added-items-container"}>
                     {addedItems?.length > 0 ? (
                        addedItems?.map((item, index) => {
                           return (
                              <RecommendedItemAdded
                                 item={item}
                                 key={index}
                                 categoryTitle={getCategoryTitle(item)}
                                 handleRemove={() => {
                                    addOrRemoveItem(item);
                                 }}
                              />
                           );
                        })
                     ) : (
                        <NoItems />
                     )}
                  </div>
                  {/* ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isValid, values, setFieldValue }) => {
                  return <EditForm setFieldValue={setFieldValue} />;
                }}
              </Formik>
            )} */}
                  <div className="combo-added-items-bottom-container">
                     {isEdit && !onNext ? (
                        <Button
                           Size={"sm"}
                           background={"#2FBF71"}
                           color={"#fff"}
                           title={"Next"}
                           border={"none"}
                           //   disabled={removedItems?.length > 0 ? false : true}
                           onClick={() => {
                              setNext(true);
                              setTab("Details");
                           }}
                        />
                     ) : (
                        <>
                           <Button
                              Size={"sm"}
                              background={"#FBE6EA"}
                              color={"#D90429"}
                              title={"Cancel"}
                              icon={<CrossIcon color="#D90429" />}
                              border={"none"}
                              //   disabled={removedItems?.length > 0 ? false : true}
                              onClick={() => {
                                 handleClose(false);
                                 setEdit(false);
                              }}
                           />
                           <Button
                              Size={"sm"}
                              background={"#2FBF71"}
                              color={"#fff"}
                              title={isEdit ? "Edit" : "Save"}
                              icon={<TickIcon />}
                              border={"none"}
                              type={"submit"}
                              //   disabled={removedItems?.length > 0 ? false : true}
                              onClick={handleSave}
                           />
                        </>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default AddCombo;
