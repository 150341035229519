/** @format */

import { Modal } from "@mui/material";
import CrossIcon from "assets/icons/CrossIcon";
import CustomModalCancel from "assets/icons/CustomModalCancel";
import DeleteIcon from "assets/icons/DeleteIcon";
import Button from "components/Button2";
import React from "react";
import "./index.scss";

export function UpdateModal(props) {
   return (
      <Modal
         className="deleteModal"
         onClose={props.handleCancel}
         open={props.isVisible}>
         <div className="deleteModalMainContainer">
            <div className="deleteModalWarnMessage">{props.message}</div>
            <div className="deleteModalBtnWrapper">
               <Button
                  Size="sm"
                  height="2.2rem"
                  title="Cancel"
                  onClick={(e) => {
                     e.stopPropagation();
                     props.handleCancel();
                  }}
                  icon={<CrossIcon color="black" />}
               />

               {props.custom ? (
                  <Button
                     Size="sm"
                     height="2.2rem"
                     title={props.Btntitle}
                     border="none"
                     icon={props.btnIcon}
                     background={props.BtnBg}
                     color={"#fff"}
                     onClick={props.handleCancel}
                  />
               ) : (
                  <Button
                     Size="sm"
                     height="2.2rem"
                     title="Okay"
                     border="none"
                     background={"#2FBF71"}
                     color={"#fff"}
                     onClick={props.handleUpdate}
                  />
               )}
            </div>
         </div>
      </Modal>
   );
}

export function CustomModal(props) {
   return (
      <>
         <Modal
            className="deleteModal"
            open={props.isVisible}>
            <>
               <div className="cancel-icon-custom-modal">
                  <span onClick={props.onClose}>
                     <CustomModalCancel />
                  </span>
               </div>

               <div className="customModalMainContainer">
                  <div className="customModalWarnMessage">{props.message}</div>
                  <div className="customModalContentMessage">{props.content}</div>

                  <div className="customModalBtnWrapper">
                     <Button
                        Size="md"
                        height="2.2rem"
                        title={"Skip & purchase"}
                        color={"#000"}
                        onClick={props.handleBtn1}
                     />
                     <Button
                        width={"16rem"}
                        Size={"md"}
                        height="2.2rem"
                        title="Enable auto-renew & purchase"
                        border="none"
                        background={"#2FBF71"}
                        color={"#fff"}
                        onClick={props.handleBtn2}
                     />
                  </div>
               </div>
            </>
         </Modal>
      </>
   );
}
