/** @format */

import React, { useEffect, useState } from "react";
import "./index.scss";
import LOGO from "assets/logo/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Arrow from "assets/icons/Arrow";
import { useSelector } from "react-redux";
import Button from "components/Button2";
import LogOutIcon from "assets/icons/LogOutIcon";
import NewFeatureTag from "components/NewFeatureTag";

const NAV_ITEMS = [
   {
      title: "Menu",
      to: ["/menu"],
   },
   {
      title: "Orders",
      to: ["/orders", "/orders/create"],
   },
   {
      title: "Recommended",
      to: ["/recommended"],
   },
   {
      title: "Best selling",
      to: ["/bestselling"],
   },
   {
      title: "Newly added",
      to: ["/newlyadded"],
   },
   {
      title: "QR Code",
      to: ["/qrcode"],
   },
   {
      title: "Analytics",
      to: ["/analytics"],
   },
   {
      title: "Specials",
      to: ["/specials"],
      isNew: true,
   },
   {
      title: "Loyalty",
      to: ["/loyalty"],
      isNew: true,
   },
   {
      title: "Offers",
      to: ["/offers"],
      isNew: true,
   },
   {
      title: "Waiter Management",
      to: ["/waiter"],
   },
   {
      title: "Settings",
      to: ["/profile"],
   },
];

function Sidebar() {
   const location = useLocation();
   const navigate = useNavigate();
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);
   const isNewUser = merchantDetails?.new_user;
   const { subscriptionDetails, currentSubscription, allPlans } = useSelector((state) => state.SubscriptionReducer);
   const [waiterMode, setWaiterMode] = useState(false);
   console.log(waiterMode, "waiterMode");
   useEffect(() => {
      const waiterModeFromStorage = localStorage.getItem("waiter_mode") === "true"; // Convert to boolean
      setWaiterMode(waiterModeFromStorage);
   }, []);

   const handleLogout = () => {
      localStorage.clear();
      navigate("/");
   };

   console.log("Merchant details ", merchantDetails);

   const chooseClassNames = (to) => {
      console.log("chooseClassNames", to, location.pathname);
      if (!isNewUser) {
         const formattedPath = `/${location.pathname.split("/")[1]}`;
         console.log(formattedPath, "formattedPath");
         if (currentSubscription?.plan_active !== false) {
            if (to.includes(formattedPath)) {
               return "NavItem active";
            } else {
               return "NavItem";
            }
         } else {
            return "NavItem newUser";
         }
      } else {
         return "NavItem newUser";
      }
   };

   console.log(location.pathname.split("/"), "location.pathname");

   const filteredNavItems = waiterMode ? NAV_ITEMS.filter((item) => item.title === "Orders") : NAV_ITEMS;

   return (
      <div className="SidebarMainContainer relative">
         <div className="LogoWrapper">
            <img
               className="SideBarLogo"
               src={LOGO}
               alt="Logo"
            />
            <h5 className="SideBarLogoText">The Beautiful Menu</h5>
         </div>
         <div className="NavItemsWrapper">
            {filteredNavItems.map((item, index) => {
               const { title, to } = item;
               return (
                  <button
                     onClick={() => {
                        window.location.href = to[0];
                     }}
                     disabled={isNewUser || currentSubscription?.plan_active === false ? true : false}
                     className={chooseClassNames(to)}
                     key={index}>
                     <span className="d-flex flex-row align-items-center gap-3 justify-content-center">
                        {title}
                        <NewFeatureTag
                           isNew={item?.isNew}
                           isActive={to.includes(location.pathname)}
                        />
                     </span>
                     <Arrow />
                  </button>
               );
            })}
         </div>

         <div className={`nav-log-out-container ${waiterMode && "absolute bottom-[2rem]"}`}>
            <Button
               width={"100%"}
               border={"none"}
               borderRadius={"10px"}
               height={"40px"}
               title={"Log out"}
               background={"#FBE6EA"}
               color={"#D90429"}
               onClick={handleLogout}
               icon={<LogOutIcon />}
            />
         </div>
      </div>
   );
}

export default Sidebar;
