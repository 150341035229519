/** @format */

import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter, getCurrency } from "utils/functions";
import "./index.scss";

export default function Index({ visible, onClose, data, onCreateOrder, tableNumber, UpateTableNumber }) {
   const { Cart, order } = useSelector((state) => state.ManualOrderReducer);
   const dispatch = useDispatch();
   const { merchantDetails } = useSelector((state) => state.MerchantReducer);

   const { sgst_percentage, cgst_percentage, items_ordered, rounded_off, amount_after_taxes, total_amount } = data;

   return (
      <Modal
         onClose={onClose}
         open={visible}
         className="deleteModal">
         <div className="bg-[#fff] px-4 w-[40%] flex max-h-[90%]  position-relative  flex-col rounded-[0.4rem]">
            <CloseIcon
               onClick={onClose}
               className="position-absolute  cursor-pointer right-4 top-2"
            />
            <div className="text-[1.7rem] font-bold text-[#2D6B4D] my-3">Order summary</div>
            <div className="flex items-center">
               <h6 className="w-[50%] text-[grey]">Item name</h6>
               <h6 className="w-[20%] text-[grey] text-center">Quantity</h6>
               <h6 className="w-[10%] text-[grey]">Price</h6>
               <h6 className="w-[20%] text-right text-[grey]">Total price</h6>
            </div>
            <div className="flex flex-col max-h-[90%] overflow-y-scroll gap-2 mt-1">
               {items_ordered[0].suborder_items_ordered.map((item, index) => {
                  return (
                     <>
                        <div className="flex">
                           <h6 className="w-[50%]">{capitalizeFirstLetter(item.item_name)}</h6>
                           <h6 className="w-[20%]  text-center">{item.count}</h6>
                           <h6 className="w-[10%]">{item.price}</h6>
                           <h6 className="w-[20%] text-right">
                              {getCurrency(merchantDetails?.currency)} {item.count * item.price}
                           </h6>
                        </div>
                        {item.addons.map((item) => {
                           return (
                              <div className="flex">
                                 <h6 className="w-[50%]">
                                    {capitalizeFirstLetter(item.item_name)} <span className="add-on-tag">Add-ons</span>
                                 </h6>
                                 <h6 className="w-[20%] text-center">{item.count}</h6>
                                 <h6 className="w-[10%]">{item.price}</h6>
                                 <h6 className="w-[20%] text-right">
                                    {getCurrency(merchantDetails?.currency)} {item.count * item.price}
                                 </h6>
                              </div>
                           );
                        })}
                     </>
                  );
               })}
            </div>
            <table>
               <tbody
                  style={{ borderTopWidth: 1, borderColor: "#c4c4ca" }}
                  id="bottom-table-body">
                  <tr className="border-0">
                     <td
                        colSpan={4}
                        className="text-left font-bold"></td>
                     <td
                        colSpan={2}
                        className="text-center text-[#4361EE]"></td>
                     <td
                        colSpan={2}
                        className="text-right font-bold">
                        Sub total
                     </td>
                     <td
                        colSpan={2}
                        className="text-right text-[#4361EE]">
                        {getCurrency(merchantDetails?.currency)} {total_amount}
                     </td>
                  </tr>
               </tbody>
               {(merchantDetails?.country === "India" || merchantDetails?.country === "UK") && cgst_percentage > 0 && (
                  <tbody>
                     <tr className="border-0">
                        <td
                           colSpan={4}
                           className="text-left font-bold"></td>
                        <td
                           colSpan={2}
                           className="text-center text-[#4361EE]"></td>

                        <>
                           <td
                              colSpan={2}
                              className="text-right font-bold">
                              CGST
                           </td>
                           <td
                              colSpan={2}
                              className="text-right text-[#4361EE]">
                              {cgst_percentage}%
                           </td>
                        </>
                     </tr>
                  </tbody>
               )}
               {(merchantDetails?.country === "India" || merchantDetails?.country === "UK") && sgst_percentage > 0 && (
                  <tbody>
                     <tr className="border-0">
                        <td
                           colSpan={4}
                           className="text-left font-bold"></td>
                        <td
                           colSpan={2}
                           className="text-center text-[#4361EE]"></td>
                        <td
                           colSpan={2}
                           className="text-right font-bold">
                           SGST
                        </td>
                        <td
                           colSpan={2}
                           className="text-right text-[#4361EE]">
                           {sgst_percentage}%
                        </td>
                     </tr>
                  </tbody>
               )}
               <tbody>
                  <tr className="border-0">
                     <td
                        colSpan={4}
                        className="text-left font-bold"></td>
                     <td
                        colSpan={2}
                        className="text-center text-[#4361EE]"></td>
                     {/* <td
                        colSpan={2}
                        className="text-right font-bold">
                        Rounded off
                     </td>
                     <td
                        colSpan={2}
                        className="text-right text-[#4361EE]">
                        {rounded_off}
                     </td> */}
                  </tr>
               </tbody>
               <tbody
                  id="bottom-table-body"
                  style={{ borderTopWidth: 1, borderColor: "#c4c4ca" }}>
                  <tr className="border-0">
                     <td
                        colSpan={4}
                        className="text-left font-bold">
                        Total items
                     </td>
                     <td
                        colSpan={2}
                        className="text-center text-[1.4rem] text-[#4361EE] font-bold">
                        {Cart.length}
                     </td>
                     <td
                        colSpan={2}
                        className="text-right font-bold">
                        Total amount
                     </td>
                     <td
                        colSpan={2}
                        className="text-right text-[1.4rem] text-[#4361EE] font-bold">
                        {amount_after_taxes}
                     </td>
                  </tr>
               </tbody>
            </table>
            <div className="flex w-full gap-[8.5%] items-center justify-end">
               <p className="text-[1rem] font-semibold">Table No:</p>
               <input
                  style={{ border: "1px solid #c0c0c0" }}
                  type="number"
                  value={tableNumber}
                  onChange={(e) => UpateTableNumber(e)}
                  className="w-[3.5rem] text-center font-semibold p-1.5 outline-none bg-gray-100 rounded-lg"
               />
            </div>
            <div className="flex items-center justify-center">
               <div
                  onClick={onCreateOrder}
                  className="bg-[#2D6B4D] cursor-pointer px-4 my-3 text-[1rem] rounded-[0.5rem] py-2 text-[#fff]">
                  Create order
               </div>
            </div>
         </div>
      </Modal>
   );
}
