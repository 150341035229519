/** @format */

import { FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from "@mui/material";
import ComingSoon from "components/ComingSoon";
import Loader from "components/Loader";
import RefreshingCounter from "components/RefreshingCounter";
import Layout from "layouts";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { GetAnalytics } from "store/action/AnalyticsAction";
import ItemsPage from "./ItemsPage";
import OrdersPage from "./OrdersPage";
import "./index.scss";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { formatDate, getCurrentWeekRange, formatMonthDate } from "utils/functions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}>
         {value === index && children}
      </div>
   );
}

function Analytics() {
   const [data, setdata] = useState(null);
   const [tab, setTab] = useState(0);
   const [ordersTab, setOrdersTab] = useState(0);
   const [recencySelected, setRecencySelected] = useState("7 days");
   const [loading, setLoading] = useState(false);
   const [showDatePicker, setshowDatePicker] = useState(false);
   const pickerRef = useRef(null);

   const togglePicker = () => {
      setshowDatePicker(!showDatePicker);
   };

   const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
         setshowDatePicker(false);
      }
   };

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   const [selectedDate, setselectedDate] = useState([
      {
         startDate: new Date(),
         endDate: new Date(Date.now() + 6 * 24 * 60 * 60 * 1000),
         key: "selection",
      },
   ]);

   console.log(selectedDate, "selectedDate");

   const dispatch = useDispatch();

   const GET_ANALYTICS_DETAILS = async (payload) => {
      setLoading(true);
      const result = await dispatch(GetAnalytics(payload));
      const info = await result.payload;
      setLoading(false);
      setdata(info);
   };

   useEffect(() => {
      const payload = {
         start_date: formatDate(getCurrentWeekRange().start_date),
         end_date: formatDate(getCurrentWeekRange().end_date),
      };
      setselectedDate([
         {
            startDate: new Date(getCurrentWeekRange().start_date),
            endDate: new Date(getCurrentWeekRange().end_date),
            key: "selection",
         },
      ]);
      GET_ANALYTICS_DETAILS(payload);
   }, [dispatch]);

   return (
      <Layout>
         <Loader isLoading={loading} />
         <div className="analyticsMainContainer">
            <div className="analyticsHeaderWrapper">
               <div className="analyticsHeader">Dashboard</div>
               <div>
                  <RefreshingCounter
                     action={GET_ANALYTICS_DETAILS}
                     duration={120}
                  />
               </div>
            </div>
            <div className="analyticsTabs">
               <Tabs
                  value={tab}
                  variant="fullWidth"
                  onChange={(e, v) => setTab(v)}
                  aria-label="tabs"
                  sx={{
                     backgroundColor: "#fff",
                     display: "flex",
                     justifyContent: "space-between",
                     borderRadius: "0.5rem",
                     width: "80%",
                  }}>
                  <Tab
                     label="Orders"
                     classes={{ root: "tab-text", selected: "tab-text-selected" }}
                  />
                  <Tab
                     label="Customers"
                     classes={{ root: "tab-text", selected: "tab-text-selected" }}
                  />
                  <Tab
                     label="Items"
                     classes={{ root: "tab-text", selected: "tab-text-selected" }}
                  />
                  <Tab
                     label="Tables"
                     classes={{ root: "tab-text", selected: "tab-text-selected" }}
                  />
               </Tabs>
            </div>

            <div>
               <div style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "#fff", alignItems: "center", padding: "1rem", borderRadius: "0.5rem" }}>
                  {(tab === 0 || tab === 1 || tab === 2) && (
                     <div style={{ marginLeft: "auto" }}>
                        {/* <Tabs
                           value={ordersTab}
                           onChange={(e, v) => {
                              setOrdersTab(v);
                           }}
                           TabIndicatorProps={{
                              style: {
                                 height: "100%",
                                 borderRadius: "0.5rem",
                                 zIndex: "0",
                              },
                           }}
                           aria-label="tabs"
                           sx={{
                              padding: "1px",
                              backgroundColor: "#fff",
                              borderRadius: "0.5rem",
                              width: "fit-content",
                              border: "1px solid #e0e0e0",
                           }}>
                           <Tab
                              label="Dine-in"
                              classes={{ root: "type-tab-text", selected: "type-tab-text-selected" }}
                           />
                           <Tab
                              label="Takeaway"
                              classes={{ root: "type-tab-text", selected: "type-tab-text-selected" }}
                           />
                           <Tab
                              label="Delivery"
                              classes={{ root: "type-tab-text", selected: "type-tab-text-selected" }}
                           />
                        </Tabs> */}
                     </div>
                  )}
                  <div
                     className="position-relative hover:underline"
                     style={{ width: "fit-content", marginLeft: "auto" }}>
                     <div
                        className="cursor-pointer text-[1.2rem] hover:underline"
                        onClick={togglePicker}>
                        {formatMonthDate(selectedDate[0].startDate)} - {formatMonthDate(selectedDate[0].endDate)}
                        <span>
                           <KeyboardArrowDownIcon />
                        </span>
                     </div>
                     {showDatePicker && (
                        <div
                           ref={pickerRef}
                           className="position-absolute shadow rounded-[0.5rem] right-0 bg-[#fff] p-1 border-[1px] border-solid border-[grey]">
                           <DateRangePicker
                              onChange={(item) => {
                                 const payload = {
                                    start_date: formatDate(item.selection.startDate),
                                    end_date: formatDate(item.selection.endDate),
                                 };
                                 GET_ANALYTICS_DETAILS(payload);
                                 setselectedDate([item.selection]);
                              }}
                              months={2}
                              rangeColors={["#2D6B4D"]}
                              ranges={selectedDate}
                              direction="horizontal"
                           />
                        </div>
                     )}
                  </div>
               </div>
               <TabPanel
                  value={tab}
                  index={0}>
                  <OrdersPage
                     data={data}
                     ordersTab={ordersTab}
                     recencySelected={recencySelected}
                  />
               </TabPanel>
               <TabPanel
                  value={tab}
                  index={1}>
                  {/* <CustomersPage data={data} recencySelected={recencySelected} ordersTab={ordersTab} /> */}
                  <ComingSoon />
               </TabPanel>
               <TabPanel
                  value={tab}
                  index={2}>
                  <ItemsPage
                     data={data}
                     recencySelected={recencySelected}
                     ordersTab={ordersTab}
                  />
               </TabPanel>
               <TabPanel
                  value={tab}
                  index={3}>
                  {/* <TablesPage data={data} recencySelected={recencySelected} ordersTab={ordersTab} /> */}
                  <ComingSoon />
               </TabPanel>
            </div>
         </div>
      </Layout>
   );
}

export default Analytics;
