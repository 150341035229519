import React from 'react';

function Arrow({ color }) {
  return (
    <svg
      width='10'
      height='18'
      viewBox='0 0 10 18'
      fill='none'
      style={{ marginBottom: '2px' }}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.19893 1.35779C0.79047 1.86745 0.79047 2.59233 1.19893 3.102L5.17399 8.06194C5.61334 8.61015 5.61334 9.38985 5.17399 9.93807L1.19893 14.898C0.790471 15.4077 0.790471 16.1325 1.19893 16.6422C1.75718 17.3388 2.81704 17.3388 3.37529 16.6422L8.7482 9.93806C9.18756 9.38985 9.18756 8.61015 8.7482 8.06194L3.37528 1.35779C2.81704 0.661232 1.75718 0.661232 1.19893 1.35779Z'
        fill={color ? color : 'white'}
      />
    </svg>
  );
}

export default Arrow;
