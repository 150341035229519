/** @format */

import React, { useState, useEffect, useCallback } from "react";
import Button from "components/Button2";
import Edit from "assets/icons/Edit";
import Delete from "assets/icons/Delete";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { CreateWaiter, UpdateWaiter, DeleteWaiter, GetWaiter } from "store/action/WaiterAction";
import debounce from "lodash/debounce";

const BACKEND_URL = process.env.REACT_APP_DEV_URL;

const WaiterDetail = ({ label, value, isEditing, placeholder, onChange, usernameAvailability }) => {
   return (
      <div className="waiterUserDetails">
         <p className="waiterDetailsLabel">{label}</p>
         {isEditing ? (
            <div className="relative">
               <input
                  type="text"
                  className="waiterInputField"
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
               />
               {label === "UserName" && usernameAvailability !== null && (
                  <p className={`absolute top-[-1rem] right-0 ${usernameAvailability ?'text-green-600':'text-red-600'}`}>
                     {usernameAvailability ? "Username is available" : "Username is already taken"}
                  </p>
               )}
            </div>
         ) : (
            <p className="waiterDetails">{value}</p>
         )}
      </div>
   );
};

const CHECK_WAITER_USERNAME_AVAILABILITY = (username) => {
   return new Promise((resolve, reject) => {
      fetch(`${BACKEND_URL}/merchant/waiter/check_waiter_username_availability/${username}`).then((res) => {
         if (res.status === 200) {
            resolve(true);
         } else if (res.status === 400) {
            resolve(false);
         }
      });
   });
};

const WaiterCard = ({ initialEditing, waiterData, isNew, onCancel, onDelete }) => {
   const dispatch = useDispatch();
   const [isEditing, setIsEditing] = useState(initialEditing);
   const [details, setDetails] = useState({
      username: waiterData?.username || "",
      password: waiterData?.password || "",
      waiter_info: waiterData?.waiter_info || "",
   });
   const [usernameAvailability, setUsernameAvailability] = useState(null);

   useEffect(() => {
      setDetails({
         username: waiterData?.username || "",
         password: waiterData?.password || "",
         waiter_info: waiterData?.waiter_info || "",
      });
   }, [waiterData]);

   const debouncedCheckUsername = useCallback(
      debounce((value) => {
         CHECK_WAITER_USERNAME_AVAILABILITY(value).then((isAvailable) => {
            setUsernameAvailability(isAvailable);
         });
      }, 500),
      []
   );

   const handleCancelClick = () => {
      if (isNew) {
         onCancel();
      } else {
         setIsEditing(false);
      }
   };

   const handleChange = (e, field) => {
      const value = e.target.value;
      setDetails((prevDetails) => ({
         ...prevDetails,
         [field]: value,
      }));
      if (field === "username") {
         setUsernameAvailability(null); 
         debouncedCheckUsername(value);
      }
   };

   const handleSaveClick = () => {
      if (!initialEditing) {
         dispatch(UpdateWaiter({ waiterId: waiterData.waiter_id, updatedData: details }));
      } else {
         dispatch(CreateWaiter(details));
      }
      setIsEditing(false);
   };

   const handleDeleteClick = () => {
      if (waiterData && waiterData.waiter_id) {
         dispatch(DeleteWaiter({ waiterId: waiterData.waiter_id })).then(() => {
            onDelete(waiterData.waiter_id);
         });
      }
   };

   const isSaveButtonEnabled = details.username && details.password && details.waiter_info && usernameAvailability;

   return (
      <div className="waiterCardContainer">
         <WaiterDetail
            label="Name / ID"
            value={details.waiter_info}
            isEditing={isEditing}
            placeholder="Enter here"
            onChange={(e) => handleChange(e, "waiter_info")}
         />
         <WaiterDetail
            label="UserName"
            value={details.username}
            isEditing={isEditing}
            placeholder="Enter Username"
            onChange={(e) => handleChange(e, "username")}
            usernameAvailability={usernameAvailability}
         />
         <WaiterDetail
            label="Password"
            value={details.password}
            isEditing={isEditing}
            placeholder="Enter Password"
            onChange={(e) => handleChange(e, "password")}
         />
         {!isEditing ? (
            <div className="waiterButtonsContainer">
               <Button
                  border={"none"}
                  color={"#fff"}
                  Size={"sm"}
                  background={"#4361EE"}
                  icon={<Edit />}
                  title={"Edit"}
                  onClick={() => setIsEditing(true)}
               />
               <Button
                  border={"none"}
                  color={"#FF445C"}
                  Size={"sm"}
                  background={"#FBE6EA"}
                  icon={<Delete />}
                  title={"Delete"}
                  onClick={handleDeleteClick}
               />
            </div>
         ) : (
            <div className="waiterButtonsContainer">
               <Button
                  border={"none"}
                  color={"#2FBF71"}
                  Size={"sm"}
                  background={"#EBF9F1"}
                  icon={<DoneIcon />}
                  title={"Save"}
                  onClick={handleSaveClick}
                  disabled={!isSaveButtonEnabled}
               />
               <Button
                  border={"none"}
                  color={"#FF445C"}
                  Size={"sm"}
                  background={"#FBE6EA"}
                  icon={<ClearIcon />}
                  title={"Cancel"}
                  onClick={handleCancelClick}
               />
            </div>
         )}
      </div>
   );
};

export default WaiterCard;
