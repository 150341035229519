import NoItemsIcon from 'assets/icons/NoItemsIcon';
import React from 'react';
import './index.scss';

function NoItems({ recommendedText = false, title, description }) {
  return (
    <div className='NoItemsContainer'>
      <div className='NoItemsIconWrapper'>
        <NoItemsIcon />
      </div>
      <div className='NoItemsInfo'>
        <div className='NoItemsTitle'>
          {!title
            ? recommendedText
              ? 'No items added to recommended list'
              : 'No items to display!'
            : title}
        </div>
        <div className='NoItemsSubTitle'>
          {!description
            ? recommendedText
              ? 'Select items from the menu to add in recommendation list'
              : 'Add items to the category to view your menu'
            : description}
        </div>
      </div>
    </div>
  );
}

export default NoItems;
