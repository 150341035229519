/** @format */

import { createSlice, current } from "@reduxjs/toolkit";
import { GetAllOrders, deleteOrder, UpdateOrder, GetSpecificOrder } from "store/action/OrderAction";

export const OrderReducer = createSlice({
   name: "Orders",
   initialState: {
      Orders: [],
      individualOrder: {},
   },
   extraReducers: {
      [GetAllOrders.fulfilled]: (state, { payload }) => {
         state.Orders = payload;
      },
      [GetSpecificOrder.fulfilled]: (state, { payload }) => {
         console.log("GET SPECIFIC ORDER ", payload);
         let existing = [...state.Orders];
         console.log("existing = [...state.Orders]", existing);
         for (const key in existing) {
            console.log(existing[key].order_id, " -------");
         }
         const index = existing.findIndex((item) => item.order_id === payload.order_id);
         console.log(index, "GetSpecificOrder");
         const updatedPayload = {
            ...payload,
            order_time: existing[index].order_time,
            order_date: existing[index].order_date,
         };
         if (index !== -1) {
            existing.splice(index, 1);
            existing.splice(index, 0, updatedPayload);
         } else {
            existing.splice(0, 0, updatedPayload);
         }

         state.individualOrder = payload;
         state.Orders = existing;
      },
      [deleteOrder.fulfilled]: (state, { payload }) => {
         console.log("DELETE ORDER REDUCDER ", payload);
         let existing = [...state.Orders];
         const indexOf = existing.findIndex((item) => item.order_id === payload.order_id);
         console.log("Delete index of => ", indexOf);
         existing.splice(indexOf, 1);
         state.Orders = existing;
      },
      [UpdateOrder.fulfilled]: (state, { payload }) => {
         let allOrders = [...state.Orders];

         console.log(payload.order_id);

         let index = allOrders.findIndex((item) => item.order_id === payload.order_id);

         console.log("INDEX => ", index);
         let existingOrder = { ...allOrders[index] };
         allOrders.splice(index, 1);
         const updatedPayload = {
            ...payload,
            order_status: payload.order_status,
            order_time: state.individualOrder.order_time,
            order_date: state.individualOrder.order_date,
         };
         allOrders.splice(index, 0, { ...payload, ...existingOrder, order_status: payload.order_status });
         if (state.filteredOrders?.length > 0) {
            let filteredOrders = [...state.filteredOrders];
            const index = filteredOrders.findIndex((item) => item.order_id === payload.order_id);
            console.log("FILTERED INDEX ", index);
            if (index !== -1) {
               filteredOrders.splice(index, 1);
               filteredOrders.splice(index, 0, existingOrder);
               state.filteredOrders = filteredOrders;
            }
         }
         state.individualOrder = updatedPayload;
         state.Orders = allOrders;
      },
   },
});

export const { ResetFilters } = OrderReducer.actions;

export default OrderReducer.reducer;
