/** @format */

import React, { useRef, useState } from "react";
import Button from "components/Button2";
import PlusIcon from "assets/icons/PlusIcon";
import Search from "components/Search";
import MenuCard from "components/MenuCard";
import NewCategoryInput from "components/NewCategoryInput";
import MenuItemCard from "components/MenuItemCard";
import { useDispatch } from "react-redux";
import { dropMenu } from "store/reducer/MenuReducer";
import { EditCategory } from "store/action/MenuAction";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ta } from "date-fns/locale";

function MenuList({
  handle,
  listTitle,
  buttonText,
  tab,
  searchVal,
  searchResults,
  data,
  addCategory,
  header,
  filter,
  openItem,
  searchItemResults,
  setloading,
  handleClose,
  setSearch,
  initialRender,
}) {
  const dispatch = useDispatch();

  const getCardData = (item) => {
    switch (tab) {
      case 0:
        return {
          name: item.category_name,
          id: item.id,
          category_idx: item.category_idx,
        };
        break;
      case 1:
        return { name: item.item_name, id: item.add_on_id };
        break;
      case 2:
        return { name: item.customisation_title, id: item.customisation_id };
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{
        width: openItem || searchItemResults?.length > 0 ? "50%" : "100%",
        animationName:
          openItem || searchItemResults?.length > 0 ? "widthTransition" : "",
      }}
      className="MenuSubContainer"
    >
      <div className="MenuHeader">
        <h2>{listTitle}</h2>
        <Button
          Size="md"
          title={buttonText}
          icon={<PlusIcon color={"#4361EE"} />}
          border="none"
          background="#ECEFFD"
          color={"#4361EE"}
          fontWeight="bold"
          onClick={handle}
        />
      </div>
      {addCategory && tab === 0 && (
        <NewCategoryInput data={data} onPressCancel={handle} />
      )}
      <Droppable droppableId="categories">
        {(provided) => (
          <div
            className="MenuListContainer"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div className="MenuCategoryWrapper">
              {searchVal != ""
                ? searchResults?.map((item, index) => (
                    <MenuCard
                      index={index}
                      category_idx={getCardData(item).category_idx}
                      searchResults={searchResults}
                      setSearch={setSearch}
                      filter={filter}
                      name={getCardData(item).name}
                      key={index}
                      tab={tab}
                      id={getCardData(item).id}
                      select={header}
                      isAvailable={item.isAvailable}
                      initialRender={initialRender}
                    />
                  ))
                : data?.map((item, index) => (
                    <Draggable
                      draggableId={item?.id}
                      index={index}
                      key={item?.id}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <MenuCard
                            index={index}
                            searchResults={searchResults}
                            setSearch={setSearch}
                            filter={filter}
                            tab={tab}
                            category_idx={getCardData(item).category_idx}
                            name={getCardData(item).name}
                            key={index}
                            id={getCardData(item).id}
                            select={header}
                            handleClose={handleClose}
                            isAvailable={item?.isAvailable}
                            initialRender={initialRender}
                          />{" "}
                        </div>
                      )}
                    </Draggable>
                  ))}
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default MenuList;
