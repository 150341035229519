const AnalyticsTable = ({ value, title, page }) => {

    return (
        <div className="table-wrapper">
            <div className="analytics-table">
                <div className="analytics-table-title">{title}</div>
                <div className="analytics-table-content">
                    <table className="analytics-table-table">
                        <thead>
                            <tr>
                                <th>S No.</th>
                                <th>{page === 'customers' ? 'Customer Name' : page === 'items' ? 'Item Name' : page === 'tables' ? 'Table Name' : 'Order Type'}</th>
                                <th>{page === 'customers' ? 'Order Count' : page === 'items' ? 'Item Count' : page === 'tables' ? 'Order Count' : 'Order Count'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                value.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{page === 'customers' ? item?.customer_name : page === 'items' ? item?.item_name : page === 'tables' ? item?.table_name : item?.order_type}</td>
                                            <td>{page === 'customers' ? item?.order_count : page === 'items' ? item?.item_count : page === 'tables' ? item?.order_count : item?.order_count}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AnalyticsTable;