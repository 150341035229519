/** @format */

import React, { useEffect } from "react";
import congratulations from "../../assets/gifs/congratulations.gif";
import Sucess from "canvas-confetti";

const Confetti = () => {
   useEffect(() => {
      Sucess({
         particleCount: 200,
         spread: 40,
         origin: { y: 0.6, x: 0.5499 },
         zIndex: 100,
      });
   }, []);

   return (
      <img
         style={{ width: "60vw", height: "20vh" }}
         src={congratulations}
         onAbortCapture={congratulations}
      />
   );
};

export default Confetti;
