/** @format */

import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate, useLocation } from "react-router-dom";

function handleClick(event) {
   event.preventDefault();
   console.info("You clicked a breadcrumb.");
}

export default function ActiveLastBreadcrumb({ data }) {
   const navigate = useNavigate();
   const path = useLocation().pathname;

   return (
      <div
         role="presentation"
         onClick={handleClick}>
         <Breadcrumbs aria-label="breadcrumb">
            {data.map((item, index) => {
               return (
                  <div
                     onClick={() => navigate(item.path)}
                     className={`fw-bold fs-4 text-[${path === item.path ? "#000" : "#c4c4ca"}] hover:underline cursor-pointer`}>
                     {item.title}
                  </div>
               );
            })}
         </Breadcrumbs>
      </div>
   );
}
