import { checkIfTokenValid } from "utils/functions";

const API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/signup`;
const CHECK_MERCHANT_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/`;


export const SEND_OTP = async ( body ) => {
    try
    {
        const response = await fetch( `${API_ENDPOINT}/send_otp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify( body )
        } );
        return response;
    } catch ( error )
    {
        return error;
    }
};



export const SEND_EMAIL = async ( body, token ) => {
    try
    {
        const response = await fetch( `${API_ENDPOINT}/send_email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify( body )
        } );
        return response;
    } catch ( error )
    {
        return error;
    }
};

export const VERIFY_OTP = async ( body ) => {
    try
    {
        const response = await fetch( `${API_ENDPOINT}/verify_otp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify( body )
        } );
        return response;
    } catch ( error )
    {
        return error;
    }
}


export const VERIFY_MERCHANT = async () => {
    try
    {
        const response = await fetch( CHECK_MERCHANT_API_ENDPOINT, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem( "savedT" )}`,
                "ngrok-skip-browser-warning": "any",
            },
        } );

        if ( checkIfTokenValid( response.status ) )
        {
            return response;
        }
    } catch ( error )
    {
        return error;
    }
};
