import React from "react";

function CustomModalCancel() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5156 15.5156L32.4862 32.4862"
        stroke="#0F191A"
        stroke-width="1.81818"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5138 32.4862L32.4844 15.5156"
        stroke="#0F191A"
        stroke-width="1.81818"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CustomModalCancel;
