/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";

const ANALYTICS_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/analytics/`;

export const GetAnalytics = createAsyncThunk(`GetAnalytics`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(ANALYTICS_API_ENDPOINT, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(data),
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't  fetch the analytics");
      }
   } catch (error) {
      throw error;
   }
});
