import { createSlice } from '@reduxjs/toolkit';
import { createCombo, getAllCombos, getSingleCombo } from 'store/action/CombosAction';

const initialState = {
  combos: [],
  singleCombo: {},
};

const comboReducer = createSlice({
  name: 'comboReducer',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllCombos.fulfilled]: (state, { payload }) => {
      console.log('+++ COMBOS PAYLOAD +++', payload);
      state.combos = payload;
    },
    [createCombo.fulfilled]: (state, { payload }) => {
      console.log('+++ CREATED COMBO PAYLOAD', payload);
    },
    [getSingleCombo.fulfilled]: (state, { payload }) => {
      console.log('+++ COMBOS PAYLOAD +++', payload);
      state.singleCombo = payload;
    },
  },
});

export const {} = comboReducer.actions;

export default comboReducer.reducer;
