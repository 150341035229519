/** @format */

import React from "react";
import CreatableSelect from "react-select/creatable";
import { useRef } from "react";

export default function Component({ options, placeholder, onChange, onCreate }) {
   const dropDownRef = useRef();

   const customStyles = {
      control: (provided, state) => ({
         ...provided,
         borderColor: state.isFocused ? "green" : provided.borderColor,
         boxShadow: state.isFocused ? "0 0 0 1px green" : provided.boxShadow,
         "&:hover": {
            borderColor: state.isFocused ? "green" : provided.borderColor,
         },
      }),
   };

   const onSelectDropdown = (obj) => {
      if (obj !== null) {
         if (obj?.__isNew__) {
            onCreate(obj);
         } else {
            onChange(obj);
         }
      }
      dropDownRef.current?.clearValue();
   };

   console.log(dropDownRef.current, "dropDownRef");

   return (
      <CreatableSelect
         ref={dropDownRef}
         styles={customStyles}
         isClearable
         options={options}
         placeholder={placeholder}
         onChange={(val) => onSelectDropdown(val)}
      />
   );
}
