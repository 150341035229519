/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";

const WAITER_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/waiter`;
const GET_WAITER_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/waiter`;

export const CreateWaiter = createAsyncThunk(`CreateWaiter`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(WAITER_API_ENDPOINT, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(data),
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't  fetch the Waiter");
      }
   } catch (error) {
      throw error;
   }
});

export const GetWaiter = createAsyncThunk(`GetWaiter`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(WAITER_API_ENDPOINT, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't fetch the Waiter");
      }
   } catch (error) {
      throw error;
   }
});

export const UpdateWaiter = createAsyncThunk(`UpdateWaiter`, async (data, { rejectWithValue }) => {
   console.log(data, "WAITERDATA");
   console.log(data.updatedData, "WAITERDATA");
   try {
      const response = await fetch(`${WAITER_API_ENDPOINT}/${data.waiterId}`, {
         method: "PUT",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(data.updatedData),
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't update waiter");
      }
   } catch (error) {
      throw error;
   }
});

export const DeleteWaiter = createAsyncThunk(`DeleteWaiter`, async (data, { rejectWithValue }) => {
   console.log(data.waiterId, "DELETEAPI");
   try {
      const response = await fetch(`${WAITER_API_ENDPOINT}/${data.waiterId}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });
      if (response.status == 201 || response.status == 200) {
         const resData = await response.json();
         return resData;
      } else {
         return rejectWithValue("Can't delete category");
      }
   } catch (error) {
      throw error;
   }
});
