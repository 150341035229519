/** @format */

import React from "react";

export default function OrderActionCard({ title, selected, onClick }) {
   return (
      <div
         onClick={onClick}
         className={`hover:bg-[#2D6B4D] bg-[${selected ? "#2D6B4D" : "#fff"}] w-[30%] p-3 hover:text-[#fff] text-[${
            selected ? "#fff" : "#000"
         }]  text-center text-[1.1rem] cursor-pointer border-[1px] shadow-sm rounded-[0.4rem] border-[#c4c4ca] border-solid`}>
         {title}
      </div>
   );
}
