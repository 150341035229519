/** @format */

import React from "react";
import "./index.scss";
const Index = (props) => {
   const { title, width, height, icon, onClick, background, Size, disabled, fontWeight, color, border, borderRadius, padding, margin } = props;

   return (
      <button
         style={{
            backgroundColor: background,
            color: color,
            border: border,
            borderRadius: borderRadius,
            fontWeight: fontWeight,
            opacity: disabled && "0.5",
            width: width,
            height: height,
            padding: padding,
            margin: margin,
         }}
         className={(Size === "sm" && "button2Container Btn2sm") || (Size === "md" && "button2Container Btn2md") || (Size === "xs" && "button2Container Btn2xs") || "button2Container"}
         onClick={onClick}
         {...props}
         disabled={disabled}>
         {icon}
         {title}
      </button>
   );
};

export default Index;
