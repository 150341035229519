/** @format */

import { MenuItem } from "@mui/material";
import "@popperjs/core";
import BillIcon from "assets/icons/BillIcon.svg";
import PrintIcon from "assets/icons/PrintIcon.svg";
import "bootstrap";
import { DeleteModal } from "components/DeleteModal";
import DineInOrderCard from "components/DineInOrderCard";
import OrderDropdown from "components/DropDown";
import InputModal from "components/InputModal";
import Loader from "components/Loader";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getAllCredits } from "store/action/CreditsAction";
import { GetMerchant } from "store/action/MerchantAction";
import {
  GenerateBill,
  UpdateGuest,
  UpdateOrder,
} from "store/action/OrderAction";
import { getCurrency } from "utils/functions";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import PrintOrder from "../PrintOrder";
import "./style.scss";
import ReactTimeAgo from "react-time-ago";
import { formatToTwoDecimalPlaces } from "utils/functions";

function Order(props) {
  const componentRef = useRef();
  const { individualOrder } = useSelector((state) => state.OrderReducer);
  console.log("INDIVIDUAL ORDER ", individualOrder);
  const printRef = useRef(null);
  const promiseResolveRef = useRef(null);

  const [showPrintOrder, setshowPrintOrder] = useState({
    isKOT: false,
    printOrder: false,
  });

  const [isPrinting, setIsPrinting] = useState(false);
  const [toOrderStatus, settoOrderStatus] = useState("");
  const [suborderStatus, setSubOrderStatus] = useState("");
  const [loading, setloading] = useState(false);
  const [isSuborderCancel, setSuborderCancel] = useState(false);
  const [merchantDetails, setmerchantDetails] = useState(null);
  const [orderButtonText, setorderButtonText] = useState("");
  const [orderSuccessMessage, setorderSuccessMessage] = useState("");
  const [orderMessage, setorderMessage] = useState("");
  const [addGuest, setaddGuest] = useState(false);
  const [orderToPrint, setorderToPrint] = useState(null);
  const dispatch = useDispatch();
  const closeDetails = (e) => {
    document.getElementById("side-wrapper").classList.add("close");
    document.getElementById("side-wrapper").classList.remove("open");
  };
  console.log(props, "ORDER props");
  const handleStockChange = (e) => {
    props.setOrders(
      props.orders.map((order) => {
        if (order.id === individualOrder?.id) {
          order.items[e.target.dataset.index].in_stock =
            !order.items[e.target.dataset.index].in_stock;
        }
        return order;
      })
    );
  };

  const allInStock = individualOrder?.items_ordered?.every(
    (item) => item.in_stock
  );
  let date = new Date(individualOrder?.datetime);
  let options = { day: "numeric", month: "long", year: "numeric" };
  let timeOptions = { hour: "numeric", minute: "numeric" };
  let formattedDate = date.toLocaleDateString("en-IN", options);
  let formattedTime = date
    .toLocaleTimeString("en-IN", timeOptions)
    .toUpperCase();
  let total = 0;
  individualOrder?.items_ordered?.forEach((item) => {
    total += item.price * item.quantity;
  });

  console.log("ORDER_STATUS ", individualOrder?.order_status);
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);
  const UPDATE_ORDER = async () => {
    setloading(true);
    const payloadData = { ...individualOrder, order_status: toOrderStatus };
    const updateOrder = await dispatch(
      UpdateOrder({ payload: payloadData, id: individualOrder.order_id })
    );
    if (updateOrder.type === "UpdateOrder/rejected") {
      toast.error(updateOrder?.payload?.detail);
    }

    setVisible(false);
    setloading(false);
  };

  const fetchMerchant = async () => {
    const result = await dispatch(GetMerchant());
    setmerchantDetails(result?.payload);
  };

  const getCurrentCredits = async () => {
    await dispatch(getAllCredits());
  };

  useEffect(() => {
    fetchMerchant();
  }, []);

  const getCancelledSubOrderTotal = (item) => {
    console.log(item, "SubOrderID");
    let total = 0;
    let afterTaxes = 0;
    if (item?.suborder_status === "cancelled") {
      item?.suborder_items_ordered?.forEach((subItem) => {
        return (total = total + subItem?.total_price);
      });
      afterTaxes =
        total +
        total * (individualOrder?.cgst_percentage * 0.01) +
        total * (individualOrder?.sgst_percentage * 0.01);
    }

    console.log("CancelledTotal", total);

    return {
      total: total,
      totalAfterTaxes: afterTaxes,
    };
  };
  const changeSubOrderStatus = (id) => {
    if (id === undefined || id === null || id === "") {
      toast.error("Can't able to update order ");
    } else {
      let allSubOrders = [...individualOrder?.items_ordered];
      let index = allSubOrders?.findIndex((item) => item.suborder_id === id);
      const subOrderData = {
        ...allSubOrders[index],
        suborder_status: isSuborderCancel
          ? "cancelled"
          : allSubOrders[index]?.suborder_status === "order_confirmed"
          ? "start_preparation"
          : allSubOrders[index]?.suborder_status === "start_preparation"
          ? "ready_to_serve"
          : "order_confirmed",
      };

      allSubOrders.splice(index, 1);
      allSubOrders.splice(index, 0, subOrderData);

      return {
        allSubOrders: allSubOrders,
        amount: getCancelledSubOrderTotal(subOrderData),
        suborder: subOrderData,
      };
    }
  };
  const UPDATE_SUB_ORDER = async (id) => {
    setloading(true);
    let amount = changeSubOrderStatus(id)?.amount;
    console.log("SUB_AMOUNT", amount);
    console.log("SUB_STAT", suborderStatus);
    const payloadData = {
      ...individualOrder,
      items_ordered: changeSubOrderStatus(id)?.allSubOrders,
      order_status: "order_updated",
      total_amount:
        individualOrder?.total_amount -
        (amount?.total !== NaN || amount?.total > 0 ? amount?.total : 0),
      amount_after_taxes: isSuborderCancel
        ? individualOrder?.amount_after_taxes
        : individualOrder?.amount_after_taxes -
          (amount?.totalAfterTaxes !== NaN || amount?.total > 0
            ? amount?.totalAfterTaxes
            : 0),
      rounded_off:
        individualOrder?.rounded_off === null
          ? "0.00"
          : individualOrder?.rounded_off,
    };
    console.log("UPDATE_SUB_PAYLOAD", payloadData);
    const result = await dispatch(
      UpdateOrder({ payload: payloadData, id: individualOrder.order_id })
    );
    console.log("UPDATE_SUBORDER_RESULT", result);
    if (result.type === "UpdateOrder/fulfilled") {
      if (changeSubOrderStatus(id)?.suborder?.suborder_status === "cancelled") {
        toast.success("Order is cancelled successfully");
      } else if (
        changeSubOrderStatus(id)?.suborder?.suborder_status ===
        "order_confirmed"
      ) {
        toast.success("Order is confirmed successfully");
      } else if (
        changeSubOrderStatus(id)?.suborder?.suborder_status ===
        "start_preparation"
      ) {
        toast.success("Started preparation for the order successfully");
      } else {
        toast.success("Order is served successfully");
      }
      getCurrentCredits();
    } else {
      toast.error(result?.payload?.detail);
    }
    console.log("UPDATE_SUBORDER_RESULT", result);

    setSuborderCancel(false);
    setVisible(false);
    setloading(false);
  };

  useEffect(() => {
    buttonText();
  }, [individualOrder]);

  const buttonText = () => {
    const orderStatus = individualOrder?.order_status;
    const orderType = individualOrder?.order_type;
    if (
      orderType === "Dine-in" &&
      (orderStatus === "order_confirmed" ||
        orderStatus === "new_order" ||
        orderStatus === "order_updated")
    ) {
      settoOrderStatus("order_completed");
      setorderButtonText("Complete order ");
      setorderSuccessMessage("Order is completed successfully");
    } else if (orderStatus === "new_order" || orderStatus === "order_updated") {
      console.log("NEW ORDER BTN");
      settoOrderStatus("order_confirmed");
      setorderButtonText("Accept order");
      setorderSuccessMessage("Order is confirmed successfully");
    } else if (orderStatus === "order_confirmed") {
      settoOrderStatus("start_preparation");
      setorderButtonText("Start preparation");
      setorderSuccessMessage("Order preparation is started successfully");
    } else if (orderStatus === "start_preparation")
      if (orderType === "Take away" || orderType === "Manual") {
        settoOrderStatus("order_completed");
        setorderButtonText("Complete order");
        setorderSuccessMessage("Order is completed successfully");
      } else {
        settoOrderStatus("order_dispatched");
        setorderButtonText("Dispatch order");
        setorderSuccessMessage("Order is dispatched successfully");
      }
    else if (orderStatus === "order_dispatched") {
      settoOrderStatus("order_delivered");
      setorderButtonText("Deliver order");
      setorderSuccessMessage("Order is delivered successfully");
    } else if (orderStatus === "order_delivered") {
      settoOrderStatus("order_completed");
      setorderSuccessMessage("Order is completed successfully");
      setorderButtonText("Complete order");
    } else if (orderStatus === "order_completed") {
      setorderButtonText("Complete order");
    }
  };

  const closeOverlay = () => {
    closeDetails();
  };

  useEffect(() => {
    if (individualOrder?.order_type === "Dine-in") {
      GenerateBillForDineIn();
    } else {
      setorderToPrint(getSubOrderedItems());
    }
  }, [individualOrder]);

  const GenerateBillForDineIn = async () => {
    try {
      const result = await GenerateBill(individualOrder?.order_id);
      console.log("GenerateBillForDineIn", result);
      setorderToPrint(result);
    } catch (error) {
      console.log(error);
    }
  };

  //FUNCTION TO CLOSE OVERLAY USING ESC KEY
  useEffect(() => {
    const keyDownHandler = (event) => {
      console.log("User pressed: ", event.key);

      if (event.key === "Escape") {
        event.preventDefault();
        setshowPrintOrder(false);
        closeOverlay();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  console.log("ORDERStaus", individualOrder?.order_status);
  console.log("ORDER BTN", orderButtonText);

  const [open, setOpen] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isCancelModalVisible, setCancelModalVisible] = useState(false);
  const [paymentData, setPaymentData] = useState({
    payment_method: "",
    notes: "",
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkIsCancelledSubOrder = () => {
    let cancelled = [];
    let notCancelled = [];
    itemsOrdered?.map((i) => {
      if (i.suborder_status === "cancelled") {
        cancelled.push(i);
      } else {
        notCancelled.push(i);
      }
    });

    if (notCancelled.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkDineInSubOrderCompleted = () => {
    let completed = [];
    const totalSubOrders = itemsOrdered?.length;
    itemsOrdered?.map((i) => {
      if (
        i.suborder_status === "cancelled" ||
        i.suborder_status === "ready_to_serve" ||
        i.suborder_status === "start_preparation"
      ) {
        completed.push(i);
      }
    });
    if (totalSubOrders === completed.length) {
      console.log("Can Complete Dine in Order");
      return true;
    } else {
      return false;
    }
  };

  const [guestsValue, setguestsValue] = useState(individualOrder?.no_of_guests);
  const [edit, setedit] = useState(false);
  const onPressEnter = (e) => {
    const payloadData = { no_of_guests: guestsValue };
    console.log("onPressEnter", payloadData);
    dispatch(
      UpdateGuest({ payload: payloadData, order_id: individualOrder.order_id })
    );
  };
  const handleCancelOrder = async () => {
    console.log("CANCEL PAYmEnt ___PAYLoAd");
    setloading(true);
    const payloadData = { ...individualOrder, order_status: "cancelled" };
    await dispatch(
      UpdateOrder({ payload: payloadData, id: individualOrder.order_id })
    );
    setCancelModalVisible(false);
    setloading(false);
  };

  const handleCompletePayment = async () => {
    setloading(true);
    if (
      paymentData?.payment_method !== undefined &&
      paymentData?.payment_method !== ""
    ) {
      const payloadData = {
        ...individualOrder,
        payment_status: "paid",
        payment_method: paymentData?.payment_method,
        payment_notes: paymentData?.notes === "" ? "" : paymentData?.notes,
        order_status: "order_completed",
      };

      console.log("COMPLETE PAYmEnt ___PAYLoAd", payloadData);
      const result = await dispatch(
        UpdateOrder({ payload: payloadData, id: individualOrder.order_id })
      );
      if (result.type === "UpdateOrder/fulfilled") {
        setOpen(false);
        setloading(false);
        toast.success("Payment status updated and order completed");
        document.getElementById("side-wrapper").classList.add("close");
        document.getElementById("side-wrapper").classList.remove("open");
      }
    }
    setloading(false);
  };

  const showOrderStatus = (value) => {
    if (value === "new_order") {
      return { status: "New order", className: "newOrder" };
    } else if (value === "order_confirmed") {
      return { status: "Confirmed", className: "orderConfirmed" };
    } else if (value === "start_preparation") {
      return { status: "Start preparation", className: "orderCompleted" };
    } else if (value === "ready_to_serve") {
      return { status: "Ready to serve", className: "orderCompleted" };
    } else if (value === "cancelled") {
      return { status: "Cancelled", className: "cancelled" };
    }
  };

  const itemsOrdered = individualOrder?.items_ordered;

  useEffect(() => {
    setguestsValue(individualOrder?.no_of_guests);
  }, [individualOrder]);

  const getSubOrderedItems = () => {
    if (individualOrder?.items_ordered) {
      return individualOrder?.items_ordered[0]?.suborder_items_ordered;
    }
    return [];
  };

  const [waiterMode, setWaiterMode] = useState(false);

  useEffect(() => {
    const waiterModeFromStorage =
      localStorage.getItem("waiter_mode") === "true";
    setWaiterMode(waiterModeFromStorage);
  }, []);

  return (
    <>
      <Loader isLoading={loading} />
      <InputModal
        setPaymentData={setPaymentData}
        paymentData={paymentData}
        handleClose={handleClose}
        open={open}
        handleClick={handleCompletePayment}
      />
      <DeleteModal
        custom
        BtnBg={"#2FBF71"}
        Btntitle={"Yes"}
        message={`Are you sure you want to ${orderButtonText
          .toLowerCase()
          .replace(" ", " this ")} ?`}
        isVisible={isVisible}
        onClose={setVisible}
        handleClick={UPDATE_ORDER}
      />
      <DeleteModal
        custom
        BtnBg={"#D90429"}
        Btntitle={"Yes"}
        message={<>Are you sure you want to cancel this order ?</>}
        isVisible={isCancelModalVisible}
        onClose={setCancelModalVisible}
        handleClick={handleCancelOrder}
      />

      <div id="side-wrapper" className="d-flex justify-content-end close">
        <PrintOrder
          ref={printRef}
          data={{
            ...individualOrder,
            items_ordered: getSubOrderedItems(),
            restaurant_name: merchantDetails?.restaurant_name,
          }}
          visible={showPrintOrder.printOrder}
          printKOT={showPrintOrder.isKOT}
          onClose={() => {
            setshowPrintOrder({
              ...showPrintOrder,
              printOrder: false,
            });
          }}
        />

        <div className="overlay" onClick={closeDetails}></div>
        <div onClick={closeDetails}>
          <button id="close" className="fs-3 m-4" onClick={closeDetails}>
            ×
          </button>
        </div>
        <div
          id="order-side"
          className="d-flex flex-column justify-content-start p-3"
        >
          <div>
            <div className=" d-flex align-items-center justify-content-between ">
              <div className="d-flex  justify-content-center w-100">
                <h4 className="text-center">Order details</h4>
              </div>

              <OrderDropdown>
                <MenuItem
                  disabled={
                    individualOrder?.order_status !== "order_completed" &&
                    individualOrder?.order_type === "Dine-in" &&
                    individualOrder?.order_status !== "cancelled" &&
                    individualOrder?.payment_status === "un-paid" &&
                    individualOrder?.order_status === "order_confirmed"
                      ? false
                      : true
                  }
                  onClick={handleClickOpen}
                >
                  Complete payment
                </MenuItem>
                <MenuItem
                  disabled={
                    (individualOrder?.order_status !== "cancelled" &&
                      individualOrder?.payment_status === "un-paid" &&
                      individualOrder?.order_status !== "order_completed" &&
                      individualOrder?.order_status !== "order_updated" &&
                      individualOrder?.order_status !== "order_confirmed") ||
                    (individualOrder?.order_type === "Dine-in" &&
                      checkIsCancelledSubOrder() === true)
                      ? false
                      : true
                  }
                  onClick={() => {
                    setCancelModalVisible(true);
                  }}
                >
                  Cancel order
                </MenuItem>
              </OrderDropdown>
            </div>
            <div id="highlighted" className="row w-100 m-0">
              <div
                className="col-12 d-flex justify-content-around p-0 m-0"
                id="order_id"
              >
                <div className=" col-4 d-flex flex-column align-items-center justify-content-evenly ">
                  <div className="slight-faded">ORDER ID</div>
                  <div className="fw-bold">{individualOrder?.order_id}</div>
                </div>
                <div className="col-4 d-flex flex-column align-items-center justify-content-evenly ">
                  <div className="slight-faded">ORDER TYPE</div>
                  <div className={individualOrder?.order_type + " fw-bold"}>
                    {individualOrder?.order_type}
                  </div>
                </div>
                <div className="col-4 d-flex flex-column align-items-center justify-content-between ">
                  <div>Table</div>
                  <div className="fs-4 fw-bold">
                    {individualOrder?.qr_code_identifier
                      ? individualOrder?.qr_code_identifier
                      : individualOrder?.table_number !== "Unknown"
                      ? individualOrder?.table_number
                      : "N/A"}
                  </div>
                </div>
              </div>
            </div>

            <div id="padded">
              <div id="row-det" className="row row-cols-2 mx-0">
                {/* <div>
                  <span>Name</span>
                  <span>:</span>
                  <span>{individualOrder?.customer_name}</span>
                </div> */}
                <div>
                  <span>Date</span>
                  <span>:</span>
                  <span>{individualOrder?.order_date}</span>
                </div>
                {/* <div>
                  <span>Contact</span>
                  <span>:</span>
                  <span>{individualOrder?.phone_number}</span>
                </div> */}
                <div>
                  <span>Time</span>
                  <span>:</span>
                  <span>{individualOrder?.order_time}</span>
                </div>
                {/* <div>
                <span>Visits</span>
                <span>:</span>
                <span>{individualOrder?.order_count}</span>
              </div> */}
                <div>
                  <div>Payment status</div>
                  <span>:</span>
                  <span>{individualOrder?.payment_status}</span>
                </div>
                <div>
                  <div>Waiter Name</div>
                  <span>:</span>
                  <span>{individualOrder?.waiter_name}</span>
                </div>

                {individualOrder?.payment_notes && (
                  <div>
                    <div>Payment notes</div>
                    <span>:</span>
                    <span>{individualOrder?.payment_notes}</span>
                  </div>
                )}
                {individualOrder?.order_type === "Dine-in" && (
                  <div className="no-of-guest-info-wrapper">
                    <div>No of guests</div>
                    <div>:</div>
                    <>
                      {edit ? (
                        <input
                          autoFocus={true}
                          type="number"
                          value={guestsValue}
                          onChange={(e) => setguestsValue(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setedit(false);
                              onPressEnter();
                            }
                          }}
                        />
                      ) : (
                        <div
                          style={{ cursor: "text" }}
                          onDoubleClickCapture={() => setedit(true)}
                        >
                          {guestsValue}
                        </div>
                      )}
                      <>
                        <div
                          style={{
                            cursor: "pointer",
                            marginLeft: edit ? "0rem" : "1rem",
                          }}
                          onClick={() => {
                            if (edit) {
                              onPressEnter();
                              setedit(false);
                            } else {
                              setedit(true);
                            }
                          }}
                          className="guest-btn"
                        >
                          {edit
                            ? "Save"
                            : guestsValue > 0
                            ? "Modify"
                            : "- Add guests"}
                        </div>
                      </>
                    </>
                  </div>
                )}
              </div>

              <table id="items-table" className="w-100">
                {individualOrder?.order_type === "Dine-in" ? (
                  <div className="dine-in-order-items-container">
                    <div className="dine-in-order-card-main-container">
                      {individualOrder.items_ordered?.map((item, index) => {
                        return (
                          <>
                            <DineInOrderCard
                              data={individualOrder}
                              key={index}
                              showOrderStatus={showOrderStatus}
                              handleUpdateSubOrder={UPDATE_SUB_ORDER}
                              item={item}
                              index={index}
                              orderDetails={individualOrder}
                              setSuborderCancel={setSuborderCancel}
                              isSuborderCancel={isSuborderCancel}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <>
                    <thead>
                      <tr>
                        <th scope="col">ITEM NAMES</th>
                        <th
                          className=" td-card-item-name text-center"
                          scope="col"
                        >
                          QTY
                        </th>
                        <th
                          className="td-card-item-name text-center"
                          scope="col"
                        >
                          RATE
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getSubOrderedItems()?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.item_name}</td>
                          <td className="text-center">{item?.count}</td>
                          <td className="text-center">
                            {getCurrency(merchantDetails?.currency)}{" "}
                            {item.price}
                          </td>
                        </tr>
                      ))}
                      {getSubOrderedItems()?.map((item, index) => (
                        <>
                          {item?.addons?.map((addon) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {addon.item_name}{" "}
                                  <span className="add-on-tag">Addons</span>
                                </td>
                                <td className="text-center">{addon?.count}</td>
                                <td className="text-center">
                                  {getCurrency(merchantDetails?.currency)}{" "}
                                  {addon.price}
                                </td>
                              </tr>
                            );
                          })}
                          {item?.customizations?.map((customization) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {customization.customisation_title}{" "}
                                  <span className="customization-tag">
                                    Customization
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ))}
                    </tbody>
                  </>
                )}
                <tbody id="bottom-table-body">
                  <div
                    style={{
                      marginTop: "4rem",
                    }}
                  ></div>
                  <tr>
                    <td>Total Amount</td>
                    <td className="text-center"></td>
                    <td className="text-center">
                      {getCurrency(merchantDetails?.currency)}{" "}
                      {individualOrder?.total_amount}
                    </td>
                  </tr>
                  {individualOrder?.cgst_percentage !== 0 && (
                    <tr>
                      <td>CGST</td>
                      <td className="text-center"></td>
                      <td className="text-center">
                        {individualOrder?.cgst_percentage} %
                      </td>
                    </tr>
                  )}{" "}
                  {individualOrder?.sgst_percentage !== 0 && (
                    <tr>
                      <td>SGST</td>
                      <td className="text-center"></td>
                      <td className="text-center">
                        {individualOrder?.sgst_percentage} %
                      </td>
                    </tr>
                  )}
                  {/* <tr>
                    <td>Rounded off</td>
                    <td className="text-center"></td>
                    <td className="text-center">
                      {individualOrder?.rounded_off}
                    </td>
                  </tr> */}
                  <tr
                    style={{
                      borderTop: "0.2px solid #477E63",
                      borderBottom: "0.2px solid #477E63",
                      padding: "1%",
                    }}
                  >
                    <td
                      style={{
                        width: "80%",
                        color: "#2D6B4D",
                      }}
                      className="bottom-table-total-amount"
                    >
                      Total Items
                    </td>
                    <td
                      style={{
                        color: "#4361ee",
                        width: "7%",
                        padding: 0,
                        textAlign:
                          individualOrder?.order_type === "Dine-in"
                            ? ""
                            : "center",
                      }}
                      className="bottom-table-total-items "
                    >
                      {individualOrder?.total_items}
                    </td>
                    <td
                      style={{
                        color: "#2D6B4D",
                        width: "25%",
                        textAlign: "center",
                        padding: 0,
                        paddingRight: "2%",
                      }}
                      className="bottom-table-total-items "
                    >
                      {getCurrency(merchantDetails?.currency)}{" "}
                      {formatToTwoDecimalPlaces(
                        individualOrder?.amount_after_taxes
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            className={
              "p-4 mt-auto row row-3 mx-0 w-100 d-flex align-items-center justify-content-center"
            }
          >
            {individualOrder?.order_status !== "new_order" ? (
              <>
                <div
                  className="cursor-pointer col-4 "
                  style={{
                    display: checkIsCancelledSubOrder() === true ? "none" : "",
                  }}
                >
                  <div
                    onClick={() => {
                      setshowPrintOrder({
                        isKOT: false,
                        printOrder: true,
                      });
                    }}
                    className="p-2 w-100 d-flex align-items-center justify-content-center b-btn-h"
                  >
                    <img className="btn-icon" src={PrintIcon} />
                    <button className="btn-text">Print Bill</button>
                  </div>
                </div>
              </>
            ) : (
              <div></div>
            )}
            <div className="p-2 cursor-pointer col-4">
              <button
                style={{
                  opacity:
                    individualOrder?.order_status === "order_completed" ||
                    individualOrder?.order_status === "cancelled" ||
                    (individualOrder?.order_type === "Dine-in" &&
                      checkDineInSubOrderCompleted() !== true)
                      ? 0.5
                      : 1,
                  width: "100%",
                }}
                onClick={() => {
                  if (
                    (individualOrder?.order_type === "Dine-in" &&
                      individualOrder?.payment_status === "un-paid") ||
                    (individualOrder?.order_type === "Manual" &&
                      individualOrder?.order_status === "start_preparation")
                  ) {
                    handleClickOpen();
                  } else {
                    setVisible(true);
                  }
                }}
                disabled={
                  individualOrder?.order_status === "order_completed" ||
                  individualOrder?.order_status === "cancelled" ||
                  (individualOrder?.order_type === "Dine-in" &&
                    checkDineInSubOrderCompleted() !== true)
                    ? true
                    : false
                }
                className={
                  individualOrder?.order_status === "cancelled"
                    ? "w-100 center cancelled-btn"
                    : "w-100 center g-btn col-3 "
                }
              >
                {individualOrder?.order_status === "cancelled"
                  ? "Order cancelled"
                  : orderButtonText}
              </button>
            </div>

            {individualOrder?.order_status !== "new_order" ? (
              <div
                className="p-2 cursor-pointer col-4 "
                style={{
                  display:
                    individualOrder?.order_type === "Dine-in" ? "none" : "",
                }}
              >
                {!waiterMode && (
                  <div
                    style={{
                      opacity:
                        individualOrder?.order_status === "order_completed" ||
                        individualOrder?.order_type === "Dine-in"
                          ? 0.5
                          : 1,
                      cursor:
                        individualOrder?.order_status === "order_completed" ||
                        individualOrder?.order_type === "Dine-in"
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={() => {
                      individualOrder?.order_status !== "order_completed" &&
                        setshowPrintOrder({
                          isKOT: true,
                          printOrder: true,
                        });
                    }}
                    className="w-100  d-flex align-items-center justify-content-center b-btn-h"
                  >
                    <img className="btn-icon" src={BillIcon} />
                    <button
                      className="btn-text"
                      disabled={
                        individualOrder?.order_status === "order_completed" ||
                        individualOrder?.order_type === "Dine-in"
                          ? true
                          : false
                      }
                    >
                      Print KOT
                    </button>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Order;
