/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";

const LOYALTY_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/loyalty/`;

export const EnableLoyalty = createAsyncThunk(`EnableLoyalty`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${LOYALTY_API_ENDPOINT}enable_loyalty`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(data),
      });
      if (response.status === 201 || response.status === 200) {
         const resData = await response.json();
         console.log("ENABLE LOYALTY PAYLOAD => ", resData);
         return resData;
      } else {
         return rejectWithValue("Can't Enable Loyalty");
      }
   } catch (error) {
      throw error;
   }
});

export const GetLoyaltyDetails = createAsyncThunk(`GetLoyaltyDetails`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${LOYALTY_API_ENDPOINT}enable_loyalty`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });

      if (response.status === 200 || response.status === 201) {
         const resData = await response.json();
         console.log("GET LOYALTY DETAILS PAYLOAD => ", resData);
         return resData;
      } else {
         return rejectWithValue("Can't Get Loyalty Details");
      }
   } catch (error) {
      throw error;
   }
});

export const GetLoyaltyRules = createAsyncThunk(`GetLoyaltyRules`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${LOYALTY_API_ENDPOINT}loyalty_rules`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
      });

      if (response.status === 200 || response.status === 201) {
         const resData = await response.json();
         console.log("GET LOYALTY RULES PAYLOAD => ", resData);
         return resData;
      } else {
         return rejectWithValue("Can't Get Loyalty Rules");
      }
   } catch (error) {
      throw error;
   }
});

export const CreateLoyaltyRules = createAsyncThunk(`CreateLoyaltyRules`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${LOYALTY_API_ENDPOINT}loyalty_rules`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(data),
      });

      if (response.status === 200 || response.status === 201) {
         const resData = await response.json();
         console.log("CREATE LOYALTY RULES PAYLOAD => ", resData);
         return resData;
      } else {
         return rejectWithValue("Can't Create Loyalty Rules");
      }
   } catch (error) {
      throw error;
   }
});

export const EditLoyaltyRules = createAsyncThunk(`EditLoyaltyRules`, async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${LOYALTY_API_ENDPOINT}loyalty_rules`, {
         method: "PUT",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("savedT")}`,
            "ngrok-skip-browser-warning": "any",
         },
         body: JSON.stringify(data),
      });

      if (response.status === 200 || response.status === 201) {
         const resData = await response.json();
         console.log("EDIT LOYALTY RULES PAYLOAD => ", resData);
         return resData;
      } else {
         return rejectWithValue("Can't Edit Loyalty Rules");
      }
   } catch (error) {
      throw error;
   }
});
