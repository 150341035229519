/** @format */

import Button from "components/Button2";
import "./styles.scss";
import EditIcon from "assets/icons/EditIcon";
import DeleteIcon from "assets/icons/DeleteIcon";
import { ToggleSwitch } from "components/ToggleSwitch";
import moment from "moment";

const HourlySpecialCard = ({ item, onEdit, onDelete, onActivate, onDeactivate }) => {
     let itemDate = new Date(item?.dates);
     console.log("item", moment.utc(itemDate)?.format("MMM Do, YYYY"));
     return (
          <div className="hourly-card col-12">
               <div className="col-3">
                    <div className="small-grey">{item?.type === "hourly" ? "Timing:" : "Dates:"}</div>
                    <div>{item.type === "hourly" ? `${new Date(item.start).toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })} to ${new Date(item.end).toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })}` : item?.type_of_day === "day" ? item.dates : moment.utc(item?.dates).format("MMM DD, YYYY")}</div>
               </div>
               <div className="col-4">
                    <div className="small-grey">Item list:</div>
                    <div>
                         {item?.items?.length <= 3 && item.items.map((i, idx) => (idx === item?.items?.length - 1 ? i.item_name : i.item_name + ", "))}
                         {item?.items?.length > 3 && item.items.slice(0, 3).map((i) => i.item_name + ", ")}
                         {item?.items?.length > 3 && <span className="blue">+{item?.items?.length - 3} more</span>}
                    </div>
               </div>
               <div className="d-flex flex-column align-items-center col-3">
                    <div className="small-grey">Show in menu</div>
                    <div>
                         <ToggleSwitch
                              isAvailable={item.is_active}
                              noWidth={true}
                              handleValue={() => {
                                   if (item.is_active) {
                                        onDeactivate();
                                   } else {
                                        onActivate();
                                   }
                              }}
                         />
                    </div>
               </div>
               <div className="buttons-outer col-2 ">
                    <Button
                         Size="sm"
                         title={``}
                         icon={<EditIcon color={"#4361EE"} />}
                         border="none"
                         background="#ECEFFD"
                         color={"#4361EE"}
                         fontWeight="bold"
                         width="auto"
                         padding="0.5rem"
                         onClick={() => onEdit()}
                    />
                    <Button
                         Size="sm"
                         title={``}
                         icon={<DeleteIcon color={"#D90429"} />}
                         border="none"
                         background="#FBE6EA"
                         color={"#D90429"}
                         fontWeight="bold"
                         width="auto"
                         padding="0.5rem"
                         onClick={() => onDelete()}
                    />
               </div>
          </div>
     );
};

export default HourlySpecialCard;
