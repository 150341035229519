/** @format */

import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import Login from "pages/Login";
import Profile from "pages/Profile";
import Menu from "pages/Menu";
import QRCode from "pages/QRCode";
import Recommended from "pages/Recommended/index";
import Orders from "pages/Orders";
import Subscription from "pages/Subscription";
import Analytics from "pages/Analytics";
import Combos from "pages/Combos";
import BestSelling from "pages/BestSelling";
import NewlyAdded from "pages/NewlyAdded";
import OnBoarding from "pages/OnBoarding";
import ManualOrder from "pages/ManualOrder";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Disputes from "pages/Disputes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { GetCurrentSubscription, GetSubscription } from "store/action/SubscriptionAction";
import { GetMerchant } from "store/action/MerchantAction";
import Credits from "pages/Credits";
import Offers from "pages/Offers";
import LoyaltyProgram from "pages/Loyalty";
import { VERIFY_MERCHANT } from "store/action/AuthAction";
import HourlyDailySpecials from "pages/HourlyDailySpecials";
import { getMerchant } from "store/reducer/MerchantReducer";
import Waiter from "pages/Waiter";
const theme = createTheme({
   palette: {
      primary: {
         main: "#2D6B4D",
      },
      secondary: {
         main: "#4361EE",
      },
      white: {
         main: "#FFFFFF",
      },
      black: {
         main: "#000000",
      },
      success: {
         main: "#2E7D32",
      },
   },
});

function App() {
   let path = useLocation().pathname;
   console.log(path, "path");
   const [isActive, setActive] = useState(null);
   const dispatch = useDispatch();
   const [waiterMode, setWaiterMode] = useState(false);

   const removeConsole = () => {
      console.log = () => {};
   };
   const GET_SUBSCRIPTION_DETAILS = async () => {
      const result = await dispatch(GetCurrentSubscription());
      console.log(result);
      if (result?.payload?.plan_active === false) {
         setActive(false);
      } else {
         setActive(true);
      }
      console.log(result, "GET_SUBSCRIPTION_DETAILS");
   };

   const GET_MERCHANT_DETAILS = () => dispatch(GetMerchant());

   useEffect(() => {
      if (process.env.REACT_APP_ENVIRONMENT === "production") {
         removeConsole();
      }
      GET_SUBSCRIPTION_DETAILS();
      GET_MERCHANT_DETAILS();
      if (path != "/onboarding" && path != "/") {
         VERIFY_MERCHANT();
      }
   }, [path]);

   useEffect(() => {
      const waiterModeFromStorage = localStorage.getItem("waiter_mode") === "true";
      setWaiterMode(waiterModeFromStorage);
   }, []);

   return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
         <ThemeProvider theme={theme}>
            <Routes>
               <Route
                  exact
                  path="/"
                  element={<Login />}
               />
               {waiterMode ? (
                  <>
                     <Route
                        exact
                        path="/orders"
                        element={<Orders />}
                     />
                     <Route
                        exact
                        path="/orders/create"
                        element={<ManualOrder />}
                     />
                     <Route
                        exact
                        path="/orders/:order_id"
                        element={<Orders />}
                     />
                  </>
               ) : (
                  <>
                     <Route
                        exact
                        path="/profile"
                        element={<Profile />}
                     />
                     <Route
                        exact
                        path="/menu"
                        element={<Menu />}
                     />
                     <Route
                        exact
                        path="/qrcode"
                        element={<QRCode />}
                     />
                     <Route
                        exact
                        path="/recommended"
                        element={<Recommended />}
                     />
                     <Route
                        exact
                        path="/bestselling"
                        element={<BestSelling />}
                     />
                     <Route
                        exact
                        path="/newlyadded"
                        element={<NewlyAdded />}
                     />
                     <Route
                        exact
                        path="/orders"
                        element={<Orders />}
                     />
                     <Route
                        exact
                        path="/orders/:order_id"
                        element={<Orders />}
                     />
                     <Route
                        exact
                        path="/orders/create"
                        element={<ManualOrder />}
                     />
                     <Route
                        exact
                        path="/onboarding"
                        element={<OnBoarding />}
                     />
                     {/* <Route exact path="/subscription" element={<Subscription />} /> */}
                     <Route
                        exact
                        path="/analytics"
                        element={<Analytics />}
                     />
                     <Route
                        exact
                        path="/credits"
                        element={<Credits />}
                     />
                     <Route
                        exact
                        path="/loyalty"
                        element={<LoyaltyProgram />}
                     />
                     {/* <Route exact path='/disputes' element={<Disputes />} /> */}
                     {/* <Route exact path='/combos' element={<Combos />} /> */}

                     <Route
                        exact
                        path="/offers"
                        element={<Offers />}
                     />
                     <Route
                        exact
                        path="/specials"
                        element={<HourlyDailySpecials />}
                     />
                     <Route
                        exact
                        path="/waiter"
                        element={<Waiter />}
                     />
                  </>
               )}
               {/* <Route exact path="/subscription" element={<Subscription />} /> */}
            </Routes>
         </ThemeProvider>
      </GoogleOAuthProvider>
   );
}

export default App;
