/** @format */

import React from "react";
import { Modal } from "@mui/material";
import CustomizationTab from "components/AddItemModal/CustomizationTab";

export default function CustomizationTabModal({ visible, onClose, editIndex }) {
   return (
      <Modal
         onClose={onClose}
         open={visible}>
         <div className="AddItemModalContainer">
            <CustomizationTab
               defaultAdd={true}
               onClose={onClose}
               editIndex={editIndex}
            />
         </div>
      </Modal>
   );
}
