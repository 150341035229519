/** @format */

import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Button from "components/Button2";
import LockIcon from "assets/icons/LockIcon";
import { icon } from "@fortawesome/fontawesome-svg-core";
import UnlockIcon from "assets/icons/UnlockIcon";
import Confetti from "components/Confetti";

const OffersModal = ({ open, close, action }) => {
   const styles = {
      modalSvgWrapper: {
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         borderRadius: "50%",
         width: "120px",
         height: "120px",
         outline: "none",
         backgroundColor: "#fff",
      },
      title: {
         textAlign: "center",
         fontFamily: "DM Sans ,sans-serif",
         fontWeight: "600",
         textTransform: "none",
         width: "100%",
         fontSize: "1.2rem",
      },
      description: {
         color: "#000",
         textAlign: "center",
         fontFamily: "DM Sans ,sans-serif",
         textTransform: "none",
         fontSize: "0.9rem",
      },
   };
   return (
      <React.Fragment>
         <Box
            open={open === "None" ? false : true}
            // onClose={close}
            sx={{
               position: "absolute",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               zIndex: 1,
               background: "none",
               width: "70%",
               height: "90%",
            }}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description">
            <div className="borderbg">
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                  }}
                  width={"20vw"}
                  alignItems={"center"}
                  justifyContent={"space-around"}
                  borderRadius={"8px"}
                  bgcolor={"#fff"}
                  padding={"1%"}
                  paddingX={"2%"}
                  minHeight={"50vh"}>
                  <div className="d-flex flex-column align-items-center justify-content-center  gap-2">
                     <Typography
                        sx={styles.title}
                        color={action?.color}
                        variant="h5">
                        {action?.title}
                     </Typography>
                     <Typography
                        sx={styles.description}
                        id="child-modal-description"
                        variant="p">
                        {action?.description}
                     </Typography>
                  </div>
                  <span className="circleBg">
                     <Box sx={styles.modalSvgWrapper}>{action?.icon}</Box>
                  </span>

                  {action?.button != "None" ? (
                     <Button
                        Size="md"
                        title={action?.button}
                        background={action?.id === "subscribed" ? "#fff" : "#2D6B4D"}
                        color={action?.id === "subscribed" ? "#D90429" : "#ffff"}
                        border="none"
                        onClick={action?.onClick}
                     />
                  ) : (
                     <div className="d-flex flex-column align-items-center justify-content-center  gap-2 h-20 "></div>
                  )}
               </Box>
            </div>
         </Box>
      </React.Fragment>
   );
};

export default OffersModal;
