/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import Button from "components/Button2";
import UploadIcon from "assets/icons/UploadIcon";
import EditIcon from "assets/icons/EditIcon";
import DeleteIcon from "assets/icons/DeleteIcon";
import ComboAddIcon from "assets/icons/ComboAddIcon";
import ImageCropModal from "components/ImageCropModal";
import { base64ToFile } from "utils/functions";

const ImageUploader = ({ label, width, optional, isEdit, value, prevValue, setField, height, uploadType, ...props }) => {
   const hiddenFileInput = React.useRef(null);
   const [image, setimage] = useState();
   const [isImageSizeExceeded, setisImageSizeExceeded] = useState(false);
   const [showImageCropModal, setshowImageCropModal] = useState(false);

   const handleClick = (event) => {
      hiddenFileInput.current.click();
   };
   const handleChange = (e) => {
      e.stopPropagation();
      const file = e.target.files[0];
      const size = file.size; // it's in bytes
      const imageSizeInKB = parseInt(size / 1024);
      if (imageSizeInKB <= 500) {
         setisImageSizeExceeded(false);
         setimage(URL.createObjectURL(e.target.files[0]));
         setshowImageCropModal(true);
         // setField("image", e.target.files[0]);
         // setField("image_url", null);
      } else {
         setisImageSizeExceeded(true);
      }
   };

   useEffect(() => {
      setimage(isEdit ? (prevValue === "" ? null : prevValue) : value);
   }, [isEdit]);

   const handleRemove = () => {
      if (isEdit === true) {
         setField("image", null);
         setField("image_url", null);
         setimage(null);
      } else {
         setimage(null);
      }
   };

   const onCropComplete = (base64Image) => {
      const convertedURL = base64ToFile(base64Image, "image.jpg");
      setimage(URL.createObjectURL(convertedURL));
      setField("image", convertedURL);
      setField("image_url", null);
      setshowImageCropModal(false);
   };

   return (
      <div>
         <ImageCropModal
            visible={showImageCropModal}
            fileURL={image}
            onCropComplete={onCropComplete}
         />
         {uploadType === "Button" ? (
            <div className="uploadBtnMainContainer">
               <div className="uploadBtnIcon">
                  <ComboAddIcon />
               </div>
               <div
                  className="uploadBtnTitle"
                  onClick={handleClick}>
                  Add image
               </div>
            </div>
         ) : (
            <div className="UploadMainContainer">
               <div className="UploadBtnLab">
                  {label} <span className="optionaltag">{optional && "(Optional)"}</span>{" "}
               </div>
               <div
                  className="BtnContainer"
                  style={{ width: width, height: height }}>
                  {image !== null && (
                     <div className="ImageWrapper">
                        <img
                           className="PreviewImage object-fit-cover"
                           src={image}
                           alt=""
                        />
                     </div>
                  )}

                  <div className="ImageBtnWrapper">
                     <Button
                        type="button"
                        Size="sm"
                        background="#4361EE"
                        color="#fff"
                        title={image !== null ? "Change" : "Upload"}
                        border="none"
                        icon={image !== null ? <EditIcon /> : <UploadIcon />}
                        onClick={handleClick}
                     />
                     {image !== null && (
                        <Button
                           type="button"
                           Size="sm"
                           background="#D90429"
                           color="#fff"
                           title={"Delete"}
                           border="none"
                           icon={<DeleteIcon />}
                           onClick={handleRemove}
                        />
                     )}
                  </div>
               </div>
               <div className={`text-[0.8rem] text-[${isImageSizeExceeded ? "red" : "#000"}]`}>Image size should not exceed 500kb</div>
            </div>
         )}
         <input
            {...props}
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            accept="image/png, image/gif, image/jpeg"
            style={{ display: "none" }}
         />
      </div>
   );
};
export default ImageUploader;
