import { Update } from '@mui/icons-material';
import { createSlice } from '@reduxjs/toolkit';
import { 
  ActivateHourlySpecials, 
  CreateHourlySpecials, 
  DeactivateHourlySpecials, 
  DeleteHourlySpecials, 
  GetDailySpecials, 
  GetHourlySpecials, 
  UpdateHourlySpecials, 
  CreateDailySpecials,
  DeleteDailySpecials,
  ActivateDailySpecials,
  DeactivateDailySpecials,
  UpdateDailySpecials
} from 'store/action/SpecialsAction';

const SpecialsReducer = createSlice( {
  name: 'Specials',
  initialState: {
    hourlyData: [],
    dailyData: [],
  },
  reducers: {
    
  },
  extraReducers: {
    [GetHourlySpecials.fulfilled]: ( state, { payload } ) => {
      console.log( 'PAYLOAD', payload )
      state.hourlyData = payload;
    },
    [CreateHourlySpecials.fulfilled]: ( state, { payload } ) => {
      state.hourlyData = state.hourlyData.concat( payload );
    },
    [DeleteHourlySpecials.fulfilled]: ( state, { payload } ) => {
      state.hourlyData = state.hourlyData.filter( item => item.hourly_special_id !== payload.hourly_special_id );
    },
    [ActivateHourlySpecials.fulfilled]: ( state, { payload } ) => {
      state.hourlyData = state.hourlyData.map( item => {
        if ( item.hourly_special_id === payload.hourly_special_id )
        {
          item.is_active = true;
        }
        return item;
      })
    },
    [DeactivateHourlySpecials.fulfilled]: ( state, { payload } ) => {
      state.hourlyData = state.hourlyData.map( item => {
        if ( item.hourly_special_id === payload.hourly_special_id )
        {
          item.is_active = false;
        }
        return item;
      })
    },
    [UpdateHourlySpecials.fulfilled]: ( state, { payload } ) => {
      state.hourlyData = state.hourlyData.map( item => {
        if ( item.hourly_special_id === payload.hourly_special_id )
        {
          item = payload;
        }
        return item;
      })
    },
    [GetDailySpecials.fulfilled]: ( state, { payload } ) => {
      state.dailyData = payload;
    },
    [CreateDailySpecials.fulfilled]: ( state, { payload } ) => {
      state.dailyData = state.dailyData.concat( payload );
    },
    [DeleteDailySpecials.fulfilled]: ( state, { payload } ) => {
      state.dailyData = state.dailyData.filter( item => item.daily_special_id !== payload.daily_special_id );
    },
    [ActivateDailySpecials.fulfilled]: ( state, { payload } ) => {
      state.dailyData = state.dailyData.map( item => {
        if ( item.daily_special_id === payload.daily_special_id )
        {
          item.is_active = true;
        }
        return item;
      })
    },
    [DeactivateDailySpecials.fulfilled]: ( state, { payload } ) => {
      state.dailyData = state.dailyData.map( item => {
        if ( item.daily_special_id === payload.daily_special_id )
        {
          item.is_active = false;
        }
        return item;
      })
    },
    [UpdateDailySpecials.fulfilled]: ( state, { payload } ) => {
      state.dailyData = state.dailyData.map( item => {
        if ( item.daily_special_id === payload.daily_special_id )
        {
          item = payload;
        }
        return item;
      })
    }
  },
} );
export const { updateData } = SpecialsReducer.actions;
export default SpecialsReducer.reducer;
