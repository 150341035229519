/** @format */

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import "./index.scss";

export const ToggleSwitch = styled((props) => {
   return (
      <div
         className={props.noWidth ? "ToggleContainer" : "ToggleContainer Width"}
         style={props.style}>
         {props.showAllValue ? (
            <>
               {/* <div
                  className="ToggleLabel"
                  style={props.textStyle}>
                  {props.falseValue}
               </div> */}
               <Switch
                  disabled={props.disabled}
                  focusVisibleClassName=".Mui-focusVisible"
                  disableRipple
                  checked={props.isAvailable}
                  onClick={() => {
                     props.handleValue();
                  }}
                  {...props}
               />
               {/* <div
                  className="ToggleLabel"
                  style={props.textStyle}>
                  {props.trueValue}
               </div> */}
            </>
         ) : (
            <>
               <div
                  className="ToggleLabel"
                  style={props.textStyle}>
                  {props.isAvailable ? props.trueValue : props.falseValue}
               </div>
               <Switch
                  focusVisibleClassName=".Mui-focusVisible"
                  disableRipple
                  disabled={props.disabled}
                  checked={props.isAvailable}
                  onClick={() => {
                     props.handleValue();
                  }}
                  {...props}
               />
            </>
         )}
      </div>
   );
})((props) => ({
   width: "2.5rem",
   height: "1.25rem",
   padding: 0,
   "& .MuiSwitch-switchBase": {
      padding: 0,
      // margin: 1,
      transitionDuration: "600ms",
      "&.Mui-checked": {
         transform: "translateX(1.25rem)",
         color: "#fff",
         "& + .MuiSwitch-track": {
            backgroundColor: props.trueValueBg ? props.trueValueBg : "#2D6B4D",
            opacity: 1,
            border: 0,
         },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
         color: "#33cf4d",
         border: "6px solid #fff",
      },
   },
   "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: "1.25rem",
      height: "1.25rem",
   },
   "& .MuiSwitch-track": {
      borderRadius: "1.25rem",
      backgroundColor: props.falseValueBg ? props.falseValueBg : "red",
      opacity: 1,
      transition: props.theme.transitions.create(["background-color"], {
         duration: 300,
      }),
   },
}));

export const StockSwitch = styled((props) => {
   return (
      <div
         className="ToggleStockContainer"
         onClick={props.handleStock}>
         <div className="ToggleLabel">{props.label}</div>
         <Switch
            disableRipple
            checked={props.isAvailable}
            size="small"
            disabled={props.disabled}
            focusVisibleClassName=".Mui-focusVisible"
            onChange={() => !props.disabled && props.handleStock()}
            {...props}
         />
      </div>
   );
})(({ theme }) => ({
   "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#2FBF71",
   },
   "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#CBEFDB",
   },
}));
