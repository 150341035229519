/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { GetMerchant, UpdateMerchant } from "store/action/MerchantAction";
import { CreateManualOrder } from "store/action/ManualOrderAction";

const initialState = {
   Cart: [],
   order: {},
};

const ManualOrderReducer = createSlice({
   name: "manualOrder",
   initialState,
   reducers: {
      addOrRemoveItemFromCart: (state, { payload }) => {
         let { item, type } = payload;
         const itemIndex = state.Cart.findIndex((menuItem) => menuItem.item_id === item.item_id);
         if (itemIndex !== -1) {
            if (type === "add") {
               state.Cart[itemIndex].count++;
               state.Cart[itemIndex].total_price += item.price_after_discount;
            } else {
               state.Cart[itemIndex].count--;
               state.Cart[itemIndex].total_price -= item.price_after_discount;
            }
            if (state.Cart[itemIndex].count === 0) {
               state.Cart.splice(itemIndex, 1);
            }
         } else {
            item = { ...item, count: 1, addons: [], customizations: [], total_price: item.price_after_discount };
            state.Cart = [...state.Cart, item];
         }
      },
      addOrRemoveAddonFromItem: (state, { payload }) => {
         let { add_on, type, item_id } = payload;
         let { add_on_id, price } = add_on;
         const itemIndex = state.Cart.findIndex((menuItem) => menuItem.item_id === item_id);
         const addOnIndex = state.Cart[itemIndex].addons.findIndex((item) => item.add_on_id === add_on_id);
         if (addOnIndex !== -1) {
            if (type === "add") {
               state.Cart[itemIndex].addons[addOnIndex].count++;
               state.Cart[itemIndex].total_price = parseInt(state.Cart[itemIndex].total_price) + parseInt(price);
            } else {
               state.Cart[itemIndex].addons[addOnIndex].count--;
               state.Cart[itemIndex].total_price = parseInt(state.Cart[itemIndex].total_price) - parseInt(price);
            }
            if (state.Cart[itemIndex].addons[addOnIndex].count === 0) {
               state.Cart[itemIndex].addons.splice(addOnIndex, 1);
            }
         } else {
            state.Cart[itemIndex].addons.push({
               ...add_on,
               count: 1,
            });
            state.Cart[itemIndex].total_price = parseInt(state.Cart[itemIndex].total_price) + parseInt(price);
         }
      },
      toggleCustomizationForItem: (state, { payload }) => {
         const itemIndex = state.Cart.map((item) => item.item_id).indexOf(payload.item_id);
         const customizationIndex = state.Cart[itemIndex].customizations.findIndex((item) => item.customisation_id === payload.customisation_id);

         if (customizationIndex !== -1) {
            if (payload.selection_type === "checkbox") {
               if (state.Cart[itemIndex].customizations[customizationIndex].selected_options.includes(payload.option)) {
                  state.Cart[itemIndex].customizations[customizationIndex].selected_options.splice(
                     state.Cart[itemIndex].customizations[customizationIndex].selected_options.indexOf(payload.option),
                     1,
                  );
               } else {
                  state.Cart[itemIndex].customizations[customizationIndex].selected_options.push(payload.option);
               }
            } else {
               state.Cart[itemIndex].customizations[customizationIndex] = {
                  selected_options: [payload.option],
                  ...payload,
               };
            }
         } else {
            state.Cart[itemIndex].customizations.push({
               selected_options: [payload.option],
               ...payload,
            });
         }
      },
      clearCustomization: (state, action) => {
         const index = state.Cart.map((item) => item.item_id).indexOf(action.payload.item_id);
         const customizationIndex = state.Cart[index].customizations.findIndex((item) => item.customisation_id === action.payload.customisation_id);
         if (customizationIndex !== -1) {
            state.Cart[index].customizations.splice(customizationIndex, 1);
         }
      },
      resetCart: (state, action) => {
         state.Cart = [];
         state.order = {};
      },
   },
   extraReducers: {
      [CreateManualOrder.fulfilled]: (state, { payload }) => {
         state.order = payload;
      },
   },
});

export const { addOrRemoveItemFromCart, addOrRemoveAddonFromItem, toggleCustomizationForItem, clearCustomization, resetCart } = ManualOrderReducer.actions;

export default ManualOrderReducer.reducer;
