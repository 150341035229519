import React from 'react';

function ComboAddIcon() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20 6.97754L20 32.9546'
        stroke='#2D6B4D'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.66659 19.9658L33.3333 19.9658'
        stroke='#2D6B4D'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default ComboAddIcon;
