import React from 'react';
import { Modal } from '@mui/material';
import { TailSpin } from 'react-loader-spinner';
import './index.scss';

function Loader({ isLoading }) {
  return (
    <Modal
      sx={{
        zIndex: '10000',
      }}
      open={isLoading}
    >
      <div className='loader-container1'>
        <div className='loader-container2'>
          <TailSpin
            height='80'
            width='80'
            color='#4fa94d'
            ariaLabel='tail-spin-loading'
            radius='1'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
          />
          <div className='loader-please-wait-text'>Please Wait</div>
        </div>
      </div>
    </Modal>
  );
}

export default Loader;
