/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { CreateOffer, DeleteOffer, GetAllOffers, GetOfferById, UpdateOffer } from "store/action/OffersAction";

const initialState = {
   offers: [],
   singleOffer: {},
};

const OffersReducer = createSlice({
   name: "OffersReducer",
   initialState,
   reducers: {},
   extraReducers: {
      // GetAllOffers
      [GetAllOffers.fulfilled]: (state, { payload }) => {
         console.log("GetAllOffers PAYLOAD=>", payload);
         state.offers = payload;
      },
      // CreateOffer
      [CreateOffer.fulfilled]: (state, { payload }) => {
         console.log("CreateOffer PAYLOAD=>", payload);
         state.offers.push(payload);
      },
      // UpdateOffer
      [UpdateOffer.fulfilled]: (state, { payload }) => {
         console.log("UpdateOffer PAYLOAD=>", payload);
         const index = state.offers.findIndex((offer) => offer.id === payload.id);
         state.offers[index] = payload;
      },
      // DeleteOffer
      [DeleteOffer.fulfilled]: (state, { payload }) => {
         console.log("DeleteOffer PAYLOAD=>", payload);
         let existingOffers = [...state.offers];
         const index = existingOffers.findIndex((offer) => offer.offer_id === payload.offer_id);
         existingOffers.splice(index, 1);
         state.offers = existingOffers;
      },
      //GetOfferById
      [GetOfferById.fulfilled]: (state, { payload }) => {
         console.log("GetOfferById PAYLOAD=>", payload);
         state.singleOffer = payload;
      },
   },
});

export default OffersReducer.reducer;
