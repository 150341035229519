/** @format */

import React, { useState } from "react";
import Button from "components/Button2";
import PlusIcon from "assets/icons/PlusIcon";
import Search from "components/Search";
import NoItems from "components/NoItems";
import AddItemModal from "components/AddItemModal";
import MenuItemCard from "components/MenuItemCard";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { dropItem, dropMenu } from "store/reducer/MenuReducer";
import { Tooltip } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useEffect } from "react";

function MenuListItem({ setloading, initialRender, searchResults, searchVal, setSearch, searchItemId, mobileView }) {
   console.log(searchResults, "searchResults from menu");
   console.log(searchItemId, "searchitem id");

   const [isVisible, setVisible] = useState(false);
   const [isEdit, setEdit] = useState(false);
   const [currentItem, setCurrentItem] = useState();
   const dispatch = useDispatch();

   const categoryId = localStorage.getItem("categoryId");

   const data = useSelector((state) => state.MenuReducer.Menu.filter((i) => i?.id === categoryId));

   const MenuItems = data.map((i) => {
      return i.items;
   });

   const header = data.map((i) => {
      return i.category_name;
   });

   return (
      <div className="MenuItemsSubContainer">
         {!mobileView && (
            <div className="MenuHeader">
               {header.length === undefined || header.length === 0 || searchVal?.length > 0 ? (
                  "items"
               ) : (
                  <Tooltip title={header}>
                     <div>{header.toString().length > 18 ? header.toString().slice(0, 14) + "..." : header}</div>
                  </Tooltip>
               )}
               <Button
                  Size="md"
                  title={`Add items`}
                  icon={<PlusIcon color={"#4361EE"} />}
                  border="none"
                  background="#ECEFFD"
                  color={"#4361EE"}
                  fontWeight="bold"
                  onClick={() => setVisible(true)}
               />
            </div>
         )}
         <AddItemModal
            items={MenuItems[0]}
            open={isVisible}
            isEdit={isEdit}
            setEdit={setEdit}
            close={() => setVisible(false)}
            prevItem={currentItem}
            setloading={setloading}
            searchResults={searchResults}
            setSearch={setSearch}
         />
         <Droppable droppableId="items">
            {(provided) => (
               <div
                  className="MenuListContainer"
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  <div className={MenuItems[0]?.length === 0 ? "MenuItemWrapper NoItemsWrapper" : "MenuItemWrapper"}>
                     {MenuItems[0]?.length > 0 ? (
                        searchVal !== "" ? (
                           searchResults?.length > 0 ? (
                              searchResults?.map((item, index) => {
                                 if (item.category_id === categoryId)
                                    return (
                                       <>
                                          <MenuItemCard
                                             categoryTitle={header}
                                             mobileView={mobileView}
                                             setCurrentItem={setCurrentItem}
                                             setEdit={setEdit}
                                             setVisible={setVisible}
                                             searchItemId={searchItemId}
                                             item={item}
                                             key={index}
                                          />
                                       </>
                                    );
                              })
                           ) : (
                              <NoItems description={true} />
                           )
                        ) : (
                           MenuItems[0]?.map((item, index) => {
                              return (
                                 <Draggable
                                    draggableId={item.item_id}
                                    index={index}
                                    key={item.item_id}>
                                    {(provided) => (
                                       <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}>
                                          <MenuItemCard
                                             categoryTitle={header}
                                             mobileView={mobileView}
                                             setCurrentItem={setCurrentItem}
                                             setEdit={setEdit}
                                             setVisible={setVisible}
                                             searchItemId={searchItemId}
                                             item={item}
                                             key={index}
                                             index={index}
                                          />
                                       </div>
                                    )}
                                 </Draggable>
                              );
                           })
                        )
                     ) : (
                        <NoItems />
                     )}
                  </div>
               </div>
            )}
         </Droppable>
      </div>
   );
}

export default MenuListItem;
