/** @format */

import React from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const iconStyle = { color: "#E63946", fontSize: "1.2rem", cursor: "pointer" };

export default function CartActionButtons({ quantity, onClickActionButtons }) {
   console.log("quantity", quantity);
   return (
      <>
         {quantity !== undefined && quantity > 0 ? (
            <div className="flex cursor-pointer items-center w-[5rem] rounded-[8px] px-2 justify-center gap-2 border-[0.5px] border-[#E63946] border-solid">
               <RemoveIcon
                  sx={iconStyle}
                  onClick={() => onClickActionButtons("sub")}
               />
               <div className="text-[#E63946]"> {quantity}</div>
               <AddIcon
                  sx={iconStyle}
                  onClick={() => onClickActionButtons("add")}
               />
            </div>
         ) : (
            <div
               onClick={() => onClickActionButtons("add")}
               className="flex text-[#E63946] text-[0.8rem ] cursor-pointer  w-[5rem]  text-center rounded-[8px] px-2 justify-center gap-2 border-[0.5px] border-[#E63946] border-solid ">
               Add
            </div>
         )}
      </>
   );
}
