/** @format */

import { useState, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@mui/material";
import "./index.scss";

function App({ fileURL, onCropComplete, visible }) {
   const [src, setSrc] = useState(null);
   const [crop, setCrop] = useState({
      unit: "%",
      width: 50,
      aspect: 1,
      x: 25, // Centers the crop area horizontally
      y: 25,
   });
   const [image, setImage] = useState(null);

   const selectImage = () => {
      setSrc(fileURL);
   };

   useEffect(() => {
      selectImage();
   }, [fileURL]);

   const cropImageNow = () => {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

      // Converting to base64
      const base64Image = canvas.toDataURL("image/jpeg");
      onCropComplete(base64Image);
   };

   return (
      <Modal
         open={visible}
         className="deleteModal">
         <div className="bg-[#fff] w-[40%] h-[60%] flex flex-col items-center justify-between p-3 rounded-[0.5rem]">
            <h4>Please resize your image</h4>

            <div className="w-[70%] h-[70%] flex items-center justify-center">
               <ReactCrop
                  imageStyle={{ height: "100%", width: "100%" }}
                  src={src}
                  ruleOfThirds
                  onImageLoaded={setImage}
                  crop={crop}
                  onChange={setCrop}
               />
            </div>

            <button
               className="bg-[#2D6B4D] text-[#fff] px-3 py-1 mb-3 rounded-[0.3rem]"
               onClick={cropImageNow}>
               Set
            </button>
         </div>
      </Modal>
   );
}

export default App;
