import SearchIcon from 'assets/icons/SearchIcon';
import React from 'react';
import './index.scss';

function Search({ border, width, height, padding, InputHeight, ...props }) {
  return (
    <div
      className='SearchBarContainer'
      style={{
        border: border,
        width: width,
        padding: padding,
        height: height,
      }}
    >
      <SearchIcon />
      <input height={InputHeight} {...props} className='SearchInput' />
    </div>
  );
}

export default Search;
