import React from 'react';

function CrossIcon(props) {
  return (
    <svg width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.6572 6.34314L6.34352 17.6568'
        stroke={props.color !== '' ? props.color : 'white'}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.34352 6.34315L17.6572 17.6569'
        stroke={props.color !== '' ? props.color : 'white'}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default CrossIcon;
