/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { GetMenu, CreateCategory, EditCategory, DeleteCategory, EditCategoryItem, CreateCategoryItem, DeleteItem, DragAndDrop } from "store/action/MenuAction";

export const MenuReducer = createSlice({
   name: "Menu",
   initialState: {
      Menu: [],
   },
   reducers: {
      dropMenu: (state, action) => {
         if (action.payload.type === "category") {
            state.Menu = action.payload.copyListItems;
         } else {
            console.log("CAT_ID", action.payload.catId);
            state.Menu.find((item) => item.id === action.payload.catId).items = action.payload.copyListItems;
         }
      },
   },
   extraReducers: {
      [GetMenu.fulfilled]: (state, { payload }) => {
         state.Menu = payload?.sort((e1, e2) => e1.category_idx - e2.category_idx);
         state.Menu.map((e) => e.items.sort((e1, e2) => e1.item_idx - e2.item_idx));
      },
      [CreateCategory.fulfilled]: (state, { payload }) => {
         console.log("CREATE_CATEGORY", payload);
         const payloadItem = {
            id: payload.category_id,
            category_name: payload.category_name,
            items: [],
            category_idx: payload.category_idx,
         };
         state.Menu.push(payloadItem);
      },
      [EditCategory.fulfilled]: (state, { payload }) => {
         for (const key in state.Menu) {
            if (state.Menu[key].id === payload.category_id) {
               state.Menu[key].category_name = payload.category_name;
            }
         }
      },
      [DeleteCategory.fulfilled]: (state, { payload }) => {
         console.log("DELETE_CATEGORY", payload);
         const index = state.Menu.findIndex((item) => item.id === payload.category_id);
         state.Menu.splice(index, 1);
      },
      [CreateCategoryItem.fulfilled]: (state, { payload }) => {
         console.log("CREATE_ITEM_PAYLOAD", payload);
         state?.Menu.forEach((e) => {
            console.log(e?.id);
            if (e?.id === payload?.category_id) {
               e?.items.push(payload);
            }
         });
         console.log(state.Menu);
      },
      [EditCategoryItem.fulfilled]: (state, { payload }) => {
         console.log("EDit ITem", payload);
         for (const i in state.Menu) {
            if (state.Menu[i].id === payload?.category_id) {
               for (const j in state.Menu[i].items) {
                  if (state.Menu[i].items[j].item_id == payload?.item_id) {
                     state.Menu[i].items.splice(j, 1);
                     state.Menu[i].items.splice(j, 0, payload);
                  }
               }
            }
         }
      },
      [DeleteItem.fulfilled]: (state, { payload }) => {
         let prev = state.Menu;
         for (const i in prev) {
            let items = prev[i].items;
            for (const j in items) {
               if (items[j].item_id === payload.item_id) {
                  items.splice(j, 1);
                  console.log("DELETED PAYLOAD");
               }
            }
         }
         state.Menu = prev;
      },
   },
});
export default MenuReducer.reducer;
export const { dropMenu, dropItem } = MenuReducer.actions;
