/** @format */
import Layout from "layouts";
import React, { useEffect, useState } from "react";
import Button from "components/Button2";
import PlusIcon from "assets/icons/PlusIcon";
import "./style.scss";
import WaiterCard from "./WaiterCard";
import Search from "components/Search";
import NotificationIcon from "assets/icons/WaiterNotificationIcon";
import FavouriteIcon from "assets/icons/FavouriteIcon";
import { useSelector, useDispatch } from "react-redux";
import { GetWaiter } from "store/action/WaiterAction";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
   return (
      <MuiAlert
         elevation={6}
         ref={ref}
         variant="filled"
         {...props}
      />
   );
});

const Waiter = () => {
   const dispatch = useDispatch();
   const waiterList = useSelector((state) => state.WaiterReducer.waiter);
   const [waiterCards, setWaiterCards] = useState([]);
   const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [snackbarMessage, setSnackbarMessage] = useState("");
   const [searchQuery, setsearchQuery] = useState("");

   useEffect(() => {
      dispatch(GetWaiter());
   }, []);

   useEffect(() => {
      if (waiterList?.length > 0) {
         const flattenedWaiterList = waiterList.flat();
         setWaiterCards(flattenedWaiterList);
      }
   }, [waiterList]);

   const handleAddWaiterClick = () => {
      const hasUnsavedCard = waiterCards.some((card) => card.initialEditing);
      if (!hasUnsavedCard) {
         const newWaiterCard = {
            initialEditing: true,
            username: "",
            password: "",
            waiter_info: "",
            isNew: true,
         };
         setWaiterCards([...waiterCards, newWaiterCard]);
      } else {
         setSnackbarMessage("Please save or cancel the current card before adding a new one.");
         setSnackbarOpen(true);
      }
   };

   const handleCancelNewWaiter = () => {
      setWaiterCards(waiterCards.slice(0, -1));
   };

   const handleSnackbarClose = (event, reason) => {
      if (reason === "clickaway") {
         return;
      }
      setSnackbarOpen(false);
   };

   const handleDeleteWaiter = (waiterId) => {
      setWaiterCards(waiterCards.filter((card) => card.waiter_id !== waiterId));
   };

   const isSearchQueryMatches = (card) => {
      if (searchQuery !== "") return card.username.toLowerCase().includes(searchQuery) || card.waiter_info.toLowerCase().includes(searchQuery);
      return true;
   };

   return (
      <Layout>
         <div className="flex justify-between items-center mt-[1rem]">
            <div className="w-[90%] ">
               <Search
                  onChange={(e) => setsearchQuery(e.target.value.toLowerCase())}
                  InputHeight={"3rem"}
                  padding={"0.8rem"}
                  width={"100%"}
                  placeholder={"Search"}
               />
            </div>
            <div className="flex gap-2">
               {/* <span className="cursor-pointer">
                  <NotificationIcon />
               </span>
               <span className="cursor-pointer">
                  <FavouriteIcon />
               </span> */}
            </div>
         </div>
         <div className="waiterHeading">
            <h3>Waiter Management</h3>
            <Button
               Size="md"
               title={`Add New Waiter`}
               icon={<PlusIcon color={"#4361EE"} />}
               border="none"
               background="#ECEFFD"
               color={"#4361EE"}
               fontWeight="bold"
               onClick={handleAddWaiterClick}
            />
         </div>
         <div className="waiterCardListContainer">
            {waiterCards?.map((card, index) => {
               if (isSearchQueryMatches(card))
                  return (
                     <WaiterCard
                        key={index}
                        initialEditing={card.initialEditing}
                        waiterData={card}
                        isNew={card.isNew}
                        onCancel={handleCancelNewWaiter}
                        onDelete={handleDeleteWaiter}
                     />
                  );
            })}
         </div>
         <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}>
            <Alert
               onClose={handleSnackbarClose}
               severity="warning">
               {snackbarMessage}
            </Alert>
         </Snackbar>
      </Layout>
   );
};

export default Waiter;
