import React from 'react';

function PlusIcon({ color }) {
  return (
    <svg width='20' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 4L12 20'
        stroke={color ? color : 'white'}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4 12L20 12'
        stroke={color ? color : 'white'}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}

export default PlusIcon;
