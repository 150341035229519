import { createSlice } from '@reduxjs/toolkit';
import { CreateNewlyAddedItems, GetNewlyAddedItems } from 'store/action/NewlyAddedAction';

const NewlyAddedReducer = createSlice( {
  name: 'NewlyAddedItems',
  initialState: {
    newlyAddedItems: { newly_added_items: [] },
    allItems: [],
  },
  reducers: {
    updateData: ( state, { payload } ) => {
      state.allItems = payload;
    },
  },
  extraReducers: {
    [GetNewlyAddedItems.fulfilled]: ( state, { payload } ) => {
      state.newlyAddedItems.newly_added_items = payload.newly_added_items;
    },
    [CreateNewlyAddedItems.fulfilled]: ( state, { payload } ) => {
      state.newlyAddedItems.newly_added_items = payload.item_ids;
    },
  },
} );
export const { updateData } = NewlyAddedReducer.actions;
export default NewlyAddedReducer.reducer;
