/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { CreateWaiter, DeleteWaiter, GetWaiter, UpdateWaiter } from "store/action/WaiterAction";

const WaiterReducer = createSlice({
   name: "WaiterReducer",
   initialState: {
      waiter: [],
   },
   reducers: {
      setWaiterMode: (state, action) => {
         state.waiter_mode = action.payload;
      },
   },
   extraReducers: {
      [CreateWaiter.fulfilled]: (state, { payload }) => {
         console.log(...state.waiter, payload, "payloadwaiter");
         state.waiter = [...state.waiter, payload];
      },
      [GetWaiter.fulfilled]: (state, { payload }) => {
         state.waiter = [payload];
         console.log(state.waiter, "WAITER");
      },
      [UpdateWaiter.fulfilled]: (state, { payload }) => {
         console.log(payload.waiter_id, "WAITERID");
         const index = state.waiter.findIndex((waiter) => waiter.id === payload.waiter_id);
         if (index !== -1) {
            state.waiter[index] = { ...state.waiter[index], ...payload.updatedData };
         }
      },
      [DeleteWaiter.fulfilled]: (state, { payload }) => {
         console.log(payload, "DELTEPAYLOAD");
         const index = state.waiter.findIndex((item) => item.id === payload.waiter_id);
         state.waiter.splice(index, 1);
      },
   },
});

export default WaiterReducer.reducer;
