import "./index.scss";
import Layout from "layouts";
import PointsCard from "../../components/PointsCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AttemptCredit, CreateCredit, getAllCredits, getAllCreditsPlan } from "store/action/CreditsAction";
import toast from "react-hot-toast";
import useRazorpay from "react-razorpay";
import Loader from "components/Loader";
const RAZORPAY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;

const Credits = () => {
  const [loading, setloading] = useState( false );
  const credits = useSelector( state => state.CreditsReducer.credits );
  const [plans, setplans] = useState( [] );
  console.log( credits );
  const dispatch = useDispatch();
  const razorpay = useRazorpay();

  const openPaymentGateway = async data => {
    console.log( data.payment_gateway_order_id, "ChEck" );
    const options = {
      description: data?.description,
      image: data?.image,
      currency: "INR",
      key: RAZORPAY_ID,
      amount: ( data.amount * 100 )?.toString(),
      name: data?.name,
      order_id: data?.payment_gateway_order_id,
      prefill: data?.prefill,
      theme: { color: "#53a20e" },
      handler: async resData => {
        await handleCreateCredit( data );
      },
    };
    const rzpayResult = new razorpay( options );
    rzpayResult.on( "payment.failed", resData => {
      console.log( resData.error );
    } );
    rzpayResult.open();
  };
  const handleChooseCredit = async no_of_credits => {
    setloading( true );
    const body = {
      no_of_credits: parseInt( no_of_credits ),
    };
    console.log( body );
    const request = await dispatch( AttemptCredit( body ) );
    if ( request.type === "AttemptCredit/fulfilled" )
    {
      console.log( request?.payload );
      openPaymentGateway( request?.payload );
    }

    setloading( false );
  };

  const handleCreateCredit = async data => {
    const body = {
      ...data,
      payment_status: "paid",
    };

    const request = await dispatch( CreateCredit( body ) );
    if ( request.type === "CreateCredit/fulfilled" )
    {
      console.log( request?.payload );
    } else
    {
      toast.error( "Can't create credit" );
    }
  };

  const fetchCredits = async () => {
    setloading( true );
    const result = await dispatch( getAllCredits() );
    if ( result.type === "getAllCredits/fulfilled" )
    {
      console.log( result?.payload );
    } else
    {
      toast.error( "Can't fetch credits" );
    }
    setloading( false );
  };


  const fetchPlans = async () => {
    setloading( true );
    const result = await dispatch( getAllCreditsPlan() );
    if ( result.type === "getAllCredits/fulfilled" )
    {
      setplans( result?.payload );
    }
    else
    {
      toast.error( "Can't fetch plans" );
    }
    setloading( false );
  };

  useEffect( () => {
    fetchCredits();
    fetchPlans();
  }, [] );

  return (
    <Layout>
      <Loader isLoading={ loading } />
      <div className="points-outer">
        <div className="points-head d-flex w-100 align-items-center justify-content-between">
          <div>Credit Points</div>
          <div className="bg-white credits-available">
            <span>Available credits : </span>
            <span> { credits?.no_of_credits } </span>
          </div>
        </div>
        <div className="points-list">
          { plans?.map( ( plan, index ) => (
            <PointsCard
              handleChooseCredit={() =>  handleChooseCredit(plan?.no_of_credits) }
              points={ plan?.no_of_credits }
              amount={ plan?.amount }
              key={ index }
            />
          ) )
          }
        </div>
        {/* <div className="points-head">Custom Credit Points</div>
        <div className="points-list">
          <PointsCard
            handleChooseCredit={handleChooseCredit}
            points={0}
            amount={0}
            isCustom={true}
          />
        </div> */}
      </div>
    </Layout>
  );
};

export default Credits;
