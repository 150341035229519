/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="18"
         height="18"
         fill="none"
         viewBox="0 0 21 21">
         <path
            fill="#fff"
            d="M14.002 1.948a3.57 3.57 0 015.049 5.048l-.803.803L13.2 2.751l.803-.803zM12.352 3.6l-9.6 9.6c-.392.393-.665.89-.786 1.432l-1.053 4.74a.6.6 0 00.716.716l4.711-1.047a3 3 0 001.481-.814L17.4 8.65 12.35 3.6z"></path>
      </svg>
   );
}

export default Icon;
