/** @format */
import axios from "axios";

const CONTACT_US_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/contact_us/`;

export const SubmitContactUs = async (data) => {
   return new Promise((resolve, reject) => {
      axios
         .post(CONTACT_US_ENDPOINT, data, {
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${localStorage.getItem("savedT")}`,
               "ngrok-skip-browser-warning": "any",
            },
         })
         .then(async (response) => {
            if (response.status === 201 || response.status === 200) {
               const resData = await response.data;
               resolve(resData);
            } else {
               reject();
            }
         })
         .catch((error) => {
            reject(error);
         });
   });
};
