import React from 'react';
import './index.scss';


export default function ComingSoon() {
    return (
        <div className="GIFContainer">
            <img src={require("../../assets/gifs/Under-construction.gif")} className="UnderConstructionGIF" />
        </div>
    )
}

