/** @format */

import React, { useState } from "react";
import Input from "components/Input";
import Button from "components/Button2";
import "./index.scss";
import CrossIcon from "assets/icons/CrossIcon";
import TickIcon from "assets/icons/TickIcon";
import { CreateCategory } from "store/action/MenuAction";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { CATEGORY_NAME_CHARACTER_LIMIT } from "utils/definitions";

export default function Index(props) {
   const { onPressCancel } = props;
   const dispatch = useDispatch();
   const [input, setinput] = useState("");

   const AddCategoryItem = async () => {
      console.log("On press saved !");

      if (input !== "") {
         const body = { category_name: input, category_idx: props.data?.length > 0 ? props.data?.length - 1 + 1 : 0 };

         await dispatch(CreateCategory(body));
         toast.success(`Category ${input} is created`);
         onPressCancel();
      } else {
         toast.error("Please enter something!");
      }
   };

   return (
      <div className="NewCategoryContainer">
         <div>
            <input
               className="NormalInputContainer"
               onChange={(e) => {
                  const { value } = e.target;
                  if (value.length < CATEGORY_NAME_CHARACTER_LIMIT + 1) {
                     setinput(value);
                  }
               }}
               onKeyDown={(e) => e.key === "Enter" && AddCategoryItem()}
               placeholder={"Enter category name"}
               style={{ width: "100%" }}
               value={input}
            />
            <div className="text-gray-400 text-[0.7rem]">Characters remaining {CATEGORY_NAME_CHARACTER_LIMIT - input.length}</div>
         </div>
         <div className="MenuBtnWrapper">
            <Button
               Size="sm"
               title="Cancel"
               border="none"
               icon={<CrossIcon color="white" />}
               background={"#D90429"}
               color={"#fff"}
               onClick={onPressCancel}
            />
            <Button
               Size="sm"
               title="Save"
               border="none"
               icon={<TickIcon />}
               background={"#2FBF71"}
               color={"#fff"}
               onClick={AddCategoryItem}
            />
         </div>
      </div>
   );
}
