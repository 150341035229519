import AnalyticsCard from "components/AnalyticsCard";
import BarChart from "../BarChart";
import AnalyticsTable from "../AnalyticsTable";
import { TabPanel } from "..";

export const titleCase = (s) =>
    s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

    const TabPanelByOrderType = ({ data, ordersTab, orderType, index, recencySelected }) => {
        return (
            <TabPanel value={ordersTab} index={index}>
                <div className='analyticsTotalValues'>
                    {
                    Object.keys(data?.order_analytics || {})?.map((key, index) => {
                        if (data?.order_analytics[key]?.type_of_order === orderType && (data?.order_analytics[key]?.recency === recencySelected)) {
                            if (data?.order_analytics[key]?.type === 'tile') {
                                return <AnalyticsCard item={{title: data?.order_analytics[key]?.title, value: data?.order_analytics[key]?.value}} key={index} />
                            }
                        } return '';
                    })
                    } 
                </div>
                <div className='analyticsTotalValues'>
                    {
                    Object.keys(data?.order_analytics || {})?.map((key, index) => {
                        if (data?.order_analytics[key]?.type_of_order === orderType && (data?.order_analytics[key]?.recency === recencySelected)) {
                            if (data?.order_analytics[key]?.type === 'bar') {
                                return (
                                    <>
                                    <BarChart dets={data?.order_analytics[key]} keys={key} />
                                    {/* <BarChart dets={data?.order_analytics[key]} keys={key} /> */}
                                    </>
                                )
                            }
                        } return '';
                    })
                    } 
                </div>
                <div className="analyticsTotalValues">
                    {
                        Object.keys(data?.order_analytics || {})?.map((key, index) => {
                            if (data?.order_analytics[key]?.type === 'table' && data?.order_analytics[key]?.recency === recencySelected && data?.order_analytics[key]?.type_of_order === orderType) {
                                return <AnalyticsTable value={data?.order_analytics[key]?.value} keys={key} title={data?.order_analytics[key]?.title} page={'orders'} />
                            } return '';
                        })
                    }
                </div>
            </TabPanel>
        )
    }
    
const OrdersPage = ({ data, ordersTab, recencySelected }) => {

    return (
        <div className="orders-page">
            <TabPanelByOrderType data={data} ordersTab={ordersTab} orderType='Dine-in' index={0} recencySelected={recencySelected} />
            <TabPanelByOrderType data={data} ordersTab={ordersTab} orderType='Take away' index={1} recencySelected={recencySelected} />
            <TabPanelByOrderType data={data} ordersTab={ordersTab} orderType='Delivery' index={2} recencySelected={recencySelected} />
        </div>
    )
}

export default OrdersPage;