/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="18"
         height="18"
         fill="none"
         viewBox="0 0 20 21">
         <path
            fill="#D90429"
            d="M7.856 3.643h4.286a2.143 2.143 0 10-4.286 0zm-1.286 0a3.429 3.429 0 016.857 0h5.358a.643.643 0 010 1.286h-1.123l-1.043 12.517a3.643 3.643 0 01-3.63 3.34h-5.98a3.643 3.643 0 01-3.63-3.34L2.336 4.929H1.213a.643.643 0 110-1.286H6.57zm2.143 4.929a.643.643 0 10-1.286 0v7.286a.643.643 0 001.286 0V8.572zm3.215-.643a.643.643 0 00-.643.643v7.286a.643.643 0 101.285 0V8.572a.643.643 0 00-.643-.643z"></path>
      </svg>
   );
}

export default Icon;
