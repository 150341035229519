import { createSlice } from '@reduxjs/toolkit';
import { GetMerchant, UpdateMerchant } from 'store/action/MerchantAction';

const initialState = {
  merchantDetails: {},
};

const MerchantReducer = createSlice({
  name: 'merchant',
  initialState,
  reducers: {},
  extraReducers: {
    [GetMerchant.fulfilled]: (state, { payload }) => {
      state.merchantDetails = payload;
    },
    [UpdateMerchant.fulfilled]: (state, { payload }) => {
      state.merchantDetails = payload;
    },
  },
});

export const { getMerchant } = MerchantReducer.actions;

export default MerchantReducer.reducer;
