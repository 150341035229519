/** @format */

import { Divider } from "@mui/material";
import Input from "components/FormikInput";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { CreateLoyaltyRules, EditLoyaltyRules } from "store/action/LoyaltyAction";
import * as yup from "yup";
import Button from "components/Button2";
import MerchantReducer from "store/reducer/MerchantReducer";
import EditIcon from "assets/icons/EditIcon";
import { useSelector } from "react-redux";

const LoyaltyRules = ({ loyalty_rules, initialValues, isEdit, setEdit, setLoading }) => {
   const [isEditting, setIsEditting] = useState(false);
   const dispatch = useDispatch();
   const merchantDetails = useSelector((state) => state.MerchantReducer.merchantDetails);
   console.log("merchantDetails", merchantDetails);
   const validationSchema = yup.object({
      // title: yup.string().required("Field is Required"),
      // contact_name: yup.string().required("Field is Required"),
      // contact_email: yup.string().email("Enter a valid email").required("Field is Required"),
   });
   const onSubmit = async (values) => {
      setLoading(true);
      const body = {
         signup_bonus_coins: parseInt(values?.signup_bonus_coins),
         value_for_1000_coins: parseInt(values?.value_for_1000_coins),
         coins_for_each_rupee: parseInt(values?.coins_for_each_rupee),
      };
      console.log("loyalty_rules", loyalty_rules);

      if (!isEdit) {
         const response = await dispatch(CreateLoyaltyRules(body));
         console.log("response", response);
         if (response.type === "CreateLoyaltyRules/fulfilled") {
            setEdit(true);
            toast.success("Loyalty rules created successfully");
         } else {
            toast.error("Something went wrong");
            setEdit(false);
         }
      } else {
         if (isEditting) {
            const response = await dispatch(EditLoyaltyRules(body));
            console.log("response", response);
            if (response.type === "EditLoyaltyRules/fulfilled") {
               setEdit(true);
               toast.success("Loyalty rules updated successfully");
               setIsEditting(false);
            } else {
               toast.error("Something went wrong");
            }
         } else {
            setIsEditting(true);
         }
      }
      setLoading(false);
   };

   return (
      <div className="loyalty-main-container">
         <div className="loyalty-main-container-header">Set rules for loyalty program</div>
         <Divider
            color="#C3C5C6"
            sx={{
               height: "2px",
            }}
         />

         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}>
            {({ setFieldValue, values, isValid }) => {
               return (
                  <Form className="loyaltyFormContainer">
                     {/* <div className="loyaltyFormGroup">
                        <div className="loyaltyLabel">How much coin should be given to the customer for each rupees they spend?</div>
                        <div className="loyaltyInput">
                           <Input
                              noLabel
                              disabled
                              width={"100%"}
                              name={"coins_for_each_rupee"}
                              type={"number"}
                              value={values?.coins_for_each_rupee}
                              onChange={(e) => setFieldValue("coins_for_each_rupee", e.target.value)}
                           />
                        </div>
                     </div> */}
                     <div className="loyaltyFormGroup">
                        <div className="loyaltyLabel">Enter the coin value (in {merchantDetails.currency}) for 1000 coins</div>
                        <div className="loyaltyInput">
                           <Input
                              disabled={isEdit && !isEditting}
                              rupee
                              noLabel
                              width={"100%"}
                              name={"value_for_1000_coins"}
                              type={"number"}
                              onChange={(e) => setFieldValue("value_for_1000_coins", e.target.value)}
                           />
                        </div>
                     </div>
                     <div className="loyaltyFormGroup">
                        <div className="loyaltyLabel">Sign up bonus coins </div>
                        <div className="loyaltyInput">
                           <Input
                              disabled={isEdit && !isEditting}
                              noLabel
                              width={"100%"}
                              name={"signup_bonus_coins"}
                              type={"number"}
                              onChange={(e) => setFieldValue("signup_bonus_coins", e.target.value)}
                           />
                        </div>
                     </div>

                     <div className="LoyaltyButtonWrapper">
                        {isEdit ? (
                           <Button
                              Size="md"
                              title={!isEditting ? "Edit rules" : "Update"}
                              background={!isEditting ? "#4361EE" : "#2D6B4D"}
                              color={"#ffff"}
                              border="none"
                              type="submit"
                              icon={!isEditting ? <EditIcon /> : null}
                              disabled={!isValid}
                           />
                        ) : (
                           <Button
                              Size="md"
                              title={"Update"}
                              background={"#2D6B4D"}
                              color={"#ffff"}
                              border="none"
                              type="submit"
                           />
                        )}
                     </div>
                  </Form>
               );
            }}
         </Formik>
      </div>
   );
};

export default LoyaltyRules;
