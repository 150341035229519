/** @format */

import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Input from "components/FormikInput";
import { Switch } from "antd";
import "./index.scss";
import { Checkbox, IconButton } from "@mui/material";
import EditIcon from "assets/icons/EditIcon";
import DeleteIcon from "assets/icons/DeleteIcon";
import CrossIcon from "assets/icons/CrossIcon";
import TickIcon from "assets/icons/TickIcon";
import BlueTickIcon from "assets/icons/BlueTickIcon";
import { DeleteModal } from "components/DeleteModal";
import toast from "react-hot-toast";

const Item = styled(Paper)(({ theme }) => ({
   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
   ...theme.typography.body2,
   padding: theme.spacing(2),
   borderRadius: 8,
   display: "flex",
   width: "100%",
   flexDirection: "row",
   flexWrap: "wrap",
   color: theme.palette.text.secondary,
}));

const expressionOptions = [
   {
      name: " Greater than or equal to",
      value: "greater_than_or_equal_to",
   },
   // {
   //    name: "Equal to",
   //    value: "equal_to",
   // },
   // {
   //    name: "Less than or equal to",
   //    value: "less_than_or_equal_to",
   // },
   {
      name: "Greater than",
      value: "greater_than",
   },
   // {
   //    name: "Less than",
   //    value: "less_than",
   // },
];
const OfferTypeOptions = [
   {
      name: "Based on order value",
      value: "based_on_order_value",
   },
];
const discountTypeOptions = [
   {
      name: "Percentage",
      value: "percentage",
   },
   {
      name: "Absolute",
      value: "absolute",
   },
];

export default function OfferCard({ isEdit, handleOffer, data, setAdding, handleDeleteOffer, setVisible, isVisible, checkIsFieldChanged }) {
   const validationSchema = yup.object({
      // offer_name: yup.string().required("Field is required"),
      // discount_val: yup.number().required("Field is required"),
      // offer_value: yup.number().required("Field is required"),
   });
   const [isEditting, setIsEditting] = React.useState(false);
   const [initialValues, setInitialValues] = React.useState({
      offer_name: isEdit ? data.offer_name : "",
      bool_exp: isEdit ? data.bool_exp : "greater_than_or_equal_to",
      offer_type: "based_on_order_value",
      discount_type: isEdit ? data.discount_type : "percentage",
      discount_val: isEdit ? data.discount_val : "",
      status: isEdit ? data.status : true,
      offer_value: isEdit ? data.offer_value : "",
      maximum_allowed_discount: isEdit ? data.maximum_allowed_discount : "",
   });

   const onSubmit = (values) => {
      if (!isEdit) {
         handleOffer(values);
      } else {
         if (isEditting) {
            if (!checkIsFieldChanged(data?.offer_id, values)) {
               toast.error("Please change any field to update");
            } else {
               handleOffer({ ...values, offer_id: data.offer_id });
               setIsEditting(false);
            }
         }
         setIsEditting(!isEditting);
      }
   };

   const handleStatus = () => {
      if (isEdit) {
         handleOffer({ ...data, status: !data.status });
      }
   };

   return (
      <Grid
         item
         xs={12}
         sm={12}
         md={12}>
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}>
            {({ setFieldValue, values, handleSubmit }) => (
               <Item>
                  {/* <Checkbox
                     defaultChecked
                     sx={{ marginRight: "2%", "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  /> */}
                  <Form
                     className="d-flex justify-content-between col-12 "
                     style={{
                        width: "100%",
                        gap: "0.7rem",
                        alignItems: "center",
                     }}>
                     <div className="offersCardInputWrapper">
                        <Input
                           disabled={isEdit && !isEditting}
                           required
                           placeholder="Eg: Offer 1"
                           width={"7vw"}
                           onChange={(e) => {
                              setFieldValue("offer_name", e.target.value);
                           }}
                           height={"2.3rem"}
                           label={"Offer name"}
                           name="offer_name"
                        />
                        <Input
                           disabled={isEdit && !isEditting}
                           placeholder="Eg: 100"
                           required
                           type="number"
                           rupee
                           width={"7vw"}
                           onChange={(e) => {
                              setFieldValue("offer_value", e.target.value);
                           }}
                           height={"2.3rem"}
                           label={"Order value"}
                           name="offer_value"
                        />
                        <Input
                           disabled={isEdit && !isEditting}
                           defaultHolder="Greater than or equal to"
                           required
                           Select
                           value={values.boolean_expression}
                           width={"10vw"}
                           Options={expressionOptions}
                           onChange={(e) => {
                              setFieldValue("bool_exp", e.target.value);
                           }}
                           height={"2.3rem"}
                           label={"Boolean expression"}
                           name="bool_exp"
                        />
                        <Input
                           disabled={isEdit && !isEditting}
                           required
                           Select
                           value={values.discount_type}
                           width={"7vw"}
                           Options={discountTypeOptions}
                           onChange={(e) => {
                              setFieldValue("discount_type", e.target.value);
                           }}
                           height={"2.3rem"}
                           label={"Discount type"}
                           name="discount_type"
                        />{" "}
                        <Input
                           disabled={isEdit && !isEditting}
                           required
                           percentage={values.discount_type === "percentage" ? true : false}
                           rupee={values.discount_type === "absolute" ? true : false}
                           width={"7vw"}
                           type="number"
                           placeholder="Eg: 10"
                           onChange={(e) => {
                              setFieldValue("discount_val", e.target.value);
                           }}
                           height={"2.3rem"}
                           label={"Discount value"}
                           name="discount_val"
                        />
                        {values?.discount_type === "percentage" && (
                           <Input
                              disabled={isEdit && !isEditting}
                              required
                              placeholder="Eg: 10"
                              width={"10vw"}
                              rupee
                              type="number"
                              onChange={(e) => {
                                 setFieldValue("maximum_allowed_discount", e.target.value);
                              }}
                              height={"2.3rem"}
                              label={"Max allowed discount"}
                              name="maximum_allowed_discount"
                           />
                        )}
                     </div>
                     <div className="offersCardBtnWrapper">
                        <div className="d-flex flex-column h-100 gap-2 ">
                           <label
                              htmlFor=""
                              className="labelText">
                              Status
                           </label>
                           <Switch
                              checked={values?.status}
                              checkedChildren="Active"
                              unCheckedChildren="Inactive"
                              onChange={() => {
                                 setFieldValue("status", !values.status);
                              }}
                              onClick={handleStatus}
                              rootClassName="custom-switch"
                              style={{
                                 width: "4.4rem",
                                 background: values.status ? "#2D6B4D" : "",
                                 fontSize: "0.5rem",
                              }}
                           />
                        </div>
                        <div className="d-flex align-items-center  gap-2">
                           {isEdit ? (
                              <>
                                 <IconButton
                                    type={"submit"}
                                    sx={{
                                       background: "#ECEFFD",
                                       borderRadius: "0.5rem",
                                       "&:hover": {
                                          background: "#ECEFFD",
                                       },
                                    }}>
                                    {!isEditting ? (
                                       <EditIcon
                                          color="#4361EE"
                                          sx={{
                                             width: "1.3rem",
                                             height: "1.3rem",
                                          }}
                                       />
                                    ) : (
                                       <BlueTickIcon />
                                    )}
                                 </IconButton>
                                 <IconButton
                                    sx={{
                                       background: "#FBE6EA",
                                       borderRadius: "0.5rem",
                                       "&:hover": {
                                          background: "#FBE6EA",
                                       },
                                    }}
                                    onClick={handleDeleteOffer}>
                                    <DeleteIcon
                                       color="#D90429"
                                       sx={{
                                          width: "1.3rem",
                                          height: "1.3rem",
                                       }}
                                    />
                                 </IconButton>
                              </>
                           ) : (
                              <>
                                 <IconButton
                                    sx={{
                                       background: "#FBE6EA",
                                       borderRadius: "0.5rem",
                                       "&:hover": {
                                          background: "#FBE6EA",
                                       },
                                    }}
                                    onClick={() => {
                                       setAdding(false);
                                    }}>
                                    <CrossIcon color="#D90429" />
                                 </IconButton>
                                 <IconButton
                                    type={"submit"}
                                    sx={{
                                       background: "#ECEFFD",
                                       borderRadius: "0.5rem",
                                       "&:hover": {
                                          background: "#ECEFFD",
                                       },
                                    }}>
                                    <BlueTickIcon />
                                 </IconButton>
                              </>
                           )}
                        </div>
                     </div>
                  </Form>
               </Item>
            )}
         </Formik>
      </Grid>
   );
}
