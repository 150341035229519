import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '@popperjs/core';
import 'bootstrap';
import './style.scss'

function SubscriptionEndedModal (props) {
    return (
        <div className={'SubscriptionEndedModal d-flex justify-content-center align-items-center' + (props.display ? '' : ' d-none')}>
            <div className='ModalContent d-flex flex-column justify-content-between align-items-center p-3'>
                <div className='text-center fw-bold fs-5'>
                    <div>Your Free Trial period has ended.</div>
                    <div>Kindly upgrade your plan to use our application</div>
                </div>
                <div className='flex-grow-1 d-flex flex-column justify-content-end'>
                    <a href='/subscription'>Go to Subscriptions</a>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionEndedModal;