/** @format */

import React, { useEffect, useState, useRef, useCallback } from "react";
import { Modal, Tabs, Tab } from "@mui/material";
import Input from "components/FormikInput";
import ImageUploader from "components/ImageUploader";
import { FieldArray, Form, Formik } from "formik";
import CrossIcon from "assets/icons/CrossIcon";
import TickIcon from "assets/icons/TickIcon";
import Button from "components/Button2";
import "./index.scss";
import * as yup from "yup";
import TypeChip from "components/TypeChip";
import VegIcon from "assets/icons/VegIcon";
import NonVegIcon from "assets/icons/NonVegIcon";
import { useDispatch, useSelector } from "react-redux";
import { CreateCategoryItem, EditCategoryItem } from "store/action/MenuAction";
import { StockSwitch, ToggleSwitch } from "components/ToggleSwitch";
import CustomizationTab from "./CustomizationTab";
import AddonsTab from "./AddonsTab";
import { styled } from "@mui/material/styles";
import toast from "react-hot-toast";
import {
  ITEM_DESCRIPTION_CHARACTER_LIMIT,
  ITEM_INGREDIENT_CHARACTER_LIMIT,
  ITEM_NAME_CHARACTER_LIMIT,
} from "utils/definitions";

const StyledTab = styled(Tab)({
  color: "gray", // Example inactive color
  textTransform: "none", // Active text transform
  fontSize: "1rem",
  fontFamily: "DM Sans", // Active font
  fontWeight: "bold",
  // Styles for the active state
  "&.Mui-selected": {
    color: "#2D6B4D", // Active color
  },
});

function AddItemModal({
  open,
  close,
  isEdit,
  setEdit,
  prevItem,
  setloading,
  setSearch,
  searchResults,
  items,
}) {
  const [Type, setType] = useState(prevItem?.type);
  const [selectionType] = useState(prevItem?.selection_type);
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const allCustomizations = useSelector(
    (state) => state.AddonsReducer.customizations
  );
  const allAddons = useSelector((state) => state.AddonsReducer.addons);

  const formRef = useRef();
  const form2Ref = useRef();
  const initialValues = {
    item_name: "",
    type: "",
    price: "",
    quantity: 0,
    discount: 0,
    out_of_stock: false,
    description: "",
    image: null,
    manage_inventory: false,
    remaining_quantity: 0,
    image_url: null,
    ingredients: "",
  };

  const EditForm = isEdit
    ? {
        ...prevItem,
        ingredients: prevItem?.ingredients,
      }
    : initialValues;

  console.log(EditForm, "EditForm", isEdit, "isEdit");

  const getAddonItems = () => {
    if (isEdit && EditForm?.add_ons?.length > 0) {
      const editFormAddons = EditForm.add_ons;
      const allAddonIds = new Set(allAddons.map((a) => a?.add_on_id));
      const isIncluded = editFormAddons.every((addon) =>
        allAddonIds.has(addon?.add_on_id)
      );
      return isIncluded ? editFormAddons : [];
    }
    return [];
  };

  const getCustomizations = () => {
    if (isEdit && EditForm?.customizations?.length > 0) {
      const editFormCustomizations = EditForm.customizations;
      const allCustomizationIds = new Set(
        allCustomizations.map((c) => c?.customisation_id)
      );
      const isIncluded = editFormCustomizations.every((customization) =>
        allCustomizationIds.has(customization?.customisation_id)
      );
      return isIncluded ? editFormCustomizations : [];
    }
    return [];
  };

  const [addons, setaddons] = useState();
  const [customizations, setcustomizations] = useState();
  const [ingredients, setingredients] = useState(EditForm?.ingredients);
  const [tab, setTab] = useState(0);
  useEffect(() => {
    setaddons(open ? getAddonItems() : []);
    setcustomizations(open ? getCustomizations() : []);
    setingredients(EditForm?.ingredients);
  }, [open]);

  const validationSchema = yup.object({
    item_name: yup
      .string()
      .required("Field is required")
      .min(1)
      .max(ITEM_NAME_CHARACTER_LIMIT, "Exceeding the limit")
      .nullable(),
    type: yup.string().required("Field is required").nullable(),
    price: yup.number().required("Field is required").nullable(),
    quantity: yup
      .number()
      .typeError("Enter a valid number")
      .required("Field is required")
      .nullable(),
    discount: yup.number().nullable(),
    out_of_stock: yup.boolean().nullable(),
    description: yup
      .string()
      .min(1)
      .max(ITEM_DESCRIPTION_CHARACTER_LIMIT, "Exceeding the limit")
      .nullable(),
  });

  const validationSchema2 = yup.object({
    ingredients: yup
      .string()
      .min(1)
      .max(ITEM_INGREDIENT_CHARACTER_LIMIT, "Exceeding the limit")
      .nullable(),
  });

  const removeLabelAndValue = (array) => {
    let restructuredArray = [];
    array.map((item) => {
      let afterRemoved = { ...item };
      afterRemoved["label"] = undefined;
      afterRemoved["value"] = undefined;
      restructuredArray.push(afterRemoved);
    });
    return restructuredArray;
  };

  const getAddonIds = () => {
    let add_on_ids = [];
    addons.map((item) => {
      add_on_ids.push(item?.add_on_id);
    });
    return add_on_ids;
  };

  const getCustomizationIds = () => {
    let customisation_ids = [];
    customizations.map((item) => {
      customisation_ids.push(item?.customisation_id);
    });
    return customisation_ids;
  };

  const onSubmit = async () => {
    // setloading(true);

    let values = formRef?.current?.values;
    const values2 = form2Ref?.current?.values;
    values = { ...values, ...values2 };
    const categoryId = localStorage.getItem("categoryId");

    let pad = values.price - (values.discount / 100) * values.price;
    const data = {
      category_id: categoryId,
      item_name: values.item_name,
      price: parseFloat(values.price),
      discount: values.discount != "" ? values.discount : 0,
      price_after_discount: values.discount != "" ? pad : values.price,
      type: values.type,
      description: values.description,
      out_of_stock:
        values?.manage_inventory === true && values?.quantity > 0
          ? false
          : values?.out_of_stock,
      quantity: values.quantity != "" ? values.quantity : 0,
      image: values.image,
      manage_inventory: values?.manage_inventory,
      remaining_quantity: values?.quantity,
      image_url: values?.image_url,
      item_idx: isEdit
        ? prevItem?.item_idx
        : items?.length > 0
        ? items?.length - 1 + 1
        : 0,
      add_ons: Array.isArray(addons)
        ? JSON.stringify(getAddonIds(removeLabelAndValue(addons)))
        : [],
      customizations: Array.isArray(customizations)
        ? JSON.stringify(
            getCustomizationIds(removeLabelAndValue(customizations))
          )
        : [],
      Spiciness_level: "",
      calories: "",
      ingredients: values?.ingredients,
    };
    console.log(data, "DATAADDITEM");
    if (!isEdit) {
      const result = await dispatch(CreateCategoryItem(data));
      console.log(result);
      setloading(false);
      close();
      toast.success("Item added successfully");
    } else {
      setloading(true);
      const result = await dispatch(
        EditCategoryItem({
          item_id: prevItem.item_id,
          image_url: data.image === null ? prevItem.image_url : null,
          ...data,
        })
      );
      setloading(false);
      setEdit(false);
      close();
      toast.success("Item updated successfully");
      if (searchResults?.length !== 0) {
        let editeditem = searchResults?.filter(
          (i) => i?.item_id !== result?.payload.item_id
        );
        let temp = [result?.payload, ...editeditem];
        setSearch(temp);
      }

      console.log("EditedItem", result?.payload);
    }
    close();
    setTab(0);
    setcustomizations([]);
    setaddons([]);
    console.log("Body", data);
  };

  const closeOverlay = () => {
    open = { close };
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        closeOverlay();
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const onRemoveAddonItem = (add_on) => {
    setaddons(addons.filter((item) => item.add_on_id !== add_on.add_on_id));
  };

  const onRemoveCustomization = (customisation) => {
    setcustomizations(
      customizations.filter(
        (item) => item.customisation_id !== customisation.customisation_id
      )
    );
  };

  console.log(formRef.current?.values, "formRef.current", tab);

  const getItemInputLabelWithLimit = (label) => {
    const item_name = formRef.current?.values?.item_name;
    const description = formRef.current?.values?.description;
    const ingredients = form2Ref.current?.values?.ingredients;

    switch (label) {
      case "Item name":
        return `Item name (${item_name?.length}/${ITEM_NAME_CHARACTER_LIMIT})`;
      case "Item description":
        return `Item description (${description?.length}/${ITEM_DESCRIPTION_CHARACTER_LIMIT})`;
      case "Ingredients":
        return `Ingredients (${ingredients?.length}/${ITEM_INGREDIENT_CHARACTER_LIMIT})`;
      default:
        break;
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setEdit(false);
        close();
        setcustomizations([]);
        setaddons([]);
        setTab(0);
      }}
    >
      <div className="AddItemModalContainer">
        <div className="AddItemModalHeader">
          {isEdit ? "Edit Item" : "Add Item"}
        </div>
        <div className="AddItemModalTabs">
          <Tabs value={tab} onChange={(e, val) => setTab(val)}>
            <StyledTab label="Item details" sx={{ textTransform: "none" }} />
            <StyledTab label="Add Ons" sx={{ textTransform: "none" }} />
            <StyledTab label="Customization" sx={{ textTransform: "none" }} />
            <StyledTab label="Ingredients" sx={{ textTransform: "none" }} />
          </Tabs>
        </div>
        <div className="h-[80%] overflow-y-scroll">
          <div style={{ display: tab === 0 ? "flex" : "none" }}>
            <Formik
              initialValues={isEdit ? EditForm : initialValues}
              validationSchema={validationSchema}
              innerRef={formRef}
              validateOnMount={true}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {({ isValid, values, setFieldValue }) => {
                return (
                  <Form className="AddItemModalForm">
                    <Input
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length < ITEM_NAME_CHARACTER_LIMIT + 1) {
                          setFieldValue("item_name", value);
                        }
                      }}
                      autoFocus
                      required
                      placeholder={"(Eg: Chicken Soup)"}
                      width={"95%"}
                      value={values.item_name}
                      label={getItemInputLabelWithLimit("Item name")}
                    />
                    <div className="itemTypeWrapper mt-3">
                      <label htmlFor="" className="labelText">
                        Item type <span className="requiredtag">*</span>
                      </label>
                      <div className="formGroup">
                        <TypeChip
                          title={"Veg"}
                          ActiveStyle={
                            (isEdit
                              ? Type === "veg" || values.type === "veg"
                              : values.type === "veg") && "VegActive"
                          }
                          icon={<VegIcon />}
                          onclick={() => {
                            setFieldValue("type", "veg");
                          }}
                        />
                        <TypeChip
                          ActiveStyle={
                            (isEdit
                              ? Type === "non-veg" || values.type === "non-veg"
                              : values.type === "non-veg") && "NonVegActive"
                          }
                          title={"Non-Veg"}
                          icon={<NonVegIcon />}
                          onclick={() => {
                            setFieldValue("type", "non-veg");
                          }}
                        />
                      </div>{" "}
                    </div>
                    <div className="formGroup mb-3 mt-2">
                      <Input
                        rupee
                        required
                        width={"80%"}
                        label={"Price"}
                        name={"price"}
                        type={"number"}
                        onChange={(e) =>
                          setFieldValue(
                            "price",
                            e.target.value === ""
                              ? ""
                              : parseFloat(e.target.value)
                          )
                        }
                      />
                      <ToggleSwitch
                        handleValue={() => {
                          setFieldValue(
                            "manage_inventory",
                            !values.manage_inventory
                          );
                        }}
                        textStyle={{
                          fontSize: "0.9rem",
                          textAlign: "center",
                        }}
                        style={{
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          display: "flex",
                        }}
                        isAvailable={values?.manage_inventory}
                        falseValue={"Manage inventory"}
                        trueValue={"Manage inventory"}
                      />
                    </div>{" "}
                    {values.manage_inventory && (
                      <div className="formGroup">
                        <Input
                          required
                          width={"100%"}
                          placeholder={"Enter quantity"}
                          label={"Quantity"}
                          type={"number"}
                          name="quantity"
                          onChange={(e) => {
                            setFieldValue("quantity", parseInt(e.target.value));
                            setFieldValue("remaining_quantity", e.target.value);
                          }}
                        />
                        <Input
                          required
                          readOnly
                          width={"100%"}
                          value={values?.remaining_quantity}
                          label={"Remaning quantity"}
                          name={"remaining_quantity"}
                          type={"number"}
                          opacity={0.5}
                          onChange={(e) =>
                            setFieldValue(
                              "remaining_quantity",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </div>
                    )}
                    <div className="formGroup mb-3">
                      <Input
                        percentage
                        optional
                        width={"83%"}
                        label={"Discount"}
                        name={"discount"}
                        type={"number"}
                        onChange={(e) =>
                          setFieldValue("discount", e.target.value)
                        }
                      />
                      {!isEdit && (
                        <div>
                          <ToggleSwitch
                            textStyle={{
                              fontSize: "0.9rem",
                              textAlign: "center",
                              color: !values?.out_of_stock ? "green" : "red",
                            }}
                            style={{
                              alignItems: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                              display: "flex",
                            }}
                            isAvailable={!values?.out_of_stock}
                            trueValue={"In stock"}
                            falseValue={"Out of stock"}
                            onClick={() => {
                              setFieldValue(
                                "out_of_stock",
                                !values?.out_of_stock
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <Input
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          value.length <
                          ITEM_DESCRIPTION_CHARACTER_LIMIT + 1
                        ) {
                          setFieldValue("description", value);
                        }
                      }}
                      optional
                      maxLength={300}
                      Width={"95%"}
                      placeholder={"Enter item description here"}
                      textArea
                      label={getItemInputLabelWithLimit("Item description")}
                      value={values.description}
                    />
                    <ImageUploader
                      optional
                      label={"Upload image"}
                      width={"96%"}
                      setField={setFieldValue}
                      isEdit={isEdit}
                      prevValue={prevItem?.image_url}
                      value={values.image}
                    />
                    <div className="AddItemBtnWrapper">
                      <Button
                        Size="sm"
                        title="Cancel"
                        border="none"
                        icon={<CrossIcon color="white" />}
                        background={"#D90429"}
                        color={"#fff"}
                        onClick={() => {
                          setEdit(false);
                          close();
                        }}
                      />
                      <Button
                        disabled={!isValid}
                        Size="sm"
                        title="Save"
                        border="none"
                        icon={<TickIcon />}
                        background={"#2FBF71"}
                        color={"#fff"}
                        // onClick={() => onSubmit(formRef.current?.values)}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>

          {tab === 1 && (
            <AddonsTab
              defaultAdd={false}
              addons={addons}
              onAddNew={(newAddon) => setaddons([...addons, newAddon])}
              onRemove={onRemoveAddonItem}
              editIndex={null}
              onSave={() => onSubmit()}
              onClose={() => {
                setEdit(false);
                setTab(0);
                close();
                setaddons([]);
              }}
            />
          )}
          {tab === 2 && (
            <CustomizationTab
              defaultAdd={false}
              customizations={customizations}
              onAddNew={(val) => setcustomizations([...customizations, val])}
              onRemove={onRemoveCustomization}
              editIndex={null}
              onSave={() => onSubmit()}
              onClose={() => {
                setEdit(false);
                setTab(0);
                close();
                setcustomizations([]);
              }}
            />
          )}
          {tab === 3 && (
            <Formik
              initialValues={
                isEdit
                  ? { ingredients: EditForm.ingredients }
                  : { ingredients: "" }
              }
              validationSchema={validationSchema2}
              enableReinitialize={true}
              innerRef={form2Ref}
              onSubmit={onSubmit}
            >
              {({ isValid, values, setFieldValue }) => {
                return (
                  <div className="p-3  h-full flex flex-col justify-between">
                    <Input
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          value.length <
                          ITEM_INGREDIENT_CHARACTER_LIMIT + 1
                        ) {
                          setFieldValue("ingredients", value);
                        }
                      }}
                      value={values.ingredients}
                      textArea
                      className="w-full h-[15rem] overflow-auto border-[1px] border-solid border-[#c4c4ca]  outline-none p-2 rounded-[0.8rem]"
                      optional
                      placeholder={"Enter ingredients here"}
                      label={getItemInputLabelWithLimit("Ingredients")}
                    />
                    <div className="AddItemBtnWrapper">
                      <Button
                        Size="sm"
                        title="Cancel"
                        border="none"
                        icon={<CrossIcon color="white" />}
                        background={"#D90429"}
                        color={"#fff"}
                        onClick={() => {
                          setEdit(false);
                          close();
                        }}
                      />
                      <Button
                        Size="sm"
                        title="Save"
                        border="none"
                        icon={<TickIcon />}
                        background={"#2FBF71"}
                        color={"#fff"}
                        onClick={() => {
                          if (
                            values?.ingredients?.length <=
                            ITEM_INGREDIENT_CHARACTER_LIMIT
                          )
                            onSubmit();
                        }}
                      />
                    </div>
                  </div>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default AddItemModal;
