import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const NEWLY_ADDED_API_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/newly_added_items/`;

export const GetNewlyAddedItems = createAsyncThunk(
  `GetNewlyAddedItems`,
  async ( data, { rejectWithValue } ) => {

    try
    {
      const response = await axios.get( NEWLY_ADDED_API_ENDPOINT, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
          'ngrok-skip-browser-warning': 'any',
        },
      } );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;
        return resData;
      } else
      {
        return rejectWithValue( "Can't Get newly added items" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  },
);

export const CreateNewlyAddedItems = createAsyncThunk(
  `CreateNewlyAddedItems`,
  async ( data, { rejectWithValue } ) => {
    try
    {
      const response = await axios.post(
        NEWLY_ADDED_API_ENDPOINT,
        { item_ids: data },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem( 'savedT' )}`,
            'ngrok-skip-browser-warning': 'any',
          },
        },
      );
      console.log( 'DATA', data );
      console.log( 'RESPONSe', response );
      if ( response.status === 201 || response.status === 200 )
      {
        const resData = await response.data;

        return resData;
      } else
      {
        return rejectWithValue( "Cant'Create Best selling items" );
      }
    } catch ( error )
    {
      console.log( error );
      throw error;
    }
  },
);
