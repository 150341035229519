function ArrowLeftAlt({ color }) {
    return (
        <svg 
            width="22" 
            height="16" 
            viewBox="0 0 22 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M9.28369 15.0164C8.76585 15.5527 7.90166 15.5677 7.37449 15.0405L1.27679 8.94281C0.756093 8.42211 0.756094 7.57789 1.27679 7.05719L7.37449 0.959493C7.90166 0.432324 8.76585 0.447287 9.28369 0.983623C9.78884 1.50682 9.78849 2.3455 9.27424 2.85975L5.46732 6.66667H20.334C21.0704 6.66667 21.6673 7.26362 21.6673 8C21.6673 8.73638 21.0704 9.33333 20.334 9.33333H5.46732L9.27424 13.1403C9.78849 13.6545 9.78884 14.4932 9.28369 15.0164Z" 
                fill={color ?? "#1C1B1F"}/>
        </svg>
    );
}

export default ArrowLeftAlt;