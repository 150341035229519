/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import logo from "assets/logo/logo.png";
import Input from "components/FormikInput";
import Button2 from "components/Button2";
import Button1 from "components/Button1";
import GoogleIcon from "assets/icons/GoogleIcon";
import LoginImage from "assets/images/login.png";
import OtpInput from "otp-input-react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import * as AuthActions from "../../store/action/AuthAction";
import { useNavigate } from "react-router-dom";
import { useGoogleOneTapLogin, useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import AuthReducer, { getOtpDetails } from "store/reducer/AuthReducer";
import WaiterLogin from "./WaiterLogin";

const Index = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const initialValues = {
      email: "",
      password: "",
      remember: false,
   };
   const [isCountryIndia, setisCountryIndia] = useState(null);
   const validationSchema = yup.object({
      email: yup.string().email("Enter a valid email"),
      password: yup.string(),
   });

   const [merchantId, setmerchantId] = useState("");
   const [isNewUser, setisNewUser] = useState(false);
   const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);

   useEffect(() => {
      const handleResize = () => {
         setIsMobileView(window.innerWidth <= 500);
      };
      window.addEventListener("resize", handleResize);
      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }, []);
   console.log(isMobileView, "MBVW");

   const onSubmit = async (values) => {
      if (!Login) {
         if (values?.otp === undefined) {
            const result = await AuthActions.SEND_OTP({ phone_number: values?.phone_number });
            if (result.status === 201 || result.status === 200) {
               setOtp(values.phone_number);
               const resData = await result.json();
               setmerchantId(resData?.merchant_id);
               setisNewUser(resData?.new_user);
               await dispatch(getOtpDetails(resData));
            } else {
               alert("Error occured");
            }
         } else {
            const result = await AuthActions.VERIFY_OTP({
               phone_number: values?.phone_number,
               otp: parseInt(values?.otp),
               merchant_id: merchantId,
            });

            if (result.status === 201 || result.status === 200) {
               setOtp(values.phone_number);
               const resData = await result.json();
               if (resData?.jwt !== undefined) {
                  localStorage.setItem("savedT", resData.jwt);
                  localStorage.setItem("waiter_mode", false);
                  if (!isNewUser && isMobileView) {
                     navigate("/menu");
                  } else if (isNewUser) {
                     navigate("/onboarding");
                  } else {
                     navigate("/profile");
                  }
               } else {
                  alert("Invalid OTP");
               }
            } else {
               alert("Error occured");
            }
         }
      } else {
         console.log(values, "ss");
      }
   };

   const SendEmail = async (email, token) => {
      try {
         const body = { email: email };
         const result = await AuthActions.SEND_EMAIL(body, token);
         console.log(result, "SEND EMAIl ");
         if (result.status === 200 || result.status === 201) {
            localStorage.setItem("waiter_mode", false);
            console.log(result, " sdsd");
            const resData = await result.json();
            console.log(resData, "resData");
            if (!resData?.new_user && isMobileView) {
               navigate("/menu");
            } else if (resData?.new_user) {
               navigate("/onboarding");
            } else {
               navigate("/menu");
            }
            localStorage.setItem("savedT", resData.jwt);
         }
      } catch (error) {
         console.log(error);
      }
   };

   // const getIpAddress = async () => {
   //    alert("IP");
   //    try {
   //       const response = await fetch(`https://geolocation-db.com/json/`);
   //       const data = await response.json();
   //       setisCountryIndia(data?.country_code === "IN");
   //       console.log(data, "IPDATA");
   //       return data;
   //    } catch (error) {
   //       throw error;
   //    }
   // };

   // useEffect(() => {
   //    getIpAddress();
   // }, []);

   const onSucessGoogleLogin = (credentialResponse) => {
      const decode = jwt_decode(credentialResponse.credential);
      console.log(decode);
      SendEmail(decode.email, credentialResponse.credential);
   };

   const login = useGoogleLogin({
      onSuccess: onSucessGoogleLogin,
      flow: "implicit",
   });

   const [Otp, setOtp] = useState("");
   const [Login, setLogin] = useState("ADMIN");

   console.log(merchantId, "merchantId");

   const openTandC = () => {
      window.open("https://www.thebeautifulmenu.com/terms-and-conditions/", "_blank");
   };

   const [isAdmin, setIsAdmin] = useState("ADMIN");
   const innerHeight = window.innerHeight;

   return (
      <div className="loginContainer overflow-hidden">
         <div className="loginScreenContainer">
            <div className="logoAndNameContainer">
               <img
                  className="logo"
                  src={logo}
               />
               <div className="logoNameText">The Beautiful Menu</div>
            </div>
            <Formik
               initialValues={initialValues}
               onSubmit={onSubmit}
               validationSchema={validationSchema}
               enableReinitialize={true}>
               <div className={`h-full flex flex-col items-start lg:pl-[6rem] ${innerHeight > 800 ? "lg:pt-[3rem]" : ""} gap-3 2xl:mt-[6rem] mt-[4rem]`}>
                  <div className="flex justify-between items-center w-[70%] mb-[1rem]">
                     <div className="flex border-[1px] border-[#d1d1d1] p-[0.5rem] gap-[1rem] rounded-lg w-[100%]">
                        <div
                           onClick={() => setIsAdmin("ADMIN")}
                           className={`cursor-pointer transition-all duration-300 text-[#868B8C] text-[1.5rem] rounded-lg w-full py-2 text-center ${
                              isAdmin === "ADMIN" && "bg-[#0F6656] text-[#fff]"
                           }`}>
                           Admin
                        </div>
                        <div
                           onClick={() => setIsAdmin("WAITER")}
                           className={`cursor-pointer transition-all duration-300 text-[#868B8C] text-[1.5rem] rounded-lg w-full py-2 text-center ${
                              isAdmin === "WAITER" && "bg-[#0F6656] text-[#fff]"
                           }`}>
                           Waiter
                        </div>
                     </div>
                  </div>
                  {isAdmin === "WAITER" ? (
                     <div className="w-[100%]">
                        <WaiterLogin />
                     </div>
                  ) : (
                     <>
                        <h3 className="text-left text-[3rem] w-[35rem] font-bold font-[Roboto] mb-[1.5rem]">Login to boost your restaurant's operational efficiency</h3>
                        <GoogleLogin
                           shape="rectangular"
                           text="continue_with"
                           theme="outline"
                           logo_alignment="left"
                           onSuccess={onSucessGoogleLogin}
                           onError={() => {
                              console.log("Login Failed");
                           }}
                        />
                        <div className="text-[0.8rem] mt-[-4px]">
                           By proceeding, you agree to our{" "}
                           <a
                              className="tandCText"
                              onClick={openTandC}>
                              terms and conditions
                           </a>
                        </div>
                     </>
                  )}
               </div>
            </Formik>
         </div>
         <div className="LoginImageContainer">
            <img
               src={LoginImage}
               className="LoginImage"
            />
         </div>
      </div>
   );
};

export default Index;
