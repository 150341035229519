/** @format */

import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import OrderActionCard from "components/OrderActionCard";
import CheckIcon from "@mui/icons-material/Check";
import PrintOrder from "pages/Orders/PrintOrder";
import Select from "react-select";
import { Link } from "react-router-dom";

const actions = ["Send to KOT", "Print bill", "Complete payment"];
const paymentMethods = [
   {
      label: "Cash",
      value: "Cash",
   },
   {
      label: "Debit card",
      value: "Debit card",
   },
   {
      label: "Credit card",
      value: "Credit card",
   },
   {
      label: "Others",
      value: "Others",
   },
];

const customStyles = {
   control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "green" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px green" : provided.boxShadow,
      "&:hover": {
         borderColor: state.isFocused ? "green" : provided.borderColor,
      },
      padding: "0.2rem",
   }),
};

const printFlows = ["Send to KOT", "Print bill"];

export default function OrderActionModal({ visible, onOrderComplete, onClose, data }) {
   const [selectedAction, setselectedAction] = useState("");
   const [selectedPaymentMethod, setselectedPaymentMethod] = useState("");
   const [showPrintOrder, setshowPrintOrder] = useState(false);

   const [notes, setnotes] = useState("");

   useEffect(() => {
      selectedAction !== "Complete payment" && setselectedPaymentMethod("");
   }, [selectedAction]);

   const togglePrintOrder = (action) => {
      setshowPrintOrder(printFlows.includes(action));
   };

   useEffect(() => {
      reset();
   }, []);

   const reset = () => {
      setselectedAction("");
      setselectedPaymentMethod("");
   };

   console.log("NOTES OrderActionModal", data);

   return (
      <Modal
         open={visible}
         className="deleteModal">
         <div className="bg-[#fff] position-relative w-[50%] px-5 py-[2rem] flex flex-col items-center rounded-[0.6rem] ">
            <div className="text-[1.5rem] font-bold">Choose action</div>
            <PrintOrder
               data={data}
               visible={showPrintOrder}
               printKOT={selectedAction === "Send to KOT"}
               onClose={() => setshowPrintOrder(false)}
            />
            <div className="flex items-center w-full justify-around mt-4">
               {actions.map((item, index) => {
                  return (
                     <OrderActionCard
                        title={item}
                        key={index}
                        selected={item === selectedAction}
                        onClick={() => {
                           setselectedAction(item);
                           togglePrintOrder(item);
                        }}
                     />
                  );
               })}
            </div>
            {selectedAction === "Complete payment" && (
               <>
                  <div className="flex flex-col items-center position-relative w-full justify-around mt-4">
                     <div className="w-full pl-3 text-[1rem] mt-3 mb-1">Select payment method</div>
                     <Select
                        className="w-[97%]"
                        styles={customStyles}
                        placeholder="Select one"
                        onChange={(val) => setselectedPaymentMethod(val.value)}
                        options={paymentMethods}
                     />
                  </div>
                  {/* <div className="my-4">or</div> */}
                  <div className="w-full flex items-center flex-col">
                     <div className="w-full pl-3 text-[1rem] mt-4 mb-1">Notes</div>
                     <textarea
                        value={notes}
                        onChange={(e) => setnotes(e.target.value)}
                        className="border-[1px] w-[97%] rounded-[0.2rem] outline-none p-1  border-solid border-[#c4c4ca] h-[5rem]"
                        placeholder="Enter the other notes if have any"
                     />
                  </div>
               </>
            )}
            <div className="flex items-center w-full justify-center gap-2">
               <Link
                  to={`/orders/${data?.order_id}`}
                  // target="_blank"
                  className="mt-5 cursor-pointer no-underline rounded-[8px] bg-[#4361EE] w-[13rem] text-center px-3 py-3 text-[1.1rem] py-1 text-[#fff]">
                  View order
               </Link>
               {selectedAction === "Complete payment" && (
                  <div
                     onClick={() => {
                        if (selectedPaymentMethod !== "") {
                           onOrderComplete(selectedPaymentMethod, notes);
                           reset();
                        }
                     }}
                     style={{ opacity: selectedPaymentMethod !== "" ? 1 : 0.5 }}
                     className="mt-5 cursor-pointer rounded-[8px] bg-[#4361EE] w-[13rem] text-center px-3 py-3 text-[1.1rem] py-1 text-[#fff]">
                     <CheckIcon /> Complete order
                  </div>
               )}
               <div
                  onClick={onClose}
                  className="mt-5 cursor-pointer rounded-[8px] bg-[#4361EE] w-[13rem] text-center px-3 py-3 text-[1.1rem] py-1 text-[#fff]">
                  Create new order
               </div>
            </div>
         </div>
      </Modal>
   );
}
