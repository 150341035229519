import { createAsyncThunk } from '@reduxjs/toolkit';

const COMBOS_ENDPOINT = `${process.env.REACT_APP_DEV_URL}/merchant/combos`;

//Get All Combos

export const getAllCombos = createAsyncThunk(`getAllCombos`, async (data, { rejectWithValue }) => {
  try {
    const response = await fetch(COMBOS_ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('savedT')}`,
        'ngrok-skip-browser-warning': 'any',
      },
    });

    if (response.status === 201 || response.status === 200) {
      const resData = await response.json();
      console.log('RESPONSE OF ALL COMBOS ===>', resData);
      return resData;
    } else {
      return rejectWithValue("Can't fetch combos");
    }
  } catch (error) {
    throw error;
  }
});

//Create combos

export const createCombo = createAsyncThunk(`createCombo`, async (data, { rejectWithValue }) => {
  try {
    const response = await fetch(COMBOS_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('savedT')}`,
        'ngrok-skip-browser-warning': 'any',
      },
      body: JSON.stringify(data),
    });

    if (response.status === 201 || response.status === 200) {
      const resData = await response.json();
      console.log('RESPONSE OF CREATE COMBOS ===>', resData);
      return resData;
    } else {
      return rejectWithValue("Can't fetch combos");
    }
  } catch (error) {
    throw error;
  }
});

//Get Single Combo
export const getSingleCombo = createAsyncThunk(
  `getSingleCombo`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(`${COMBOS_ENDPOINT}/${data}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('savedT')}`,
          'ngrok-skip-browser-warning': 'any',
        },
      });

      if (response.status === 201 || response.status === 200) {
        const resData = await response.json();
        console.log('RESPONSE OF SINGlE COMBO ===>', resData);
        return resData;
      } else {
        return rejectWithValue("Can't fetch combo");
      }
    } catch (error) {
      throw error;
    }
  },
);

//Edit Combo

export const editCombo = createAsyncThunk(`editCombo`, async (data, { rejectWithValue }) => {
  console.log('EditedData', data);
  try {
    const response = await fetch(`${COMBOS_ENDPOINT}/${data.combo_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('savedT')}`,
        'ngrok-skip-browser-warning': 'any',
      },
      body: JSON.stringify(data.data),
    });

    if (response.status === 201 || response.status === 200) {
      const resData = await response.json();
      console.log('RESPONSE OF EDIT COMBO ===>', resData);
      return resData;
    } else {
      return rejectWithValue("Can't edit combo");
    }
  } catch (error) {
    throw error;
  }
});

//Delete Combo

export const deleteCombo = createAsyncThunk(`deleteCombo`, async (data, { rejectWithValue }) => {
  try {
    const response = await fetch(`${COMBOS_ENDPOINT}/${data}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('savedT')}`,
        'ngrok-skip-browser-warning': 'any',
      },
    });

    if (response.status === 201 || response.status === 200) {
      const resData = await response.json();
      console.log('RESPONSE OF DELETE COMBO ===>', resData);
      return resData;
    } else {
      return rejectWithValue("Can't delete combo");
    }
  } catch (error) {
    throw error;
  }
});
