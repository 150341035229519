import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  otpDetails: {},
};

const AuthReducer = createSlice({
  name: 'AuthReducer',
  initialState,
  reducers: {
    getOtpDetails: (state, { payload }) => {
      state.otpDetails = payload;
    },
  },
});

export const { getOtpDetails } = AuthReducer.actions;

export default AuthReducer.reducer;
