import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const BarChart = ({dets, keys}) => {
    const option = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.5,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: dets.title,
                align: 'start',
                color: '#000',
            },
            tooltip: {
                enabled: true,
                backgroundColor: '#fff',
                bodyColor: '#2D6B4D',
                displayColors: false,
                padding: 10,
                position: 'nearest',
                borderWidth: 1,
                borderColor: '#858f8f',
                titleColor: '#2D6B4D',
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Number of orders',
                },
            },
            x: {
                ticks: {
                    maxRotation: 0,
                    minRotation: 0
                },
                grid: {
                    display: false
                }
            }
        }
    };

    const Data = {
        labels: dets?.value?.map((item) => {
            return item[0]
        }),
        datasets: [
            {
                label: '# of orders',
                data: dets?.value?.map((item) => {
                    return item[1]
                }),
                backgroundColor: '#2D6B4D',
                borderColor: '#2D6B4D',
                tension: 0.4,
            }
        ]
    }
    return (
        <div className='bar-chart-wrapper'>
            <Line data={Data} options={option} />
        </div>
    )
}

export default BarChart;