/** @format */

import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { FormGroup } from "@mui/material";

export default function Customization({ customizations, updateCustomizationInCartItem, clearCustomizationForItem, selectedCustomizations }) {
   const onSelect = (e, item) => {
      updateCustomizationInCartItem(item, e.target.value);
   };

   const isSelected = (customisation_id, option) => {
      const index = selectedCustomizations.findIndex((item) => item.customisation_id === customisation_id);

      if (index !== -1) {
         return selectedCustomizations[index].selected_options?.includes(option);
      } else {
         return false;
      }
   };

   return (
      <>
         {customizations.map((customization) => {
            return (
               <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                     {customization?.customisation_title}{" "}
                     <span
                        onClick={() => clearCustomizationForItem(customization.customisation_id)}
                        className="text-[0.7rem] underline">
                        clear
                     </span>
                  </FormLabel>
                  {customization.selection_type === "radio" && (
                     <RadioGroup
                        onChange={(e) => onSelect(e, customization)}
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group">
                        {customization.options.map((item) => {
                           return (
                              <FormControlLabel
                                 value={item}
                                 className="text-[#000] text-[DM Sans]"
                                 control={<Radio checked={isSelected(customization.customisation_id, item)} />}
                                 label={item}
                              />
                           );
                        })}
                     </RadioGroup>
                  )}
                  {customization.selection_type === "checkbox" && (
                     <FormGroup>
                        {customization.options.map((option) => {
                           return (
                              <FormControlLabel
                                 value={option}
                                 onChange={(e) => onSelect(e, customization)}
                                 className="text-[#000] text-[DM Sans]"
                                 control={<Checkbox checked={isSelected(customization.customisation_id, option)} />}
                                 label={option}
                              />
                           );
                        })}
                     </FormGroup>
                  )}
               </FormControl>
            );
         })}
      </>
   );
}
